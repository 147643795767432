import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Segment } from '@app/shared/models/segment';

@Pipe({
  name: 'number',
})
export class CustomNumberPipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe,
  ) {
  }

  transform(value?: string | number, digitsInfo?: string, locale?: string, removeTrailingZeros = true) {
    const result = this.decimalPipe.transform(value, digitsInfo, locale);

    if (removeTrailingZeros && value === 0) return 0;

    return result;
  }

  powerUnitConsumptionTransform(value: string | number, segment: Segment) {
    if (segment !== 'C5') {
      const digitsInfo = '1.3-3';
      return this.decimalPipe.transform(value, digitsInfo, 'fr-FR');
    }
    return this.decimalPipe.transform(value, undefined, 'fr-FR');
  }

  powerUnitLoadCurveTransform(value: string | number, segment: Segment) {
    if (segment === 'C5') {
      const digitsInfo = '1.2-2';
      return this.decimalPipe.transform(value, digitsInfo, 'fr-FR');
    }
    return this.decimalPipe.transform(value, undefined, 'fr-FR');
  }
}
