import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AlertService } from '@app/shared/components/organisms/alert/alert.service';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
})
export class LoginContainerComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: ActivatedRoute,
    private alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.router.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((message) => {
      if (message.isPasswordModified) {
        this.alertService.success('La modification de votre mot de passe a été réalisée avec succès !');
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
