import {
  MaintenanceContainerComponent,
} from '@app/maintenance-page/maintenance-container/maintenance-container.component';
import { Routes } from '@angular/router';
import { HomePageComponent } from '@app/home-page/home-page.component';
import { HomePageGuard } from '@app/home-page/home-page.guard';
import { distinctUntilChanged, OperatorFunction } from 'rxjs';
import { SamlCallbackComponent } from '@app/user-account/saml-callback/saml-callback.component';
import { LoginContainerComponent } from './user-account/login-container/login-container.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginContainerComponent,
    loadChildren: () => import('./user-account/user-account.module').then((m) => m.UserAccountModule),
  },
  {
    path: 'saml-callback',
    component: SamlCallbackComponent,
    canActivate: [],
  },
  {
    path: 'maintenance',
    component: MaintenanceContainerComponent,
    loadChildren: () => import('./maintenance-page/maintenance.module').then((m) => m.MaintenanceModule),
  },
  {
    path: '',
    component: HomePageComponent,
    canActivate: [HomePageGuard],
    canActivateChild: [HomePageGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./home-page/home-page.module').then((m) => m.HomePageModule),
      },
      {
        path: 'invoices',
        loadChildren: () => import('./invoices/invoices.module').then((m) => m.InvoicesModule),
      },
      {
        path: 'offers',
        loadChildren: () => import('./offers/offers.module').then((m) => m.OffersModule),
      },
      {
        path: 'site-offers',
        loadChildren: () => import('./site-offers/site-offers.module').then((m) => m.SiteOffersModule),
      },
      {
        path: 'account',
        loadChildren: () => import('./information/information.module').then((m) => m.InformationModule),
      },
      {
        path: 'exports',
        loadChildren: () => import('./exports/exports.module').then((m) => m.ExportsModule),
      },
      {
        path: 'consumptions',
        loadChildren: () => import('./consumptions/consumptions.module').then((m) => m.ConsumptionsModule),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

export const ignoreKeysChanged = (list: string[]): OperatorFunction<any, any> => {
  const replacer = (key: string, value: any) => (list.includes(key) ? undefined : value);

  return distinctUntilChanged((prev, next) => JSON.stringify(prev, replacer) === JSON.stringify(next, replacer));
};
