import { Component } from '@angular/core';
import { ALTERNA_WEBSITE_URL, B2C_CUSTOMER_AREA_URL, CDN_SOREGIES_URL } from '@app/shared/utils/urls';
import {
  ButtonStyle,
} from '@app/shared/components/molecules/buttons/button.type';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss'],
})
export class LoginHeaderComponent {
  CDN_SOREGIES_URL = CDN_SOREGIES_URL;

  goToAlternaWebsite() {
    window.location.href = ALTERNA_WEBSITE_URL;
  }

  goToBtocArea() {
    window.location.href = B2C_CUSTOMER_AREA_URL;
  }

  protected readonly ButtonStyle = ButtonStyle;
}
