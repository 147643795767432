<div>
  <div class="header-container" [class.pointer]="showIcon" #connectedEl [style.float]="float">
    <div *ngIf="showIcon" class="header-icon">
      <app-icon [phosphorClass]="icon!" [color]="iconColor" [size]="Size.small"></app-icon>
      <app-badge-notification *ngIf="activeFiltersNumber" class="badge" [value]="activeFiltersNumber"></app-badge-notification>
    </div>
    <span>{{ name }}</span>
  </div>

  <ng-template cdkPortal>
    <div class="filter-container">
      <div class="filter" *ngFor="let item of filterItems" (click)="onFilterItemClick(item)">
        <div class="filter-item">
          <app-checkbox
            (checkedChange)="onFilterItemClick(item)"
            [checked]="item.checked"
            [aria-label]="item.name">
          </app-checkbox>
          <span>{{ Number.isNaN(item.name) ? (item.name | titlecase) : item.name}}</span>
        </div>
        <div class="filter-count">
          <span>{{ item.count }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
