import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';
import { TranslateService } from '@app/shared/translate/translate.service';

@Directive({
  selector: '[appTranslate]',
})
export class TranslateDirective implements AfterViewInit {
  private translateKey: string = '';

  constructor(
    private el: ElementRef,
    private translateService: TranslateService,
    private renderer: Renderer2,
  ) {}

  @Input('appTranslate') set key(value: string) {
    this.translateKey = value;
  }

  get key(): string {
    return this.translateKey;
  }

  @Input() appTranslateParams: string[] = [];

  ngAfterViewInit(): void {
    if (['input', 'textarea'].includes(this.el.nativeElement.localName)) {
      this.renderer.setProperty(this.el.nativeElement, 'placeholder', this.translateService.getValue(this.key, this.appTranslateParams));
    } else {
      const value = this.translateService.getValue(this.translateKey, this.appTranslateParams);
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', value);
    }
  }
}
