import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  Component, ElementRef, EventEmitter, Input, OnInit, Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconComponent, Size, Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';
import { LabelComponent } from '@app/shared/components/atoms/text/label/label.component';
import { Typography } from '@app/shared/models/typography';
import { FontWeight } from '@app/shared/models/font-weight';
import { TextfieldIconOption } from '@app/shared/components/atoms/textfield/textfield.interface';
import { isValidDate } from 'rxjs/internal/util/isDate';

@Component({
  selector: 'app-textfield',
  templateUrl: './textfield.component.html',
  styleUrls: ['./textfield.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent, FormsModule, LabelComponent],
})
export class TextfieldComponent implements OnInit, AfterViewChecked {
  // Main Options

  @Input() value!: string;

  @Input() type: 'text' | 'password' | 'email' = 'text';

  @Input() title?: string;

  @Input() placeholder?: string;

  @Input() hint?: string;

  @Input() styleIcon?: Style;

  @Input() prefixIconOption?: TextfieldIconOption;

  @Input() suffixIconOption?: TextfieldIconOption;

  @Input() clearSuffixButton?: boolean = false;

  @Input() errorMessage?: string;

  @Input() autocomplete?: string;

  @Input() height?: string;

  @Input() maxLength?: number = 999999;

  // Comportement

  @Input() isOnError?: boolean = false;

  @Input() isValidated?: boolean = false;

  @Input() focusOnView?: boolean;

  @Output() inputFocus = new EventEmitter<void>();

  @Output() inputFocusOut = new EventEmitter<void>();

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  // ViewChild

  @ViewChild('nativeHtmlInput', { static: false }) inputRef!: ElementRef<HTMLInputElement>;

  internalInputType?: string;

  get isPasswordVisible(): boolean {
    return this.internalInputType === 'text';
  }

  ngOnInit(): void {
    this.internalInputType = this.type;
  }

  onChange(newValue: string) {
    this.valueChange.emit(newValue);
  }

  onClear() {
    this.value = '';
    this.onChange('');
  }

  onFocus() {
    this.inputFocus.emit();
  }

  onFocusOut() {
    this.inputFocusOut.emit();
  }

  onTogglePasswordVisibility() {
    if (this.internalInputType === 'text') {
      this.internalInputType = 'password';
    } else {
      this.internalInputType = 'text';
    }
  }

  ngAfterViewChecked(): void {
    if (this.focusOnView) {
      this.inputRef.nativeElement.focus();
    }
  }

  protected readonly Style = Style;

  protected readonly Color = Color;

  protected readonly Size = Size;

  protected readonly Typography = Typography;

  protected readonly FontWeight = FontWeight;

  protected readonly isValidDate = isValidDate;
}
