import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';
import { CoverSpacing, ExpansionPanelComponent } from '@app/shared/components/molecules/expansion-panel/expansion-panel.component';
import { IconComponent, Size, Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';
import { CommonModule } from '@angular/common';

export interface SelectorTypeParams {
  title: string;
  value: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent, ExpansionPanelComponent],
})
export class SelectorComponent implements OnInit, OnChanges {
  @Input() disabled: boolean = false;

  @Input() coverSpacing: keyof typeof CoverSpacing = 'spaceBetween';

  @Input() selectId?: string = '';

  @Input() params: SelectorTypeParams[] = [];

  @Input() placeHolder: string = '';

  @Input() value: string = '';

  @Output() valueChange = new EventEmitter<any>();

  coverText: string = this.placeHolder;

  ngOnInit() {
    this.coverText = this.params.find((p) => p.value === this.value)?.title || this.placeHolder;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { value, params } = changes;
    if (value || params) {
      this.coverText = this.params.find((p) => p.value === this.value)?.title || this.placeHolder;
    }
  }

  handleChange(param: SelectorTypeParams) {
    this.valueChange.emit(param.value);
  }

  protected readonly Style = Style;

  protected readonly Color = Color;

  protected readonly Size = Size;
}
