import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTreeModule } from '@angular/cdk/tree';
import { PortalModule } from '@angular/cdk/portal';

const cdkModules = [
  OverlayModule,
  CdkTreeModule,
  PortalModule,
];

@NgModule({
  imports: [
    ...cdkModules,
  ],
  exports: [
    ...cdkModules,
  ],
})
export class CdkModule {
}
