<app-expansion-panel coverText="Détails du site" iconClassName="ph-buildings" [iconStyle]="Style.fill"
expansionPanelId="site-detail-EP"
>
  <ng-container ngProjectAs="[dropdown-content]">
    <div class="content">
      <app-main-information title="Nom du site" [loading]="!this.site"
                            [value]="site?.siteName">
      </app-main-information>
      <app-main-information title="Adresse du site" [loading]="!this.site"
                            [value]="site?.siteAddress?.fullName">
      </app-main-information>
      <app-main-information title="Segment" [loading]="!this.site"
                            [value]="site?.segment">
      </app-main-information>
      <app-main-information id="bloc-detail-site-date-min" title="Début de contrat" [loading]="!this.site"
                            [value]="site?.dateMin?.toFormat('dd/MM/yyyy')">
      </app-main-information>
      <app-main-information id="bloc-detail-site-date-max" title="Fin de contrat" [loading]="!this.site"
                            [value]="site?.dateMax?.toFormat('dd/MM/yyyy')">
      </app-main-information>
      <app-main-information title="Tarif d'acheminement" [loading]="!this.routingPricing"
                            [value]="routingPricing?.name">
      </app-main-information>
    </div>
  </ng-container>
</app-expansion-panel>
