import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { MESSAGES } from '@app/shared/utils/messages';
import { IconComponent, Size, Style } from '@app/shared/components/atoms/icon/icon.component';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from '../../tooltip/tooltip.directive';

export interface TabInterface {
  id?: number,
  label: string;
  tooltip?: string;
}

@Component({
  selector: 'app-tab-switch',
  templateUrl: './tab-switch.component.html',
  styleUrls: ['./tab-switch.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent, TooltipDirective],
})
export class TabSwitchComponent {
  @Input() disabled: boolean = false;

  @Input() tabs?: TabInterface[];

  @Input() currentTab?: TabInterface;

  @Output() currentTabChange = new EventEmitter<string>();

  onToggle(option: TabInterface) {
    this.currentTab = option;
    this.currentTabChange.emit(option.label);
  }

  reset() {
    this.currentTab = undefined;
  }

  protected readonly MESSAGES = MESSAGES;

  protected readonly Style = Style;

  protected readonly Size = Size;
}
