<div class="app-textfield">

  @if (title) {
    <app-label id="title" [typography]="Typography.body1" [weight]="FontWeight.regular" [color]="Color.greyBlack">
      {{ title }}
    </app-label>
  }

  <div class="app-textfield-field">
    @if (prefixIconOption) {
      <app-icon id="prefixIcon" [phosphorClass]="prefixIconOption.phosphorClass" class="prefix" [iconStyle]="prefixIconOption.iconStyle"
                [color]="Color.grey500" [size]="Size['medium-small']">
      </app-icon>
    }

    <input #nativeHtmlInput
           [ngModel]="value"
           (ngModelChange)="onChange($event)"
           (focus)="onFocus()"
           (focusout)="onFocusOut()"
           [type]="internalInputType"
           [placeholder]="placeholder ? placeholder : ''"
           [autocomplete]="autocomplete"
           [maxLength]="maxLength"
           [style.height]="height"
           [class.error]="errorMessage || isOnError"
           [class.valid]="isValidated"
           id="textfield"
    />

    @if (clearSuffixButton && value) {
      <app-icon id="clearIcon" phosphorClass="ph-x-circle" class="suffix clear-icon"
                [iconStyle]="Style.fill" [color]="Color.grey600" [size]="Size.medium" (click)="onClear()">
      </app-icon>
    } @else if (isValidated) {
      <app-icon id="validateIcon" phosphorClass="ph-check" class="suffix"
                [iconStyle]="Style.bold" [color]="Color.success500" [size]="Size['medium-small']">
      </app-icon>
    } @else if (suffixIconOption) {
      <app-icon id="suffixIcon" [phosphorClass]="suffixIconOption.phosphorClass" class="suffix" [iconStyle]="suffixIconOption.iconStyle"
                [color]="Color.greyBlack" [size]="Size['medium-small']">
      </app-icon>
    }

    @if (type === 'password') {
      <app-icon id="togglePasswordVisibility" class="suffix"
                phosphorClass="ph-{{isPasswordVisible ? 'eye' : 'eye-closed'}}"
                (click)="onTogglePasswordVisibility()">
      </app-icon>
    }
  </div>

  @if (errorMessage) {
    <div class="error-container">
      <app-icon id="errorIcon"
        [phosphorClass]="'ph-warning-circle'" [color]="Color.danger500" [size]="Size.small"
      ></app-icon>
      <app-label id="errorMessage"
        [typography]="Typography.body2" [weight]="FontWeight.regular" [color]="Color.greyBlack"
      >
        <span [innerHTML]="errorMessage"></span>
      </app-label>
    </div>
  } @else if (hint) {
    <app-label id="hint" [typography]="Typography.body2" [weight]="FontWeight.regular" [color]="Color.grey700">
      {{hint}}
    </app-label>
  }
</div>
