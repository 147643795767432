import {
  Component, EventEmitter, Output, ViewChild,
} from '@angular/core';
import { BaseDialogComponent, DialogSize } from '@app/shared/components/atoms/dialog/base-dialog.component';
import { MESSAGES } from '@app/shared/utils/messages';
import { ButtonStyle } from '@app/shared/components/molecules/buttons/button.type';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
})
export class EditDialogComponent {
  protected readonly MESSAGES = MESSAGES;

  protected readonly DialogSize = DialogSize;

  protected readonly ButtonStyle = ButtonStyle;

  @ViewChild('dialog') dialog!: BaseDialogComponent;

  @Output() validateTrigger = new EventEmitter<any>();

  @Output() abortTrigger = new EventEmitter<any>();

  id: string | null = null;

  open(id?:string) {
    this.dialog.showModal();
    this.id = id || null;
  }

  protected confirm() {
    this.validateTrigger.emit(this.id);
    this.dialog.close();
  }

  protected cancel() {
    this.abortTrigger.emit();
    this.dialog.close();
  }
}
