import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Segment } from '@app/shared/models/segment';
import { UnitTypes } from '@app/shared/interfaces/consumptions.interface';

@Pipe({
  name: 'consumptionNumber',
})
export class ConsumptionNumberPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) { }

  transform(value?: string | number, segment?: Segment | string, unit?: UnitTypes) {
    const digitsInfo = (unit === UnitTypes.kWh) ? '1.0-0' : '1.0-3';
    return this.decimalPipe.transform(value, digitsInfo, 'fr-FR');
  }
}
