import { Component } from '@angular/core';

@Component({
  selector: 'app-main-loader',
  templateUrl: './main-loader.component.html',
  styleUrls: ['./main-loader.component.scss'],
  standalone: true,
})
export class MainLoaderComponent {

}
