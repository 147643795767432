import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private readonly assetsUrl: string = '/assets/i18n';

  private readonly placeholder: string = '$s';

  private jsonLang = {};

  constructor(
    private httpClient: HttpClient,
  ) { }

  initTranslation() {
    return this.httpClient.get(`${this.assetsUrl}/fr.json`, {
      observe: 'response',
    }).pipe(tap((res: HttpResponse<any>) => this.setJsonLang(res.body)));
  }

  getValue(path: string, params: string[] = []): string {
    const msg = path.split('.').reduce((o: any, k: string) => o && o[k], this.jsonLang) ?? 'invalid : '.concat(path);
    return this.replaceValues(msg, params);
  }

  setJsonLang(obj : any): void {
    this.jsonLang = obj;
  }

  replaceValues(message: string, params: string[]): string {
    return params.reduce(
      (currentMessage, param) => currentMessage.replace(this.placeholder, param),
      message,
    );
  }
}
