import {
  Component, Input, OnChanges, SimpleChanges,
} from '@angular/core';
import { FontWeight } from '@app/shared/models/font-weight';
import { Typography } from '@app/shared/models/typography';
import { Color } from '@app/shared/models/color';
import { LabelComponent } from '@app/shared/components/atoms/text/label/label.component';
import { CheckComponent } from '@app/shared/components/molecules/password-checker/check/check.component';
import { NgForOf } from '@angular/common';
import { regexSpecialCharacter } from '@app/shared/utils/regex';

interface Check {
  text: string;
  checked: boolean;
  handleCheck: () => void;
}

@Component({
  standalone: true,
  selector: 'app-password-checker',
  templateUrl: './password-checker.component.html',
  styleUrls: ['./password-checker.component.scss'],
  imports: [
    LabelComponent,
    CheckComponent,
    NgForOf,
  ],
})
export class PasswordCheckerComponent implements OnChanges {
  @Input() text!: string;

  checks: Check[] = [];

  constructor() {
    this.checks = this.initChecks();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text) {
      this.checks.forEach((check) => {
        check.handleCheck();
      });
    }
  }

  initChecks(): Check[] {
    return [
      {
        text: '12 caractères',
        checked: false,
        handleCheck: () => {
          this.checks[0].checked = this.text.length >= 12;
        },
      },
      {
        text: 'Une majuscule',
        checked: false,
        handleCheck: () => {
          // vérifie dans text qu'il y a au moins une majuscule
          this.checks[1].checked = this.text.match(/[A-Z]/) !== null;
        },
      },
      {
        text: 'Une minuscule',
        checked: false,
        handleCheck: () => {
          // vérifie dans text qu'il y a au moins une minuscule
          this.checks[2].checked = this.text.match(/[a-z]/) !== null;
        },
      },
      {
        text: 'Un chiffre',
        checked: false,
        handleCheck: () => {
          // vérifie dans text qu'il y a au moins un chiffre
          this.checks[3].checked = this.text.match(/[0-9]/) !== null;
        },
      },
      {
        text: 'Un caractère spécial',
        checked: false,
        handleCheck: () => {
          // vérifie dans text qu'il y a au moins un caractère spécial
          this.checks[4].checked = this.text.match(regexSpecialCharacter) !== null;
        },
      },
    ];
  }

  protected readonly FontWeight = FontWeight;

  protected readonly Typography = Typography;

  protected readonly Color = Color;
}
