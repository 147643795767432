import { DateTime } from 'luxon';
import { Address, RoutingPricing, SubscribedPower } from './site.interface';

export interface GetConsumptionsParams {
  prm: string;
  startDate: DateTime;
  endDate: DateTime;
  contractId: string;
  segment: string;
}

export interface GetCurveParams {
  prm: string;
  startDate: DateTime;
  endDate: DateTime;
  contractId: string;
}

export interface GetHistogramParams {
  prm: string;
  startDate: DateTime;
  endDate: DateTime;
  contractId: string;
  granularity?: string;
}

export interface GetHistogramStatisticsParams extends GetHistogramParams {
  timeSlicingName: string;
}

export interface IntervalPoint {
  startDate: string;
  endDate: string;
  value: number | null
}

export interface IntervalHistogramSerie {
  name: string;
  points: IntervalPoint[];
  total?: number;
}

export interface GetConsumptionsResponse {
  id: string,
  start: string,
  end: string,
  unit: UnitTypes;
  data: IntervalHistogramSerie[];
  total: number;
}

export interface GetLoadCurveResponse {
  start: string,
  end: string,
  unit: UnitTypes;
  data: IntervalHistogramSerie[];
  total: number;
}

export interface GetPeriodicConsumptionsResponse {
  start: string,
  end: string,
  unit: UnitTypes;
  data: IntervalHistogramSerie[];
  total: number;
}

export interface TotalInfo {
  value: number;
  unit: string;
}

export interface TotalPoste {
  id: number,
  name: string,
  libelle?: string
  totalInfo: TotalInfo,
}

export interface TotalConsumption {
  value: number,
  unit: string | UnitTypes,
}

export interface CurveDataPoint {
  date: string,
  value: number,
}

export interface CurveData {
  id: string,
  periodicity: string,
  unit: string,
  start: string,
  end: string,
  data: CurveDataPoint[],
  properties: {
    start: string,
    end: string
  }
}

export interface ConsumptionSitesOffer {
  id: string,
  exchangeRef: string,
  contractId: string,
  dateMax: DateTime,
  dateMin: DateTime,
  siteName: string,
  siteAddress: Address,
  segment: string,
  routingPricing?: RoutingPricing,
  routingSubscribedPowerList?: SubscribedPower[],
}

export interface GetTotalConsumptionParams {
  prm: string;
  startDate: DateTime;
  endDate: DateTime;
  contractId: string;
  segment: string;
}

export enum ConsumptionType {
  Consumption = 'Consumption',
  Curve = 'Curve',
}

export enum ConsumptionSteps {
  ConsumptionTypeStep = 0,
  PerimeterStep = 1,
}

export enum ConsumptionPeriods {
  MONTH1 = '1 mois',
  MONTH3 = '3 mois',
  YEAR1 = '1 an',
}

export enum ChartsTypes {
  CURVE = 'Courbe de charge',
  HISTOGRAM = 'Histogramme',
}

// TODO: revoir le nom apres la totale bascule sur le nouveau parcours de conso
export enum NewConsumptionType {
  PERIODICCONSUMPTION = 'PERIODICCONSUMPTION',
  CONSUMPTION = 'CONSUMPTION',
  CONSUMPTIONBYTARIFF = 'CONSUMPTIONBYTARIFF',
  COMPAREDCONSUMPTION = 'COMPAREDCONSUMPTION',
  LOADEDCURVE = 'LOADEDCURVE',

}

export enum NewChartsTypes {
  HISTOGRAM = 'Consommation',
  CURVE = 'Courbe de charge',
}

export enum ChartTypeTitles {
  HISTOGRAM = 'Ma consommation',
  CURVE = 'Ma courbe de charge',
}

export enum UnitTypes {
  MWh = 'MWh',
  kWh = 'kWh',
  Wh = 'Wh',
  MW = 'MW',
  kW = 'kW',
  W = 'W',
  kVA = 'kVA',
}

export enum ConsumptionPeriodEnum {
  YEARMINUS1 = 'YEARMINUS1',
  MONTHMINUS1 = 'MONTHMINUS1',
  WEEKMINUS1 = 'WEEKMINUS1',
  DAYMINUS1 = 'DAYMINUS1',
  CUSTOM = 'CUSTOM',
}

export enum ConsumptionPeriodLabelEnum {
  YEARMINUS1 = 'Semaine - 52',
  MONTHMINUS1 = 'Semaine - 4',
  WEEKMINUS1 = 'Semaine - 1',
  DAYMINUS1 = 'Jour - 1',
  CUSTOM = 'Personnalisé',
}

export enum GranularityEnum {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum GranularityLabelEnum {
  HOUR = 'Heure',
  DAY = 'Jour',
  WEEK = 'Semaine',
  MONTH = 'Mois',
  YEAR = 'Année',
}

export enum GranularityComparedLabelEnum {
  HOUR = 'Heure comparée',
  DAY = 'Jour comparé',
  WEEK = 'Semaine comparée',
  MONTH = 'Mois comparé',
  YEAR = 'Année comparée',
}

export enum ExportStartEndEnum {
  START = 'Début',
  END = 'Fin',
}

export enum CurvePeriodicity {
  '5MIN' = '5mn',
  '10MIN' = '10mn',
  '20MIN' = '20mn',
  '30MIN' = '30mn',
  HOURLY = 'hourly',
  DAILY = 'daily',
}
