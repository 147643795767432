import {
  Component, EventEmitter, Output, ViewChild,
} from '@angular/core';
import { BaseDialogComponent, DialogSize } from '@app/shared/components/atoms/dialog/base-dialog.component';
import { MESSAGES } from '@app/shared/utils/messages';
import { COGNITO_MESSAGES } from '@app/shared/utils/cognito-messages';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomMatchingValidators } from '@app/shared/validators/matching.validator';
import { AuthService } from '@app/shared/auth/auth.service';
import { INFORMATION } from '@app/shared/utils/information-constants';
import { regexSpecialCharacter } from '@app/shared/utils/regex';

@Component({
  selector: 'app-confirm-change-password-dialog',
  templateUrl: './confirm-change-password-dialog.component.html',
  styleUrls: ['./confirm-change-password-dialog.component.scss'],
})
export class ConfirmChangePasswordDialogComponent {
  protected readonly MESSAGES = MESSAGES;

  protected readonly DialogSize = DialogSize;

  protected isLoadingSubmit: boolean = false;

  @ViewChild('dialog') dialog!: BaseDialogComponent;

  changePasswordform = new UntypedFormGroup({
    oldPassword: new UntypedFormControl('', Validators.required),
    password: new UntypedFormControl(
      '',
      {
        validators:
          [Validators.required, Validators.minLength(12),
            CustomMatchingValidators.patternValidator(/\d/, { hasNumber: true }),
            CustomMatchingValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
            CustomMatchingValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            CustomMatchingValidators.patternValidator(regexSpecialCharacter, { hasSpecialCharacters: true }),
          ],
      },
    ),
    passwordConfirm: new UntypedFormControl('', Validators.required),
  }, {
    validators:
      [CustomMatchingValidators.mustMatch,
        CustomMatchingValidators.cantMatchOldPassword],
  });

  @Output() cancelTrigger = new EventEmitter<void>();

  @Output() validateTrigger = new EventEmitter<any>();

  constructor(
    public authService: AuthService,
  ) {}

  submitForm() {
    this.isLoadingSubmit = true;
    if (this.controls.password?.hasError('incorrectPassword')) {
      delete this.controls.password?.errors?.cantMatch;
    }
    if (this.controls.oldPassword?.hasError('incorrectOldPassword')) {
      delete this.controls.oldPassword?.errors?.cantMatch;
    }

    this.authService
      .changePassword(
        this.changePasswordform.get('oldPassword')?.value,
        this.changePasswordform.get('password')?.value,
      ).subscribe({
        next: () => {
          this.isLoadingSubmit = false;
          this.dialog.close();
          this.validateTrigger.emit();
        },
        error: (err) => {
          this.isLoadingSubmit = false;
          this.dialog.close();
          if (err.error.body === COGNITO_MESSAGES.CGNT_INCORRECT_USERNAME_PASSWORD) {
            this.controls.oldPassword.setErrors({ incorrectOldPassword: true });
          } else {
            this.controls.password.setErrors({ incorrectPassword: true });
          }
        },
      });
  }

  get controls() {
    return this.changePasswordform.controls;
  }

  get oldPasswordError(): string {
    if (this.controls.oldPassword.errors?.incorrectOldPassword) {
      return INFORMATION.CHANGE_PASSWORD_INCORRECT_OLD_PASSWORD;
    }

    return '';
  }

  get passwordError(): string {
    if (this.controls.password.errors?.cantMatch) {
      return INFORMATION.CHANGE_PASSWORD_CANT_MATCH;
    }

    return '';
  }

  get confirmPasswordError(): string {
    if (this.controls.passwordConfirm.errors?.mustMatch) {
      return MESSAGES.TXT98;
    }

    return '';
  }

  get passwordErrors() {
    const controlErrors = this.controls.password?.errors;

    if (controlErrors && (controlErrors.size > 1
      || (!controlErrors?.cantMatch && !controlErrors?.incorrectPassword))) {
      return true;
    }
    return false;
  }

  cancel() {
    this.cancelTrigger.emit();
    this.dialog.close();
  }
}
