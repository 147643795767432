import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  map, Observable, Subject, takeUntil, tap,
} from 'rxjs';
import { getSignalFromJson, SignalResponse, Signal } from '@app/shared/interfaces/signal';

import { API_ROUTES } from '@app/shared/utils/api-routes';
import { OffersService } from '@app/offers/offers.service';
import { SiteOffersService } from '@app/site-offers/site-offers.service';
import { BASE_API_URL } from '@app/shared/utils/api';
import {
  CurveData,
  CurveDataPoint,
  GetTotalConsumptionParams,
  TotalConsumption,
  UnitTypes,
} from '@app/shared/interfaces/consumptions.interface';
import { AuthService } from '@app/shared/auth/auth.service';
import { User } from '@app/shared/models/user-info';
import { unitConversion } from '@app/shared/utils/unit-convertion';
import { SessionStorageService } from '@app/shared/auth/session-storage.service';
import { UserResponse } from '@app/shared/interfaces/user-response.interface';

@Injectable({
  providedIn: 'root',
})
export class HomePageService {
  userConnected?: User;

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    public offersService: OffersService,
    public siteOffersService: SiteOffersService,
    private sessionStorage: SessionStorageService,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  public getSignals(): Observable<Signal> {
    const url = `${BASE_API_URL.CA_API_BASE_URL_SIGNAL}${API_ROUTES.SIGNALS}`;
    return this.httpClient.get<SignalResponse>(
      url,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).pipe(
      map((response: SignalResponse) => getSignalFromJson(response)),
    );
  }

  public getConsumptionData({
    prm,
    startDate,
    endDate,
    contractId,
    segment,
  }: GetTotalConsumptionParams): Observable<TotalConsumption> {
    let params = new HttpParams()
      .append('prm', prm)
      .append('contractId', contractId)
      .append('startDate', startDate.toISODate())
      .append('endDate', endDate.toISODate())
      .append('granularity', 'day');

    if (this.userConnected?.email) {
      params = params.append('email', this.userConnected?.email);
    }
    if (this.userConnected?.selectedProfile) {
      params = params.append('profile', this.userConnected?.selectedProfile.id);
    }

    return this.httpClient
      .get<CurveData>(
      `${BASE_API_URL.CA_API_BASE_URL_OPERA}${API_ROUTES.CONSUMPTION}`,
      {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).pipe(
      map(({ unit, data }: CurveData) => {
        const value = data.reduce(
          (accumulator: number, currentValue: CurveDataPoint) => accumulator + currentValue.value,
          0,
        );

        if (segment !== 'C5') {
          return {
            value: unitConversion(value, unit, UnitTypes.MWh),
            unit: UnitTypes.MWh,
          };
        }
        return {
          value,
          unit: UnitTypes.kWh,
        };
      }),
    );
  }

  public postDismissSurvey() {
    let params = new HttpParams();
    if (this.userConnected?.email) {
      params = params.append('email', this.userConnected?.email);
    }
    if (this.userConnected?.selectedProfile) {
      params = params.append('profile', this.userConnected?.selectedProfile.id);
    }
    const body = { refreshToken: this.sessionStorage.get(AuthService.REFRESH_TOKEN) };

    return this.httpClient.post<UserResponse>(
      `${BASE_API_URL.CA_API_BASE_URL_IAM}${API_ROUTES.SURVEY}`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        params,
      },
    ).pipe(
      tap((res: UserResponse) => {
        this.sessionStorage.set(AuthService.ACCESS_TOKEN, res.authenticationResult.accessToken);
        this.sessionStorage.set(AuthService.ID_TOKEN, res.authenticationResult.idToken);
      }),
    );
  }
}
