import { Component, Input } from '@angular/core';
import { LabelComponent } from '@app/shared/components/atoms/text/label/label.component';
import { Typography } from '@app/shared/models/typography';
import { FontWeight } from '@app/shared/models/font-weight';
import { Color } from '@app/shared/models/color';
import { NgIf } from '@angular/common';
import { IconComponent, Size } from '@app/shared/components/atoms/icon/icon.component';

@Component({
  selector: 'app-check',
  standalone: true,
  imports: [LabelComponent, NgIf, IconComponent],
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
})
export class CheckComponent {
  @Input() text?: string;

  @Input() isChecked: boolean = false;

  protected readonly Typography = Typography;

  protected readonly FontWeight = FontWeight;

  protected readonly Color = Color;

  protected readonly Size = Size;
}
