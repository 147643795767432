import { Component } from '@angular/core';

@Component({
  selector: 'app-leaf-loader',
  templateUrl: './leaf-loader.component.html',
  styleUrls: ['./leaf-loader.component.scss'],
  standalone: true,
})
export class LeafLoaderComponent {
}
