import { NgModule } from '@angular/core';
import { PhoneNumberFormatPipe } from '@app/shared/pipes/phone-number-format.pipe';
import { KwhToMwhConversionPipe } from '@app/shared/pipes/kwh-to-mwh-conversion.pipe';
import { CustomNumberPipe } from '@app/shared/pipes/custom-number.pipe';
import { CivilityPipe } from '@app/shared/pipes/civility.pipe';
import { FormControlPipe } from '@app/shared/pipes/form-control.pipe';
import { PerimeterNameConcatPipe } from '@app/shared/pipes/perimeter-name-concat.pipe';
import { ConsumptionNumberPipe } from '@app/shared/pipes/power-unit/consumption-number.pipe';
import { LoadCurveNumberPipe } from '@app/shared/pipes/power-unit/load-curve-number.pipe';
import { HighlightPipe } from '../pipes/highlight.pipe';
import { UpperCaseFirstLettersPipe } from '../pipes/upper-case-first-letters.pipe';

const pipeModules = [
  HighlightPipe,
  CustomNumberPipe,
  PhoneNumberFormatPipe,
  KwhToMwhConversionPipe,
  FormControlPipe,
  CivilityPipe,
  PerimeterNameConcatPipe,
  ConsumptionNumberPipe,
  LoadCurveNumberPipe,
  UpperCaseFirstLettersPipe,
];

@NgModule({
  declarations: [
    ...pipeModules,
  ],
  exports: [
    ...pipeModules,
  ],
})
export class PipeModule {
}
