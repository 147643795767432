import { AlertTypeEnum } from '@app/shared/components/molecules/alert-card/alert-card.model';

export class Alert {
  id: string = '';

  type!: AlertTypeEnum;

  action?: string;

  message: string = '';

  icon?: string = '';

  duration?: number = 15000;

  horizontalPosition: string = 'center';

  verticalPosition: string = 'bottom';

  keepAfterNavigationChange: boolean = false;

  constructor(init?:Partial<Alert>) {
    Object.assign(this, init);
  }
}
