import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component, ElementRef, Input, ViewChild,
} from '@angular/core';
import { TooltipPositionEnum } from './tooltip.enum';

@Component({
  standalone: true,
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements AfterViewChecked {
  @Input() message!: string;

  maxWidth?: number;

  position?: TooltipPositionEnum;

  left: number = 0;

  top: number = 0;

  parentNativeElement?: any;

  @ViewChild('tooltipElement') elementRef?: ElementRef;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewChecked(): void {
    const { elementRef } = this;
    if (elementRef) {
      const { top, left } = this.calcTooltipPosition();
      this.top = top;
      this.left = left;
    }
    this.changeDetectorRef.detectChanges();
  }

  private calcTooltipPosition() {
    let left;
    let top;
    const tooltipMargin = 15;
    const { position } = this;
    const {
      left: parentLeft,
      top: parentTop,
    } = this.parentNativeElement.getBoundingClientRect();
    const {
      offsetWidth: parentOffsetWidth,
      offsetHeight: parentOffsetHeight,
    } = this.parentNativeElement;
    const {
      offsetWidth, offsetHeight,
    } = this.elementRef!.nativeElement;

    switch (position) {
      case TooltipPositionEnum.bottom:
        left = parentLeft
          + parentOffsetWidth / 2
          - offsetWidth / 2;
        top = parentTop
          + parentOffsetHeight
          + tooltipMargin;
        break;
      case TooltipPositionEnum.right:
        left = parentLeft
          + parentOffsetWidth
          + tooltipMargin;
        top = parentTop
          - offsetHeight / 2
          + parentOffsetHeight / 2;
        break;
      case TooltipPositionEnum.left:
        left = parentLeft
          - offsetWidth
          - tooltipMargin;
        top = parentTop
          - offsetHeight / 2
          + parentOffsetHeight / 2;
        break;
      case TooltipPositionEnum.top:
      default:
        left = parentLeft
          + parentOffsetWidth / 2
          - offsetWidth / 2;
        top = parentTop
          - offsetHeight
          - tooltipMargin;
        break;
    }

    return { top, left };
  }
}
