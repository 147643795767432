<dialog appBaseDialog #dialog id="monthly-subscription-modal" [size]="DialogSize.MEDIUM" (cancelTrigger)="cancel()" >
  <ng-template appDialogHeader>
    <span>{{ (segment === 'C5') ? 'Arrêt collecte courbe de charge': 'Données mensuelles'}}</span>
  </ng-template>

  <ng-template appDialogContent>
    <p>{{ (segment === 'C5') ? MESSAGES.TXT67 : MESSAGES.TXT45 }}</p>
  </ng-template>

  <ng-template appDialogFooter>
    <app-button
      buttonId="cancel-request-btn"
      [style]="ButtonStyle.Outlined"
      (onclick)="cancel()"
    >Annuler</app-button>

    <app-button
      buttonId="validate-btn"
      (onclick)="submitForm()"
    >Accepter</app-button>
  </ng-template>
</dialog>
