import {
  AfterContentChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild,
} from '@angular/core';
import { Color } from '@app/shared/models/color';
import { ButtonComponent } from '@app/shared/components/molecules/buttons/button.component';
import { ButtonIcon, ButtonSize, ButtonStyle } from '@app/shared/components/molecules/buttons/button.type';
import { NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { AlertTypeEnum } from '@app/shared/components/molecules/alert-card/alert-card.model';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { IconComponent, Size, Style } from '../../atoms/icon/icon.component';

type StyleOption = {
  color?: string,
  customIconSrc?: string,
  strip?: boolean,
  iconCenterAlign?: boolean
};

@Component({
  selector: 'app-alert-card',
  standalone: true,
  imports: [
    IconComponent,
    ButtonComponent,
    NgIf,
    NgStyle,
    NgOptimizedImage,
  ],
  templateUrl: './alert-card.component.html',
  styleUrl: './alert-card.component.scss',
})

export class AlertCardComponent implements AfterContentChecked {
  @Input() type?: AlertTypeEnum = AlertTypeEnum.Info;

  @Input() description?: string;

  @Input() styleOption?: StyleOption;

  @Input() title?: string;

  @Input() closeIcon?: boolean = false;

  @Output() closeEvent = new EventEmitter<void>();

  @ViewChild('customAlertIcon') protected customAlertIconElRef!: ElementRef;

  constructor(private el: ElementRef) {
  }

  public get getType() {
    const alertTypeConfig = {
      [AlertTypeEnum.Success]: { type: 'success', iconClass: 'ph-check-circle', color: Color.positive },
      [AlertTypeEnum.Error]: { type: 'danger', iconClass: 'ph-warning-circle', color: Color.danger500 },
      [AlertTypeEnum.Info]: { type: 'info', iconClass: 'ph-info', color: Color.info500 },
      [AlertTypeEnum.Warning]: { type: 'warning', iconClass: 'ph-warning', color: Color.warning500 },
    };

    return alertTypeConfig[this.type!];
  }

  ngAfterContentChecked() {
    const { style } = this.el.nativeElement;
    let curColor = this.getType.color.toString();

    if (this.styleOption) {
      const { color, strip, customIconSrc } = this.styleOption;

      if (color && customIconSrc && this.customAlertIconElRef) {
        curColor = color;
        const filterCss = hexToCSSFilter(color).filter;
        this.customAlertIconElRef.nativeElement.style.filter = `${filterCss.substring(0, filterCss.length - 1)}`;
      }

      if (!strip) {
        style.setProperty('--border-left-style', 'none');
      }
    }

    style.setProperty('--alert-color', curColor);
  }

  protected readonly Color = Color;

  protected readonly Style = Style;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonStyle = ButtonStyle;

  protected readonly ButtonIcon = ButtonIcon;

  protected readonly Size = Size;
}
