<div id="sidenav-container" [class.loading]="loading$ | async">
  <app-sidenav-menu></app-sidenav-menu>
  <div id="sidenav-content">
    <app-header class="header"></app-header>
    <app-alert></app-alert>
    <div *ngIf="containsOffers(); then thenRouterOutletBlock else elseWithoutOffer"></div>
    <ng-template #thenRouterOutletBlock>
      <router-outlet></router-outlet>
    </ng-template>
    <ng-template #elseWithoutOffer>
      <app-offers-missing></app-offers-missing>
    </ng-template>
    <app-footer></app-footer>
  </div>
</div>
<app-survey-toast></app-survey-toast>
