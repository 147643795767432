<div class="toggle-container" [class.disabled]="disabled" [class]="size" >
  <button *ngFor="let option of options"
    id="tabs-buttons-{{option.replaceAll(' ', '-')}}"
       class="toggle-option"
       [class.selected]="currentOption === option"
       (click)="onToggle(option)"
          [disabled]="disabled"
  >
    {{option}}
  </button>
</div>
