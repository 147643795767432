import {
  AfterViewInit,
  Component, ElementRef, Input, ViewChild,
} from '@angular/core';
import { Color } from '@app/shared/models/color';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
})
export class SpinnerComponent implements AfterViewInit {
  @Input() mainColor: Color = Color.greyBlack;

  @Input() secondColor: Color = Color.primary600;

  @Input() scaling: number = 1;

  @Input() diameter: string = '';

  @ViewChild('spinner') private spinnerEl!: ElementRef;

  @ViewChild('spinnerContainer') private containerEl!: ElementRef;

  ngAfterViewInit(): void {
    this.spinnerEl.nativeElement.style.setProperty('--main-color', this.mainColor);
    this.spinnerEl.nativeElement.style.setProperty('--second-color', this.secondColor);
    this.containerEl.nativeElement.style.width = `${this.diameter}`;
    this.containerEl.nativeElement.style.height = `${this.diameter}`;
  }
}
