<div class="container" [class.disabled]="disabled">
  <button *ngFor="let tab of tabs"
          id="tabs-buttons-{{tab.label.replaceAll(' ', '-')}}"
          class="btn"
          [class.selected]="currentTab?.label === tab.label"
          (click)="onToggle(tab)"
          [disabled]="disabled"
  >
    {{tab.label}}
    <div class="btn__bg--selected"></div>
    <div class="btn__bg--hovered"></div>
    <app-icon *ngIf="tab.tooltip"
        class="tooltip"
        phosphorClass="ph-info"
              [iconStyle]="tab.label === this.currentTab?.label ? Style.fill : undefined"
        [appTooltip]="tab.tooltip"
              [size]="Size.small"
        appTooltipPosition="right" [appTooltipMaxWidth]="28">
    </app-icon>
  </button>
</div>
