<div class="loading">
  <div class="column-1"></div>
  <div class="column-2"></div>
  <div class="column-3"></div>
  <div class="column-4"></div>
  <div class="column-5"></div>
  <div class="column-6"></div>
  <div class="column-7"></div>
  <div class="column-8"></div>
</div>
