import {
  Directive, TemplateRef,
} from '@angular/core';

interface Context<T> {
  $implicit?: T; // current item exposed as implicit value
  index?: number;
}

@Directive({
  selector: '[appTableCell]',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class TableCellDirective<T> {
  constructor(
    public templateRef: TemplateRef<any>,
  ) {
  }

  static ngTemplateContextGuard<T>(
    dir: TableCellDirective<T>,
    ctx: any,
  ): ctx is Context<T> {
    return true;
  }
}
