<app-textfield [value]="value"
               (valueChange)="onChange($event)"
               [title]="title"
               [type]="type"
               autocomplete="off"
               [placeholder]="placeholder"
               [prefixIconOption]="prefixIcon"
               [errorMessage]="errorMessage"
               [isOnError]="isOnError"
               [maxLength]="maxLength"
               [focusOnView]="focusOnView"
               (inputFocus)="onFocus()"
               (inputFocusOut)="onFocusOut()">
</app-textfield>
