import { Component, OnInit } from '@angular/core';
import { MESSAGES } from '@app/shared/utils/messages';
import { FluidService } from '@app/shared/services/fluid.service';
import { Fluid } from '@app/shared/models/fluid.model';
import { WelcomeCardTheme } from '@app/home-page/main-content/welcome-card/welcome-card.component';

@Component({
  selector: 'app-offers-missing',
  templateUrl: './offers-missing.component.html',
  styleUrls: ['./offers-missing.component.scss'],
})
export class OffersMissingComponent implements OnInit {
  MESSAGES = MESSAGES;

  currentFluid?: Fluid;

  constructor(private fluidService: FluidService) {}

  ngOnInit() {
    this.fluidService.currentFluid$().subscribe({
      next: ({ fluid }) => {
        this.currentFluid = fluid;
      },
    });
  }

  getMessage(): string {
    const { currentFluid: fluid } = this;

    if (!fluid) return '';

    const message: string = MESSAGES.TXT114;
    return message.replaceAll('$$fluid', fluid.value);
  }

  protected readonly WelcomeCardTheme = WelcomeCardTheme;
}
