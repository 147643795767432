import { Style as IconStyle } from '@app/shared/components/atoms/icon/icon.component';

export enum ButtonStyle {
  Primary = 'primary',
  Secondary = 'secondary',
  Outlined = 'outlined',
  Textual = 'textual',
}

export enum ButtonSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  Pagination = 'pagination',
}

export enum ButtonIcon {
  LeftIcon = 'leftIcon',
  RightIcon = 'rightIcon',
  OnlyIcon = 'onlyIcon',
}

export enum ButtonSpacing {
  FitContent = 'fitContent',
  FullSpacing = 'fullWidth',
}

export interface ButtonIconOption {
  phosphorClass: string,
  iconStyle?: IconStyle,
  position: ButtonIcon,
}
