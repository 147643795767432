import {
  ChangeDetectionStrategy,
  Component, Input, OnChanges, SimpleChanges,
} from '@angular/core';
import { MESSAGES } from '@app/shared/utils/messages';
import { SpecialDays } from '@app/shared/interfaces/date.interface';
import { Style } from '@app/shared/components/atoms/icon/icon.component';

interface LegendInfo {
  label: string;
  color: string;
  tooltipTitle?: string;
}

@Component({
  selector: 'app-special-days-legend',
  templateUrl: './special-days-legend.component.html',
  styleUrls: ['./special-days-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialDaysLegendComponent implements OnChanges {
  @Input() specialDays?: SpecialDays;

  @Input() enableSubscribedPower?: boolean;

  legendInfos: LegendInfo[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.specialDays || changes.enableSubscribedPower) {
      this.initLegendInfo();
    }
  }

  addLegendInfo(label: string, color: string, tooltipTitle?: string) {
    this.legendInfos.push({ label, color, tooltipTitle });
  }

  resetLegendInfo() {
    this.legendInfos = [];
  }

  initLegendInfo() {
    const { specialDays, enableSubscribedPower } = this;
    const { weekendDays, publicHolidays, pp1Dates } = specialDays || {};

    this.resetLegendInfo();
    if (pp1Dates && pp1Dates.length > 0) {
      this.addLegendInfo('Jour PP1', '#FFDFBF', MESSAGES.TXT33);
    }

    if (weekendDays && weekendDays.length > 0) {
      this.addLegendInfo('Weekend', '#DEE0E6');
    }

    if (publicHolidays && publicHolidays.length > 0) {
      this.addLegendInfo('Jour férié', '#ADB2BD');
    }

    if (enableSubscribedPower) {
      this.addLegendInfo('Puissance souscrite', '#2F7ED8');
    }
  }

  protected readonly Style = Style;
}
