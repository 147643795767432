import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ChartModule } from 'angular-highcharts';
import { CdkModule } from '@app/shared/modules/cdk.module';
import { HttpClientModule } from '@angular/common/http';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { setOptions } from 'highcharts';

HC_exporting(Highcharts);
setOptions({
  lang: {
    contextButtonTitle: 'Menu',
    viewFullscreen: 'Plein écran',
    printChart: 'Imprimer',
    exitFullscreen: 'Fermer',
    downloadJPEG: 'Exporter au format JPEG',
    downloadPNG: 'Exporter au format PNG',
    downloadPDF: 'Exporter au format PDF',
    downloadSVG: 'Exporter au format SVG',
    months: [
      'Janvier', 'Février', 'Mars', 'Avril',
      'Mai', 'Juin', 'Juillet', 'Août',
      'Septembre', 'Octobre', 'Novembre', 'Décembre',
    ],
    weekdays: [
      'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
      'Jeudi', 'Vendredi', 'Samedi',
    ],
    shortMonths: [
      'janv.', 'févr.', 'mars', 'avr.',
      'mai', 'juin', 'juil.', 'août',
      'sept.', 'oct.', 'nov.', 'déc.',
    ],
    loading: 'Chargement...',
    noData: 'Aucune donnée visible',
    resetZoom: 'Dézoomer',
    decimalPoint: ',',
  },
});

@NgModule({
  exports: [
    CdkModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    ChartModule,
  ],
})
export class SharedLibsModule {
  static forRoot() {
    return {
      ngModule: SharedLibsModule,
    };
  }
}
