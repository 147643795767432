<div class="paginator-container" *ngIf="pageable.total > 0">
  <fieldset [disabled]="disabled">
    <span>{{ rangeMin }} à {{ rangeMax }} sur {{ pageable.total }}</span>
    <ul class="paginator">
      <li>
        <app-button
          buttonId="caret-left-btn"
          [style]="ButtonStyle.Secondary"
          [size]="ButtonSize.Pagination"
          [iconOptions]="{phosphorClass: 'ph-caret-left', position: ButtonIcon.OnlyIcon}"
          [isDisabled]="currentPage === 1"
          (onclick)="changePage(currentPage -2)"
          ></app-button>
      </li>

      <li *ngIf="totalPage > 5">
        <app-button
          buttonId="{{currentPage === 1 && 'active'}}"
          [style]="currentPage === 1 ? ButtonStyle.Primary : ButtonStyle.Secondary"
          [isSelected]="currentPage === 1"
          [size]="ButtonSize.Pagination"
          (onclick)="changePage(0)">
          {{totalPage ? '1' : '0'}}
        </app-button>
      </li>

      <li *ngIf="selectPages[0] >= 3">
        <span>...</span>
      </li>

      <li *ngFor="let page of selectPages">
        <app-button
          buttonId="{{currentPage === page && 'active'}}"
          [style]="currentPage === page ? ButtonStyle.Primary : ButtonStyle.Secondary"
          [isSelected]="currentPage === page"
          [size]="ButtonSize.Pagination"
          (onclick)="changePage(page - 1)"
        >{{page}}</app-button>
      </li>

      <li *ngIf="selectPages[2] <= (totalPage-2) && totalPage > 5">
        <span>...</span>
      </li>

      <li *ngIf="totalPage > 5">
        <app-button
          buttonId="{{currentPage === totalPage  && 'active'}}"
          [style]="currentPage === totalPage  ? ButtonStyle.Primary : ButtonStyle.Secondary"
          [isSelected]="currentPage === totalPage "
          [size]="ButtonSize.Pagination"
          (onclick)="changePage(totalPage - 1)"
        >{{totalPage}}</app-button>
      </li>

      <li>
        <app-button
          buttonId="caret-right-btn"
          [style]="ButtonStyle.Secondary"
          [size]="ButtonSize.Pagination"
          [iconOptions]="{phosphorClass: 'ph-caret-right', position: ButtonIcon.OnlyIcon}"
          [isDisabled]="currentPage === totalPage || totalPage === 0"
          (onclick)="changePage(currentPage)"
        ></app-button>
      </li>
    </ul>
    <div class="displayed-item-selected-container">
      <span>Nombre de résultats par page : </span>
      <app-selector
        selectId="paginator-limit-select"
        [coverSpacing]="'spaceEvenly'"
        [params]="itemLimitArray"
        [value]="pageable.limit.toString()"
        (valueChange)="changeDisplayedItemNumber(Number($event))">
      </app-selector>
    </div>
  </fieldset>
</div>
