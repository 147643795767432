import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertTypeEnum } from '@app/shared/components/molecules/alert-card/alert-card.model';
import { Alert } from './alert.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private subject: BehaviorSubject<Alert | null> = new BehaviorSubject<Alert | null>(null);

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string, options?: {
    icon?: string, duration?: number,
    horizontalPosition?: string, verticalPosition?: string, keepAfterNavigationChange?: boolean
  }) {
    this.alert({
      ...options, type: AlertTypeEnum.Success, message,
    });
  }

  error(message: string, options?: {
    icon?: string, duration?: number, horizontalPosition?: string,
    verticalPosition?: string, keepAfterNavigationChange?: boolean
  }) {
    this.alert({
      ...options, type: AlertTypeEnum.Error, message,
    });
  }

  info(message: string, options?: {
    icon?: string, duration?: number, horizontalPosition?: string,
    verticalPosition?: string, keepAfterNavigationChange?: boolean
  }) {
    this.alert({
      ...options, type: AlertTypeEnum.Info, message,
    });
  }

  warn(message: string, options?: {
    icon?: string, duration?: number, horizontalPosition?: string,
    verticalPosition?: string, keepAfterNavigationChange?: boolean
  }) {
    this.alert({
      ...options, type: AlertTypeEnum.Warning, message,
    });
  }

  alert(data: {}) {
    const alert = new Alert(data);
    this.subject.next(alert);
  }

  clear() {
    this.subject.next(null);
  }
}
