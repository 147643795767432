import {
  Directive, HostBinding, Input, OnInit,
} from '@angular/core';

@Directive({
  selector: '[appTable]',
  standalone: true,
})
export class TableDirective implements OnInit {
  @HostBinding('style.table-layout') tableLayout: ('fixed' | 'auto') = 'fixed';

  @Input() tableLayoutAuto?: boolean = true;

  ngOnInit(): void {
    if (this.tableLayoutAuto) this.tableLayout = 'auto';
  }
}
