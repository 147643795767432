import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-standard-image',
  templateUrl: './standard-image.component.html',
  styleUrls: ['./standard-image.component.scss'],
  standalone: true,
})
export class StandardImageComponent {
  @Input() src: string = '';

  @Input() alt: string = '';
}
