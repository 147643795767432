import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { FilterType } from '@app/shared/components/table-filters/filters-type';
import {
  ButtonIcon,
  ButtonSize,
  ButtonStyle,
} from '@app/shared/components/molecules/buttons/button.type';
import { Size } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';

@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
})
export class TableFiltersComponent {
  @Input() disabledButton: boolean = false;

  @Input() filters = new Map<FilterType, string>();

  @Output() filtersChange = new EventEmitter<Map<FilterType, string>>();

  get filterIsActived() {
    return this.filters.size > 0;
  }

  removeFilter(filter: FilterType) {
    const newFilters = new Map(this.filters);
    newFilters.delete(filter);
    this.filtersChange.emit(newFilters);
  }

  removeAllFilters() {
    const newFilters = new Map();
    this.filters = newFilters;
    this.filtersChange.emit(newFilters);
  }

  protected readonly Size = Size;

  protected readonly Color = Color;

  protected readonly ButtonStyle = ButtonStyle;

  protected readonly ButtonIcon = ButtonIcon;

  protected readonly ButtonSize = ButtonSize;
}
