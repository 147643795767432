<div class="legend-bloc">
  <div class="legend-title">
    <app-icon phosphorClass="ph-git-commit" [iconStyle]="Style.fill"></app-icon>
    <p class="title">Légende</p>
  </div>
  <div class="legend-content">
    <div class="legend-item" *ngFor="let legendItem of legendInfos">
      <span class="bullet" [style.color]="legendItem.color">●</span>
      <span class="content-data">{{ legendItem.label }}</span>
      <app-icon *ngIf="legendItem.tooltipTitle"
          phosphorClass="ph-info"
          [iconStyle]="Style.fill"
          class="tooltip"
          [appTooltip]="legendItem.tooltipTitle"
          appTooltipPosition="top">
      </app-icon>
    </div>
  </div>
</div>
