import { Component, Input } from '@angular/core';
import { Typography } from '@app/shared/models/typography';
import { Color } from '@app/shared/models/color';
import { FontWeight } from '@app/shared/models/font-weight';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  imports: [CommonModule],
})

export class LabelComponent {
  @Input() typography?: Typography = Typography.body1;

  @Input() weight?: FontWeight = FontWeight.medium;

  @Input() color: Color = Color.grey800;

  @Input() redirectUrl?: string;

  @Input() IsQueryParamsPreserve: boolean = true;

  constructor(private router: Router) {
  }

  redirectTo() {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl], { queryParamsHandling: (this.IsQueryParamsPreserve ? 'preserve' : '') });
    }
  }
}
