import {
  AfterContentChecked,
  Directive, Host, HostBinding, Input, OnInit, Optional,
} from '@angular/core';
import { ColumnDirective } from '@app/shared/components/atoms/table/directives/column.directive';

const defaultBorderStyle = '1px solid #E9EBEF';

@Directive({
  selector: 'appCell, td[appCell]',
})
export class CellDirective implements OnInit, AfterContentChecked {
  @HostBinding('style.border-up') borderUp?: string;

  @HostBinding('style.border-right') borderRight?: string;

  @HostBinding('style.border-left') borderLeft?: string;

  @HostBinding('style.text-align-last') @Input() align?: string;

  @HostBinding('style.text-align') @Input() textAlign?: string;

  @HostBinding('style.white-space') @Input() whiteSpace: string = 'pre-wrap';

  @Input() border?: 'left' | 'right' | 'bottom' | 'up';

  @HostBinding('style.width') @Input() width?: string;

  @HostBinding('style.display') display?: string;

  constructor(
    @Optional() @Host() private columnDirective: ColumnDirective,
  ) {}

  ngOnInit(): void {
    const { border } = this;

    switch (border) {
      case 'up':
        this.borderUp = defaultBorderStyle;
        break;
      case 'right':
        this.borderRight = defaultBorderStyle;
        break;
      case 'left':
        this.borderLeft = defaultBorderStyle;
        break;
      case 'bottom':
      default:
        break;
    }
  }

  ngAfterContentChecked(): void {
    this.display = this.columnDirective?.showColumn() ? undefined : 'none';
  }
}
