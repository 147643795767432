import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { IconComponent, Size as IconSize } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';
import {
  ButtonIcon,
  ButtonIconOption,
  ButtonSize,
  ButtonSpacing,
  ButtonStyle,
} from '@app/shared/components/molecules/buttons/button.type';
import { SpinnerComponent } from '@app/shared/components/atoms/loaders/spinner/spinner.component';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [IconComponent, SpinnerComponent, NgIf, NgTemplateOutlet],
})
export class ButtonComponent implements AfterViewInit, OnChanges {
  @Input() style: ButtonStyle = ButtonStyle.Primary;

  @Input() type: 'button' | 'submit' | 'reset' = 'button';

  @Input() isLoading: boolean = false;

  @Input() isDisabled: boolean = false;

  @Input() isSelected: boolean = false;

  @Input() size: ButtonSize = ButtonSize.Medium;

  @Input() spacing: ButtonSpacing = ButtonSpacing.FitContent;

  @Input() iconOptions?: ButtonIconOption;

  @Input() buttonId?: string;

  @Output() onclick = new EventEmitter<Event>();

  @ViewChild('DefaultTmpl') protected defaultTmpl!: TemplateRef<null>;

  @ViewChild('LeftIconTmpl') protected leftIconTmpl!: TemplateRef<string>;

  @ViewChild('RightIconTmpl') protected rightIconTmpl!: TemplateRef<string>;

  @ViewChild('OnlyIconTmpl') protected onlyIconTmpl!: TemplateRef<string>;

  @ViewChild('buttonElement') protected buttonRef!: ElementRef;

  protected appliedTmpl: TemplateRef<any> | null = this.defaultTmpl;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngAfterViewInit() {
    this.applyTemplate(this.iconOptions);
    this.buttonRef.nativeElement.classList.add(this.size, this.style, this.spacing);
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.iconOptions && !changes.iconOptions.firstChange) {
      this.applyTemplate(changes.iconOptions.currentValue);
    }

    if (changes.size && !changes.size?.firstChange) {
      const { currentValue, previousValue } = changes.size;
      this.buttonRef.nativeElement.classList.replace(previousValue, currentValue);
    }

    if (changes.style && !changes.style?.firstChange) {
      const { currentValue, previousValue } = changes.style;
      this.buttonRef.nativeElement.classList.replace(previousValue, currentValue);
    }

    if (changes.state && !changes.state?.firstChange) {
      const { currentValue, previousValue } = changes.state;
      this.buttonRef.nativeElement.classList.replace(previousValue, currentValue);
    }

    if (changes.spacing && !changes.spacing?.firstChange) {
      const { currentValue, previousValue } = changes.spacing;
      this.buttonRef.nativeElement.classList.replace(previousValue, currentValue);
    }
  }

  private applyTemplate(iconOptions?: ButtonIconOption) {
    if (!iconOptions) {
      this.appliedTmpl = this.defaultTmpl;
    } else {
      switch (iconOptions.position) {
        case ButtonIcon.LeftIcon:
          this.appliedTmpl = this.leftIconTmpl;
          break;
        case ButtonIcon.RightIcon:
          this.appliedTmpl = this.rightIconTmpl;
          break;
        case ButtonIcon.OnlyIcon:
        default:
          this.buttonRef.nativeElement.classList.add(ButtonIcon.OnlyIcon);
          this.appliedTmpl = this.onlyIconTmpl;
      }
    }
  }

  protected get iconSize(): IconSize {
    const iconSizeMap = {
      [ButtonSize.Small]: {
        noIcon: IconSize.small,
        [ButtonIcon.LeftIcon]: IconSize.small,
        [ButtonIcon.RightIcon]: IconSize.small,
        [ButtonIcon.OnlyIcon]: IconSize['medium-small'],
      },
      [ButtonSize.Large]: {
        noIcon: IconSize.medium,
        [ButtonIcon.LeftIcon]: IconSize.medium,
        [ButtonIcon.RightIcon]: IconSize.medium,
        [ButtonIcon.OnlyIcon]: IconSize.large,
      },
      [ButtonSize.Medium]: {
        noIcon: IconSize['medium-small'],
        [ButtonIcon.LeftIcon]: IconSize['medium-small'],
        [ButtonIcon.RightIcon]: IconSize['medium-small'],
        [ButtonIcon.OnlyIcon]: IconSize.medium,
      },
      [ButtonSize.Pagination]: {
        noIcon: IconSize.small,
        [ButtonIcon.LeftIcon]: IconSize.small,
        [ButtonIcon.RightIcon]: IconSize.small,
        [ButtonIcon.OnlyIcon]: IconSize.small,
      },
    };

    return iconSizeMap[this.size]?.[(this.iconOptions?.position) || 'noIcon'];
  }

  protected get getIconColor() {
    return this.isDisabled ? Color.grey600 : Color.greyBlack;
  }

  protected get getSpinnerColor() {
    switch (this.style) {
      case ButtonStyle.Primary:
        return { mainColor: Color.greyBlack, secondColor: Color.primary600 };
      case ButtonStyle.Secondary:
      case ButtonStyle.Textual:
      case ButtonStyle.Outlined:
      default:
        return { mainColor: Color.greyBlack, secondColor: Color.grey400 };
    }
  }

  protected onClickEvent($event: Event) {
    this.onclick.emit($event);
  }
}
