import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class CheckboxComponent {
  @Input('aria-label') ariaLabel = '';

  @Input() disabled = false;

  @Input() checked = false;

  @Output() checkedChange = new EventEmitter<boolean>();

  @Input() indeterminate = false;

  toggleCheckbox() {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
