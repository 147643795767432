import { Component, OnInit } from '@angular/core';
import { CDN_SOREGIES_URL } from '@app/shared/utils/urls';
import jwt_decode from 'jwt-decode';
import { HomePageService } from '../home-page.service';

@Component({
  selector: 'app-survey-toast',
  templateUrl: './survey-toast.component.html',
  styleUrls: ['./survey-toast.component.scss'],
})
export class SurveyToastComponent implements OnInit {
  CDN_SOREGIES_URL: string = CDN_SOREGIES_URL;

  surveyUrl: string | undefined = undefined;

  constructor(
    private homePageService: HomePageService,
  ) {}

  ngOnInit(): void {
    const idToken: string | null = localStorage.getItem('idToken');
    if (idToken) {
      const decodedToken: any = jwt_decode(idToken);
      this.surveyUrl = decodedToken['custom:pending_survey'];
    }
  }

  goToSurvey(): void {
    window.open(this.surveyUrl);
    this.neverShowAgain();
  }

  neverShowAgain(): void {
    if (this.surveyUrl) {
      this.homePageService.postDismissSurvey().subscribe({
        next: () => {
          this.surveyUrl = undefined;
        },
      });
    }
  }
}
