<app-expansion-panel [expansionPanelId]="selectId"
                     [coverText]="coverText"
                     [coverSpacing]="coverSpacing"
                     [state]="disabled ? 'disabled' : 'default'"
>
  <ng-container ngProjectAs="[dropdown-content]">
    <div class="select-dropdown-content" [id]="selectId+'-select-dropdown'">
      <div
        *ngFor="let param of params"
        class="select-option"
        [class.selected]="value === param.value || value === param.title"
        [class.disabled]="param.disabled || false"
        (click)="handleChange(param)"
        [id]="'select-option-'+params.indexOf(param)"
      >
        <span>{{param.title}}</span>
        <app-icon
          *ngIf="value === param.value || value === param.title"
          phosphorClass="ph-check"
          [iconStyle]="Style.bold"
          [color]="Color.primary300"
          [size]="Size.small"
        ></app-icon>
      </div>
    </div>
  </ng-container>
</app-expansion-panel>

