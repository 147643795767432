import {
  Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, Output, ViewChild,
} from '@angular/core';
import {
  Overlay, OverlayConfig, OverlayModule, OverlayRef,
} from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import {
  auditTime, BehaviorSubject, Subject, takeUntil,
} from 'rxjs';
import { IconComponent, Size, Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';
import { NgIf, NgStyle } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';

export enum CoverSpacing {
  center = 'center',
  spaceBetween = 'space-between',
  spaceEvenly = 'space-evenly',
}

export enum ExpansionPanelState {
  default = 'default',
  readOnly = 'readOnly',
  disabled = 'disabled',
}

@Component({
  standalone: true,
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  imports: [
    IconComponent,
    OverlayModule,
    NgStyle,
    NgIf,
    DialogModule,
  ],
})
export class ExpansionPanelComponent implements OnDestroy {
  @Input() expansionPanelId?: string;

  @Input() state: keyof typeof ExpansionPanelState = 'default';

  @Input() iconClassName?: string;

  @Input() iconStyle?: Style | undefined;

  @Input() iconSize?: Size | undefined;

  @Input() coverText: string = '';

  @Input() coverSpacing: keyof typeof CoverSpacing = 'spaceBetween';

  @Input() hoverText: string = '';

  @Input() withoutCarretIcon: boolean = false;

  @Input() hasCloseOnCLick: boolean = true;

  @Output() panelChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected toOpen: boolean = false;

  @ViewChild('triggerContainerEl') protected triggerContainerEl!: ElementRef;

  @ViewChild('dropdownContainer') protected dropdownContainerEl!: ElementRef;

  private overlayRef!: OverlayRef;

  @ViewChild(CdkPortal) protected contentTemplate!: CdkPortal;

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  private change$ = new BehaviorSubject(({ triggerButtonWidth: 0, triggerButtonTop: 0 }));

  private animationFrameId: number = 0;

  protected COVERSPACINGTYPE = CoverSpacing;

  constructor(private overlay: Overlay, private zone: NgZone) {
  }

  showDropdown(): void {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.contentTemplate);
    this.overlayRef.backdropClick().subscribe(() => this.hide());
    this.syncWidth();
    this.toOpen = true;
    this.panelChanges.emit(true);

    let width = 0;
    let top = 0;

    const watchOnFrame = () => {
      const {
        width: triggerButtonWidth,
        top: triggerButtonTop,
      } = this.triggerContainerEl.nativeElement.getBoundingClientRect();

      if (triggerButtonTop !== top || triggerButtonWidth !== width) {
        width = triggerButtonWidth;
        top = triggerButtonTop;

        this.change$.next({ triggerButtonWidth, triggerButtonTop });
      }

      this.animationFrameId = requestAnimationFrame(watchOnFrame);
    };

    this.zone.runOutsideAngular(watchOnFrame);

    const obs = this.change$.asObservable().pipe(takeUntil(this.unsubscribe$));
    const change = obs.pipe(
      takeUntil(this.unsubscribe$),
      auditTime(0),
    );

    change.subscribe(({ triggerButtonWidth, triggerButtonTop }) => {
      const {
        width: dropdownWidth,
        top: dropdownTop,
      } = this.overlayRef.overlayElement.getBoundingClientRect();
      if (dropdownWidth > triggerButtonWidth) {
        this.triggerContainerEl.nativeElement.classList.add('floated');
        this.dropdownContainerEl.nativeElement.classList.add('floated');
      }
      if (dropdownTop < triggerButtonTop) {
        this.triggerContainerEl.nativeElement.classList.add('top-dir');
        this.dropdownContainerEl.nativeElement.classList.add('top-dir');
      } else {
        this.triggerContainerEl.nativeElement.classList.add('bottom-dir');
        this.dropdownContainerEl.nativeElement.classList.add('bottom-dir');
      }
    });
  }

  hide(): void {
    this.panelChanges.emit(false);
    cancelAnimationFrame(this.animationFrameId);
    this.overlayRef.detach();
    this.toOpen = false;
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.triggerContainerEl.nativeElement)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ]);

    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    return new OverlayConfig({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }

  private syncWidth(): void {
    if (!this.overlayRef) {
      return;
    }
    const refRectWidth = this.triggerContainerEl.nativeElement.getBoundingClientRect().width;
    this.overlayRef.updateSize({ minWidth: refRectWidth });
  }

  @HostListener('window:resize', ['$event'])
  private onWindowResize() {
    this.syncWidth();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.change$.complete();
  }

  protected readonly Style = Style;

  protected readonly Size = Size;

  protected readonly Color = Color;
}
