import { DatePipe, TitleCasePipe } from '@angular/common';
import { DateInterval, DateIntervalResponse } from '@app/shared/interfaces/date.interface';
import { DateTime } from 'luxon';

export interface PPDays {
  PP1: DateInterval[];
  PP2: DateInterval[];
}

export interface PPDaysResponse {
  PP1: DateIntervalResponse[];
  PP2: DateIntervalResponse[];
}

export interface Signal {
  count: number,
  today: PP1,
  tomorrow: PP1,
}

export interface SignalResponse {
  data: PP1[],
  count: number
}

export interface PP1 {
  pp1?: boolean,
  type: string,
  date: string;
}

function transformDate(date: string | Date, format?: string) {
  const datePipe = new DatePipe('fr-FR');
  const titleCasePipe = new TitleCasePipe();
  return titleCasePipe.transform(datePipe.transform(date, format || 'dd/MM/yyyy'))!;
}

export function getSignalFromJson(json: SignalResponse): Signal {
  const currentDate = new Date();
  const dateToday = transformDate(currentDate);
  const dateTodayShort = transformDate(currentDate, 'd MMM');
  const dateTomorrow = transformDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
  const dateTomorrowShort = transformDate(currentDate, 'd MMM');

  let type = 'En attente de publication';
  let today: PP1 = { date: dateTodayShort, type };
  let tomorrow: PP1 = { date: dateTomorrowShort, type };

  json.data.forEach((element: PP1) => {
    const date = transformDate(element.date);
    if (date === dateToday) {
      type = element.pp1 ? 'PP1' : 'Hors Période';
      today = { ...today, pp1: element.pp1, type };
    } else if (date === dateTomorrow) {
      type = element.pp1 ? 'PP1' : 'Hors Période';
      tomorrow = { ...tomorrow, pp1: element.pp1, type };
    }
  });

  return {
    count: json.count, today, tomorrow,
  };
}

const jsDateToDateTime = ({ startDate, endDate }: DateIntervalResponse) => ({
  startDate: DateTime.fromJSDate(new Date(startDate!)),
  endDate: DateTime.fromJSDate(new Date(endDate!)),
});

export const mapPPDays = ({ PP1, PP2 }: PPDaysResponse): PPDays => {
  const PP1DateTime = (PP1 ?? []).map((value) => jsDateToDateTime(value));
  const PP2DateTime = (PP2 ?? []).map((value) => jsDateToDateTime(value));
  return { PP1: PP1DateTime, PP2: PP2DateTime };
};
