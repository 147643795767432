import { Component, Input } from '@angular/core';
import { IconComponent, Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';

@Component({
  standalone: true,
  selector: 'app-warning-message-block',
  templateUrl: './warning-message-block.component.html',
  styleUrls: ['./warning-message-block.component.scss'],
  imports: [
    IconComponent,
  ],
})
export class WarningMessageBlockComponent {
  @Input() message: string = '';

  protected readonly Style = Style;

  protected readonly Color = Color;
}
