import {
  Directive, TemplateRef,
} from '@angular/core';

interface Context<T> {
  $implicit?: T; // current item exposed as implicit value
  index?: number;
}

@Directive({
  selector: '[appRowHeaders]',
})
export class RowHeadersDirective {
  constructor(
    public templateRef: TemplateRef<any>,
  ) {
  }

  static ngTemplateContextGuard<T>(
    dir: RowHeadersDirective,
    ctx: any,
  ): ctx is Context<T> {
    return true;
  }
}
