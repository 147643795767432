import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CurveResponse } from '@app/consumptions/charts.types';
import { SiteOffer } from '@app/shared/interfaces/site.interface';
import { Pageable } from '@app/shared/interfaces/pagination.interface';

@Injectable({
  providedIn: 'root',
})
export class S3Service {
  private httpClients3?: HttpClient;

  constructor(
    public httpBackend: HttpBackend,
  ) {
    this.httpClients3 = new HttpClient(httpBackend);
  }

  getChartsData(s3Url: string): Observable<CurveResponse> {
    return this.httpClients3!
      .get<CurveResponse>(s3Url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  getSiteOffers(s3Url: string) {
    return this.httpClients3!.get<Pageable<SiteOffer>>(s3Url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
