import {
  AfterViewInit, Component, ElementRef, Input, ViewChild,
} from '@angular/core';
import { Color } from '@app/shared/models/color';

@Component({
  selector: 'app-badge-notification',
  templateUrl: './badge-notification.component.html',
  styleUrls: ['./badge-notification.component.scss'],
  standalone: true,
})
export class BadgeNotificationComponent implements AfterViewInit {
  @Input() value?: string | number;

  @Input() color: Color = Color.secondary700;

  @Input() size: string = '12px';

  @ViewChild('badge') badgeElRef!: ElementRef;

  ngAfterViewInit() {
    this.badgeElRef.nativeElement.style.backgroundColor = this.color;
    this.badgeElRef.nativeElement.style.height = this.size;
    this.badgeElRef.nativeElement.style.minWidth = this.size;
  }
}
