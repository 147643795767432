<div class="pie-container">
  <div id="pie-chart" [chart]="chart"></div>
  <div class="btob-column pie-legend">
    <div *ngIf="legendTitle" class="pie-legend-title">
      <span>{{ legendTitle }}</span>
    </div>
    <div *ngIf="hasData; then thenShowLegendBlock else elseBudgetEstimationBlock"></div>
    <ng-template #thenShowLegendBlock>
        <div class="pie-legend-container" [ngStyle]="getLegendContainerStyles()">
          <div *ngFor="let item of chartItems; let index = index"
               id="pie-legend-item-{{index}}"
               class="pie-legend-item"
               [class.disabled]="!item.enabled"
               [class.large]="!legendTitle"
               [class.underline]="withUnderline"
               (mouseenter)="item.enabled && onMouseEnter(item.name)"
               (mouseleave)="item.enabled && onMouseOut()"
               (click)="handleClick(item.name)">
            <div class="pie-legend-name">
              <em class="ph ph-circle ph-fill"  [style.color]="item.enabled ? item.color : 'grey'"></em>
              {{ item.name }}
              <app-icon *ngIf="item.tooltip"
                  phosphorClass="ph-info"
                  [iconStyle]="Style.fill"
                  id="item-info-{{index}}"
                  [appTooltip]="item.tooltip"></app-icon>
            </div>
            <div *ngIf="valueUnit === BillinUnitType.PERCENT; then percentBlock else baseBlock"></div>
            <ng-template #baseBlock><span class="pie-legend-value">{{ convertUnit(item.value) | number:'1.2-2'}} {{this.valueUnit}}</span></ng-template>
            <ng-template #percentBlock><span class="pie-legend-value">{{ convertUnit(item.value) | percent : '1.2-2' : 'fr-FR' }}</span></ng-template>
          </div>
        </div>
    </ng-template>
    <ng-template #elseBudgetEstimationBlock>
      <div class="budget-estimation">
        <app-warning-message-block [message]="MESSAGES.TXT89" id="noData"></app-warning-message-block>
      </div>
    </ng-template>
  </div>
</div>
