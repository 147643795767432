import { Fluid } from '@app/shared/models/fluid.model';
import { GieFluid } from '@app/shared/interfaces/fluid.interface';
import { DateTime } from 'luxon';

export interface PayerPartner {
  id: number,
  name: string,
}

export interface InvoiceDataResume {
  id: number,
  invoiceId: string,
  type: string,
  status: InvoiceStatus,
  contractLineId: string,
  exchangeRef: string,
  siteNameOrInvoicesBillName: string,
  inTaxTotal: number,
  inTaxTotalRemaining: number,
  exTaxTotal: number,
  dueDate: string,
  invoiceDate: string,
  payerPartner: PayerPartner,
  mainClientPartner: string,
  fluid?: string
}

export interface InvoicesBills {
  date: string,
  exchangeRef: string,
  invoicesBill: {
    number: string,
    id: number
  },
  siteName: string,
  draftNumber: string,
  exTaxTotal: string,
  id: number,
  contractLineId: string
}

export interface InvoiceResume {
  data: InvoiceDataResume[],
  total: number
}

export interface InvoiceDataResponse {
  id: number,
  invoiceId: string,
  invoiceIdDraft: string,
  type: number,
  subType: number,
  contractLineId: string,
  exchangeRef: string,
  siteName: string,
  inTaxTotal: number,
  inTaxTotalRemaining: number,
  exTaxTotal: number,
  dueDate: string,
  invoiceDate: string,
  payerPartner: PayerPartner,
  mainClientPartner: string,
  invoicesBillName: string,
}

export type InvoiceBillingAmount = {
  name: string,
  value: number,
};

export enum InvoiceType {
  Manuelle = 0,
  Cyclique = 1,
  Virtuelle = 2,
  Echeance = 3,
  Resiliation = 4,
  Prestation = 5,
  MemoireBordereauDeRegroupement = 6,
  PieceComptable = 7,
  Ieg = 8,
  Agent = 9,
  Unitaire = 10,
  Specifique = 11,
  MiseEnService = 12,
}

export enum InvoiceSubType {
  FinCycleMasse = 1,
  FinCycleGrandCompte = 2,
  PeriodiqueMensuMasse = 3,
  ReleveDinformationMensualisé = 4,
  ReleveDInformation = 5,
  Devis = 6,
}

export enum InvoiceFrontType {
  Facture = 'Facture',
  Memoire = 'Mémoire',
  BordereauDeFacture = 'Bordereau de facture',
  ReleveDinformation = 'Relevé d\'information',
}

export enum InvoiceStatus {
  ToPay = 'A payer',
  Late = 'En retard',
  Paid_F = 'Payée',
  Paid_M = 'Payé',
  Credit = 'Avoir',
  CreditPaid = 'Avoir soldé',
}

export interface GetInvoicesParams {
  contractLineIds: string[],
  startDate?: string,
  endDate?: string,
  exchangeRef?: string,
  fluid?: Fluid,
}

export interface GetInvoicesBillsParams {
  contractLineIds: string[],
  startDate?: string,
  endDate?: string,
  search?: string,
  fluid?: Fluid,
}

export interface GetBillingAmountsParams {
  contractLineIds: string[],
  startDate?: string,
  endDate?: string,
  fluid?: Fluid,
}

export interface GetBillingTotalsAmountsParams {
  contractLineIds: string[]
}

export interface InvoiceTotalAmount {
  totalAmount: string
}

export interface GetDownloadParams {
  invoicesIds: string[],
  contractLineIds: string[],
  fluid?: Fluid,
  invoiceBillNumber?: string
  invoiceBillId?: string
  type: InvoiceExportType,
}

export type InvoiceAmount = InvoiceBillingAmount & { id?: number; tooltip?: string };

interface InvoiceLine {
  invoiceLineType: { name: string, code: string }
  exTaxTotal: string,
  id: number,
  version: number
}

export interface InvoiceDetailsResponse {
  id: number,
  number: string,
  type: InvoiceType,
  date: string,
  fluid: GieFluid,
  contractLineId: string,
  dueDate: string,
  inTaxTotal: string,
  exTaxTotal: string,
  taxTotal: string,
  vatTotal: string,
  inTaxTotalRemaining: string,
  invoiceBillGroupingFolders: InvoiceBillGroupingFolder[],
  informationSummaries: InvoiceInformationSummary[],
  paymentMode: PaymentMode,
  payerName: string,
  exchangeRef: number,
  invoiceLines: InvoiceLine[],
  iban: string,
  billingAmounts: InvoiceBillingAmount[]
}

export interface InvoiceDetails {
  id: string,
  number: string,
  type: string,
  date: string,
  fluid: string,
  dueDate: string,
  inTaxTotal: number,
  exTaxTotal: number,
  vatTotal: string,
  exchangeRef: string,
  inTaxTotalRemaining: number
  invoiceBillGroupingFolders: InvoiceBillGroupingFolder[],
  informationSummaries: InvoiceInformationSummary[],
  paymentMode: PaymentMode,
  iban: string,
  bic: string,
  bankAddress: string,
  ownerName: string
  billingAmounts: InvoiceBillingAmount[],
  payerName: string,
}

export interface PaymentMode {
  code: string,
  name: string,
}

export interface InvoiceBillGroupingFolder {
  id: string,
  draftNumber: string,
  date: string,
  exTaxTotal: number,
  invoiceBillName: string,
}

export interface InvoiceInformationSummary {
  id: string,
  draftNumber: string,
  date: DateTime,
  exTaxTotal: number,
  exchangeRef: string,
  siteName: string,
  contractLineId: string,
}

export enum BillinUnitType {
  EUR = '€',
  PERCENT = '%',
}

export enum TotalUnitType {
  HTVA = 'HTVA',
  TTC = 'TTC',
}

export enum InvoiceExportType {
  INVOICE = 'INVOICE',
  INFORMATION_SUMMARY_BY_BILL = 'INFORMATION_SUMMARY_BY_BILL',
  INFORMATION_SUMMARY = 'INFORMATION_SUMMARY',
}
