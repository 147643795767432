<dialog appBaseDialog #dialog id="change-password-dialog" [size]="DialogSize.MEDIUM" (cancelTrigger)="cancel()">
  <ng-template appDialogHeader>
    <span>Modifier mon mot de passe</span>
  </ng-template>

  <ng-template appDialogContent>
    <form [formGroup]="changePasswordform" class="change-password-form">
      <div class="form-sections">
        <div class="text-input-container oldPassword-container">
          <app-form-input formControlName="oldPassword"
                          id="old-password"
                          title="Ancien mot de passe"
                          placeholder="Ancien mot de passe"
                          type="password"
                          [errorMessage]="oldPasswordError">
          </app-form-input>
        </div>

        <div class="text-input-container password-container">
          <app-form-input formControlName="password"
                          id="password"
                          title="Nouveau mot de passe"
                          placeholder="Nouveau mot de passe"
                          type="password"
                          [errorMessage]="passwordError">
          </app-form-input>
        </div>
        <app-password-checker
          *ngIf="this.changePasswordform.controls.password.value"
          [text]="this.changePasswordform.controls.password.value"
        >
        </app-password-checker>

        <div class="text-input-container password-confirm-container" appBlockCopyPaste>
          <app-form-input formControlName="passwordConfirm"
                          id="password-confirm"
                          title="Confirmation nouveau mot de passe"
                          placeholder="Confirmation nouveau mot de passe"
                          type="password"
                          [errorMessage]="confirmPasswordError">
          </app-form-input>
        </div>

        <div class="submit-form">
          <div *ngIf="controls.password.errors?.incorrectPassword"
               class="submit-error no-data"
               id="submittedError"
               [innerHTML]="MESSAGES.TXT28">
          </div>

          <div *ngIf="controls.password.errors?.limitExceeded"
               class="submit-error no-data"
               id="submittedErrorExceededLimit"
               [innerHTML]="MESSAGES.TXT127">
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template appDialogFooter>
    <app-button
      buttonId="cancel-btn"
      [style]="ButtonStyle.Outlined"
      (onclick)="cancel()"
    >Annuler
    </app-button>

    <app-button
      buttonId="save-btn"
      [isDisabled]="(controls.password.invalid || controls.passwordConfirm.invalid) && !controls.password.hasError('incorrectPassword')"
      [isLoading]="isLoadingSubmit"
      (onclick)="submitForm()"
    >Confirmer
    </app-button>
  </ng-template>
</dialog>
