import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/organisms/alert/alert.service';
import { MESSAGES } from '@app/shared/utils/messages';

@Injectable({
  providedIn: 'root',
})
export class HomePageGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
  ) {
  }

  /**
   * check if the user has the right to access the page
   * @param route
   */
  hasTheRight(route: ActivatedRouteSnapshot) {
    if (route.data.right) {
      // if we don't have the right we are redirected to home
      if (!this.authService.hasAnyAuthority([route.data.right])) {
        this.router.navigate(['/'], { queryParamsHandling: 'merge' });
      }
    }
    return true;
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean {
    if (this.authService.isLoggedIn()) {
      if (this.authService.isSessionExpired()) {
        this.authService.signOut();
        this.alertService.info(MESSAGES.TXT62, { icon: 'sign-out', duration: 30000 });
        return false;
      }
      if (!this.authService.getProfileID()) {
        this.router.navigate(['/login/profiles']);
        return false;
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }

    return this.hasTheRight(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean {
    return this.canActivate(route);
  }
}
