<div class="app-autocomplete-input">
  <app-textfield  [value]="value"
              (valueChange)="onChange($event)"
              type="text"
              [height]="'3rem'"
              [placeholder]="placeholder"
              [prefixIconOption]="prefixIcon"
              [styleIcon]="styleIcon"
              [clearSuffixButton]="true"
              [errorMessage]="errorMessage"
              autocomplete="new-password"
              (inputFocus)="onFocus()"
              (inputFocusOut)="onFocusOut()">
  </app-textfield>
  <div *ngIf="isFocused && ((suggestions && suggestions.length > 0) || hint)" class="suggestions">
    <div *ngIf="hint" class="hint">
      <div class="hint-header">
        <app-icon phosphorClass="ph-lightbulb" [iconStyle]="Style.bold" [size]="Size.small" [color]="Color.grey500"></app-icon>
        ASTUCE
      </div>
      {{hint}}
    </div>
    <div *ngIf="hint && suggestions && suggestions.length > 0" class="separator"></div>
    <div  *ngFor="let suggestion of suggestions"
          class="suggestion"
          (mousedown)="onChange(suggestion)"
          [innerHtml]="suggestion | highlight:value:false">
    </div>
  </div>
</div>
