<app-expansion-panel [expansionPanelId]="'change-perimeter-expansion-panel'"
                     [coverText]="companyName"
                     iconClassName="ph-buildings"
                     [state]="monoPerimeter ? 'readOnly' : 'default'"
                     [withoutCarretIcon]="monoPerimeter"
                     hoverText="Changer de périmètre"
                     [hasCloseOnCLick]="false"
>
  <ng-container ngProjectAs="[dropdown-content]">
    <div class="content">
      <div id="select-all-perimeter" class="all-perimeter" (click)="selectPerimeter()">
        <span>Tous mes périmètres</span>
        <span>{{ profiles.length }}</span>
      </div>

      <app-divider [text]="{content: 'Ou'}"></app-divider>

      <app-textfield class="filter"
                     placeholder="Rechercher..."
                     [prefixIconOption]="{phosphorClass: 'ph-magnifying-glass'}"
                     [(value)]="filter"
                     [clearSuffixButton]="true"></app-textfield>

      <div class="profile-list">
        <ng-container *ngFor="let profile of filteredProfiles">
          <div class="profile"
               [class.selected]="companyName === profile.company.companyName"
               (click)="selectPerimeter(profile)">
            <div class="profile-icon">
              <app-icon phosphorClass="ph-buildings" [size]="Size['medium-small']"></app-icon>
            </div>
            <div class="profile-block">
              <p>{{ profile?.company?.companyName }}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</app-expansion-panel>

