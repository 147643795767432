import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  catchError, filter,
  map, mergeMap, Observable, of, ReplaySubject, Subject, takeUntil,
} from 'rxjs';
import { Offer, OfferDetails, OfferParams } from '@app/shared/interfaces/offer.interface';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import { ComponentStatusChangeService } from '@app/shared/services/component-status-change.service';
import { FluidService } from '@app/shared/services/fluid.service';
import { Fluid } from '@app/shared/models/fluid.model';
import { AuthService } from '@app/shared/auth/auth.service';
import { User } from '@app/shared/models/user-info';
import { Document } from '@app/shared/interfaces/document.interface';
import { BASE_API_URL } from '@app/shared/utils/api';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { downloadFromUrl } from '@app/shared/utils/download';
import { getOffersNature, mapCrmOffer } from './mapper';

export type DataStore = {
  offers?: Offer[];
};

@Injectable({
  providedIn: 'root',
})
export class OffersService implements OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  currentOffers$: ReplaySubject<DataStore> = new ReplaySubject<DataStore>(1);

  userConnected?: User;

  url = `${BASE_API_URL.CA_API_BASE_URL_CRM}${API_ROUTES.OFFERS}`;

  isNatureOfferLoading: boolean = false;

  routeFluid?: Fluid;

  constructor(
    private httpClient: HttpClient,
    private cscs: ComponentStatusChangeService,
    private fluidService: FluidService,
    private authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(({ fluid }: Params) => {
      if (fluid) this.routeFluid = Fluid.getFluidFromValue(fluid);
      else this.routeFluid = undefined;
    });
  }

  public getOffers(offerParams?: OfferParams): Observable<Offer[]> {
    const { exchangeRef, nature, profile } = offerParams || {};
    let params = new HttpParams();
    const { email } = this.userConnected || {};

    if (email) params = params.append('email', email);
    if (profile) params = params.append('profile', profile || '');
    if (nature) params = params.append('nature', nature || '');
    if (exchangeRef) params = params.append('exchangeRef', exchangeRef || '');

    return this.httpClient.get<Offer[]>(
      this.url,
      {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).pipe(
      map((res) => mapCrmOffer(res)),
    );
  }

  public currentOffers(fluid?: Fluid): Observable<DataStore> {
    const currentNature = fluid?.key;
    return this.currentOffers$.pipe(
      filter(({ offers }) => offers !== undefined),
      map(({ offers: allOffers }) => {
        if (!currentNature) return { offers: allOffers };
        const offers = allOffers!.filter(({ nature }) => nature === currentNature);
        return { offers };
      }),
    );
  }

  public getNatureOfOffers() {
    this.isNatureOfferLoading = true;
    let params = new HttpParams();
    if (this.userConnected?.email) {
      params = params.append('email', this.userConnected?.email);
    }
    if (this.userConnected?.selectedProfile) {
      params = params.append('profile', this.userConnected?.selectedProfile.id);
    }

    this.httpClient
      .get<Offer[]>(
      this.url,
      {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).pipe(
      map((res) => {
        const offers = mapCrmOffer(res);
        this.currentOffers$.next({ offers });
        return getOffersNature(res);
      }),
    ).pipe(
      map((calculatedFluid) => {
        const { routeFluid } = this;
        if (routeFluid) return routeFluid;
        return calculatedFluid;
      }),
      catchError(() => of(Fluid.ELEC)),
    ).subscribe({
      next: (fluid) => {
        this.fluidService.setFluid(fluid);
        this.cscs.showLoader(false);
        this.isNatureOfferLoading = false;
        this.addQueryParams({ fluid });
      },
      error: () => {
        this.isNatureOfferLoading = false;
      },
    });
  }

  private addQueryParams(queryParams?: Params) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      },
    ).then();
  }

  public getDocuments(offerId: string): Observable<Document[]> {
    let params = new HttpParams()
      .append('offerId', offerId)
      .append('email', this.userConnected?.email!);

    if (this.userConnected?.selectedProfile) {
      params = params.append('profile', this.userConnected.selectedProfile.id);
    }

    return this.httpClient
      .get<Document[]>(
      `${this.url}${API_ROUTES.DOCUMENTS}`,
      {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public getDocument(id: string, objectId: string, name:string): Observable<void> {
    let params = new HttpParams()
      .append('objectType', 'offers')
      .append('objectId', objectId)
      .append('filename', name)
      .append('email', this.userConnected?.email!);

    if (this.userConnected?.selectedProfile) {
      params = params.append('profile', this.userConnected.selectedProfile.id);
    }

    return this.httpClient
      .get<{ url: string }>(`${BASE_API_URL.CA_API_BASE_URL_CRM}${API_ROUTES.DOCUMENTS}/${id}`, {
      params,
    }).pipe(mergeMap(async ({ url }) => {
      await downloadFromUrl(url, name);
    }));
  }

  public getOfferDetails(id: string): Observable<OfferDetails> {
    let params = new HttpParams();
    const { selectedProfile, email } = this.userConnected || {};

    if (email) params = params.append('email', email);
    if (selectedProfile) params = params.append('profile', selectedProfile.id);

    return this.httpClient
      .get<any>(`${this.url}/${id}`, {
      headers: { 'Content-Type': 'application/json' },
      params,
    });
  }

  clear() {
    this.routeFluid = undefined;
    this.currentOffers$.next({});
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.currentOffers$.complete();
  }
}
