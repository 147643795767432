import { Injectable, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import {
  Observable, ReplaySubject, Subject, takeUntil,
} from 'rxjs';
import { BASE_API_URL } from '@app/shared/utils/api';
import { Profile } from '@app/shared/interfaces/user-response.interface';
import { API_ROUTES } from '../utils/api-routes';
import { AuthService } from '../auth/auth.service';
import { SessionStorageService } from '../auth/session-storage.service';
import { User } from '../models/user-info';
import { Collaborator } from '../interfaces/profiles.interface';

export type DataStore = {
  profile?: Profile;
};

@Injectable({
  providedIn: 'root',
})
export class ProfileService implements OnDestroy {
  private selectedProfile$: ReplaySubject<DataStore> = new ReplaySubject<DataStore>(1);

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  public currentUser?: User;

  constructor(
    private http: HttpClient,
    private sessionStorage: SessionStorageService,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  setProfile(profile?: Profile) {
    if (profile) this.selectedProfile$.next({ profile });
    else this.setAllProfiles();
  }

  setAllProfiles() {
    this.selectedProfile$.next({});
  }

  currentProfile$(): Observable<DataStore> {
    return this.selectedProfile$.pipe(takeUntil(this.unsubscribe$));
  }

  getCollaborators(profileId: string, companyId: string | undefined) {
    const url = `${BASE_API_URL.CA_API_BASE_URL_CRM}${API_ROUTES.COLLABORATORS}`
      .replace('{profileId}', profileId ?? '')
      .replace('{companyId}', companyId ?? '');

    return this.http
      .get<Collaborator[]>(url, {
      // params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.sessionStorage.get(AuthService.ID_TOKEN) ?? [],
      },
    })
      .pipe(
        map((response: Collaborator[]) => response),
      );
  }
}
