import { Fluid } from '@app/shared/models/fluid.model';
import { Holder, HolderResponse } from './holder.interface';
import { ManagerTeam, ManagerTeamResponse } from './manager-team.interface';

export interface SitesOffersResponse {
  sitesOffers: SiteOffer[],
  total: number
}

export interface SiteOffer {
  id: string,
  contractId: string,
  hoder?: Holder,
  terminationDate: string,
  supplyStartDate: string,
  supplyEndDate: string,
  status: string,
  nature: string,
  offerSummary: OfferSummary,
  site: Site,
}

export type SiteOffers = SiteOffer[];

export interface SiteOfferResponse {
  id: string,
  contractId: string,
  terminationDate: string,
  supplyStartDate: string,
  supplyEndDate: string,
  status: string,
  nature: string,
  offerSummary: OfferSummaryResponse,
  site: SiteResponse,
  typeOfProposal: { code: string, label: string },
}

export interface SiteOfferDetails {
  siteOffer: SiteOffer,
  holder: Holder,
  commercial: ManagerTeam
  siteDetails: SiteDetails
  siteOfferContractFeatures: SiteDetailContractFeatures
}

export interface SiteOfferDetailsResponse {
  commercial: ManagerTeamResponse
  holder: HolderResponse,
  siteDetails: SiteDetailsResponse
  siteOffer: SiteOfferResponse,
}

export interface InternalReference {
  id: string,
  name: string,
  value: string,
}

export interface SiteOfferSummary extends SiteOffer {
}

export interface SiteOfferSummaryResponse {
  id: string,
  contractId: string,
  hoder: HolderResponse,
  nature: string,
  terminationDate: string,
  supplyStartDate: string,
  supplyEndDate: string,
  status: string,
  site: SiteResponse,
  name: string,
  number: string,
  typeOfProposal: { code: string, label: string },
  offerSummary: OfferSummaryResponse
}

export interface Address {
  line1: string,
  line2: string,
  line3: string,
  zipCode: number,
  city: string,
  country: string,
  fullName: string,
}

export interface Site {
  locationID: string,
  exchangeRef: string,
  name: string,
  segment: string,
  address: Address,
}

export interface SiteResponse {
  locationID: string,
  exchangeRef: string,
  name: string,
  segment: string,
  address: Address,
}

interface SiteDetails {
  fta: string,
  technicalInterlocutor?: Interlocutor,
}

interface SiteDetailsResponse {
  dso: { code: string, codeName: string, labelName: string },
  fta: string,
  technicalInterlocutor?: Interlocutor,
}

interface Dso {
  num: string,
  name: string,
  code: string,
}

export interface OfferSummary {
  id: string
  name: string,
  offerName: string,
  number: string,
}

export interface OfferSummaryResponse {
  id: string
  name: string,
  offerName: string,
  number: string,
  sitesOfferNumber: number,
  supplyEndDate: string,
  supplyStartDate: string,
}

export interface Interlocutor {
  id: string,
  name: string,
  firstName: string,
  email?: string,
  civility: string,
  mobilePhone?: string,
}
export interface SubscribedPower {
  name: string,
  code: string,
  subscribedPower: number,
}

export interface SubscribedPowerResponse {
  fullName: string,
  name: string,
  code: string,
  subscribedPower: number,
}

export interface RoutingPricing {
  code: string,
  name: string,
}

interface Profile {
  code: string,
  name: string,
}

export enum PowerCode {
  /**
   * Ordre d'affichage des colonnes
   * de la page "mes données de consommation"
   */
  P = 'Pointe',
  HPH = 'Heures Pleines Hiver',
  HCH = 'Heures Creuses Hiver',
  HPE = 'Heures Pleines Été',
  HCE = 'Heures Creuses Été',
  HP = 'Heures Pleines',
  'HEURES PLEINES' = 'Heures Pleines',
  'HEURES CREUSES' = 'Heures Creuses',
  HC = 'Heures Creuses',
  BASE = 'Base',
  HPTE = P,
  HPSH = HPH,
  HCSH = HCH,
  HPSB = HPE,
  HCSB = HCE,
}

export interface SiteDetailContractFeatures {
  routingSubscribedPowerList: SubscribedPower[],
  routingPricing: RoutingPricing;
  segment: string;
  annualReferenceConsumption: number;
  profile?: Profile;
  commercialDescription: string;
  mptLevel: number;
  internalReferenceContractLineList: InternalReference[];
  dso: Dso;
}

export interface SiteDetailContractFeaturesResponse {
  routingSubscribedPowerList: SubscribedPowerResponse[],
  routingPricing: RoutingPricing;
  segment: string;
  annualReferenceConsumption: number;
  profile?: Profile;
  commercialDescription: string;
  mptLevel: number;
  internalReferenceContractLineList: InternalReference[];
  dso: Dso;
}

export interface GetSitesOffersParams {
  offers?: string,
  exchangeRef?: string,
  fluid?: Fluid,
  filter?: string,
  profile?: string,
}

export interface GetSitesParams {
  exchangeRef?: string,
  fluid?: Fluid
}
