import {
  Component, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { User } from '@app/shared/models/user-info';
import { combineLatestAll, Subject, takeUntil } from 'rxjs';
import { AuthService } from '@app/shared/auth/auth.service';
import { Profile } from '@app/shared/interfaces/user-response.interface';
import { Router } from '@angular/router';
import { PerimeterNameConcatPipe } from '@app/shared/pipes/perimeter-name-concat.pipe';
import { Color } from '@app/shared/models/color';
import { ExpansionPanelComponent } from '@app/shared/components/molecules/expansion-panel/expansion-panel.component';
import { Size, Style } from '@app/shared/components/atoms/icon/icon.component';

const perimeterNameConcatPipe: PerimeterNameConcatPipe = new PerimeterNameConcatPipe();

@Component({
  selector: 'app-perimeter-block',
  templateUrl: './perimeter-block.component.html',
  styleUrls: ['./perimeter-block.component.scss'],
})
export class PerimeterBlockComponent implements OnInit, OnDestroy {
  protected readonly combineLatestAll = combineLatestAll;

  protected readonly Color = Color;

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  userConnected?: User;

  companyName: string = '';

  companyNameTitle: string = '';

  monoPerimeter: boolean = false;

  filter: string = '';

  profiles: Profile[] = [];

  @ViewChild(ExpansionPanelComponent) protected expansionPanelRef!: ExpansionPanelComponent;

  constructor(
    private authentication: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.authentication.getProfiles().subscribe({
      next: () => {
        this.monoPerimeter = this.authentication.profiles.length === 1;
        this.profiles = this.authentication.profiles.sort(
          (a, b) => a.company.companyName.localeCompare(b.company.companyName),
        );
      },
    });
    this.authentication.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) {
        this.userConnected = user;
        const company = user.selectedProfile?.company;
        this.companyName = company ? (company.companyName || 'Entreprise') : 'Tous mes périmètres';
        this.companyNameTitle = this.companyName;
        this.companyName = perimeterNameConcatPipe.transform(this.companyName);
      }
    });
  }

  selectPerimeter(profile?: Profile) {
    this.filter = '';
    this.expansionPanelRef.hide();
    if (profile) {
      this.authentication.setProfile(profile);
    } else {
      this.authentication.setAllPerimeter(this.profiles);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get filteredProfiles(): Profile[] {
    return this.filter
      ? this.profiles.filter((profile) => profile.company.companyName.toLowerCase().includes(this.filter.toLowerCase()))
      : this.profiles;
  }

  protected readonly Style = Style;

  protected readonly Size = Size;
}
