import {
  Component, HostListener, OnDestroy, OnInit,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@app/shared/auth/auth.service';
import { ComponentStatusChangeService } from '@app/shared/services/component-status-change.service';
import { User } from '@app/shared/models/user-info';
import { FluidService } from '@app/shared/services/fluid.service';
import { Fluid } from '@app/shared/models/fluid.model';
import { OffersService } from '@app/offers/offers.service';
import {
  FluidToggleSwitchTypeEnum,
} from '@app/shared/components/atoms/switch/toggle-fluid-switch/fluid-toggle-switch.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  fluid = Fluid;

  selectedFluid!: Fluid;

  userConnected?: User;

  fluidButtonVisibility: boolean = true;

  fluidButtonDisabled: boolean = false;

  fluidSwitchType: FluidToggleSwitchTypeEnum = FluidToggleSwitchTypeEnum.TEXT_ICON;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 1400) {
      this.fluidSwitchType = FluidToggleSwitchTypeEnum.ONLY_ICON;
    } else {
      this.fluidSwitchType = FluidToggleSwitchTypeEnum.TEXT_ICON;
    }
  }

  constructor(
    private authentication: AuthService,
    private route: ActivatedRoute,
    private cscs: ComponentStatusChangeService,
    private fluidService: FluidService,
    public router: Router,
    public offersService: OffersService,
  ) {
    this.onResize();
  }

  ngOnInit(): void {
    this.authentication.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });

    this.cscs.headerFluidButtonVisibility$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((visibility) => {
        this.fluidButtonVisibility = visibility;
      });

    this.cscs.headerFluidButtonDisabled$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((disabled) => {
        this.fluidButtonDisabled = disabled;
      });

    this.fluidService.currentFluid$().pipe(takeUntil(this.unsubscribe$)).subscribe(({ fluid }) => {
      this.selectedFluid = fluid!;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  onChangeFluid(event: Fluid) {
    this.fluidService.setFluid(event);
    this.addQueryParams({
      fluid: event,
      page: null,
      exchangeRef: null,
      filter: null,
      searchFilter: null,
    });
  }

  private addQueryParams(queryParams?: Params) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
      },
    ).then();
  }
}
