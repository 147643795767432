import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  protected loading = new BehaviorSubject<{ open:boolean }>(
    { open: false },
  );

  public readonly loading$ = this.loading.asObservable();

  private readonly globalLoading$ = new ReplaySubject<boolean>(1);

  public lastValue = false;

  show() {
    if (!this.lastValue) {
      this.loading.next({ open: true });
      this.lastValue = true;
    }
  }

  hide() {
    if (this.lastValue) {
      this.loading.next({ open: false });
      this.lastValue = false;
    }
  }

  global() {
    return this.globalLoading$;
  }

  showGlobal() {
    this.globalLoading$.next(true);
  }

  hideGlobal() {
    this.globalLoading$.next(false);
  }
}
