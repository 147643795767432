<div class="sbc-container" [id]="sbcId">
  <div class="sbc-title">
    <app-label
      [typography]="Typography.title5"
      [weight]="FontWeight.semibold"
    >
      <ng-content select="[title]"></ng-content>
    </app-label>
    <app-icon *ngIf="infoTip"
              phosphorClass="ph-info"
              [iconStyle]="Style.fill"
              [appTooltip]="infoTip"
              appTooltipPosition="right"
    >
    </app-icon>
  </div>
  <div class="sbc-content"
       *ngIf="!isLoading; else skeletonLoading">
    <ng-content select="[content]"></ng-content>
  </div>
</div>

<ng-template #skeletonLoading>
  <ngx-skeleton-loader class="skeleton-loader" count="3"
                       [theme]="{'margin-bottom.rem': '1', 'margin-top.rem': '1'}"></ngx-skeleton-loader>
</ng-template>
