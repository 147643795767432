import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class ComponentStatusChangeService {
  headerFluidButtonVisibility$ = new BehaviorSubject(true);

  headerFluidButtonDisabled$ = new BehaviorSubject(false);

  loaderVisibility$ = new BehaviorSubject(false);

  showHeaderFluidButton(shown: boolean) {
    this.headerFluidButtonVisibility$.next(shown);
  }

  disabledHeaderFluidButton(disabled: boolean) {
    this.headerFluidButtonDisabled$.next(disabled);
  }

  showLoader(shown: boolean) {
    this.loaderVisibility$.next(shown);
  }

  currentValue() {
    return this.loaderVisibility$.value;
  }
}
