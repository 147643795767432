import { CommonModule } from '@angular/common';
import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ToggleSwitchComponent<T extends String> {
  @Input() disabled: boolean = false;

  @Input() options?: T[];

  @Input() currentOption?: T;

  @Input() size?: 'small' | 'medium' | 'large' = 'small';

  @Output() currentOptionChange = new EventEmitter<T>();

  onToggle(option: T) {
    this.currentOption = option;
    this.currentOptionChange.emit(option);
  }

  reset() {
    this.currentOption = undefined;
  }
}
