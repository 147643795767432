export class Fluid {
  static readonly ELEC = new Fluid('ELEC', 'Électricité');

  static readonly GAZ = new Fluid('GAZ', 'Gaz');

  static gieFluidName: { [key: string]: 'elec' | 'gas' } = {
    ELEC: 'elec',
    GAZ: 'gas',
  };

  static crmFluidName: { [key: string]: string } = {
    ELEC: 'ELEC',
    GAZ: 'GAZ',
  };

  // private to disallow creating other instances of this type
  private constructor(public readonly key: string, public readonly value: string) {

  }

  static getFluidFromValue(value?: string) {
    if (Fluid.gieFluidName.ELEC === value || Fluid.crmFluidName.ELEC === value) {
      return Fluid.ELEC;
    }

    if (Fluid.gieFluidName.GAZ === value || Fluid.crmFluidName.GAZ === value) {
      return Fluid.GAZ;
    }
    return Fluid.ELEC;
  }

  toString() {
    return this.key;
  }
}
