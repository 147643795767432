import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  catchError, Observable, switchMap, throwError,
} from 'rxjs';
import { UserResponse } from '@app/shared/interfaces/user-response.interface';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import { BASE_API_URL } from '@app/shared/utils/api';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private http: HttpClient, public router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((httpError: HttpErrorResponse) => {
      if (httpError.status === 401 && httpError.error.message === 'The incoming token has expired') {
        const token = this.authService.getToken();
        if (token) {
          return this.http
            .post<UserResponse>(
            `${BASE_API_URL.CA_API_BASE_URL_IAM}${API_ROUTES.LOGIN}`,
            { refreshToken: this.authService.getRefreshToken() },
            { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) },
          ).pipe(
            switchMap((res) => {
              this.authService.responseLogin(res, false);
              const newToken = this.authService.getToken();
              if (newToken) {
                return next.handle(request.clone({ headers: request.headers.set('Authorization', newToken) }));
              }
              return next.handle(request);
            }),
            catchError((err) => {
              this.authService.clear();
              this.router.navigate(['/']);
              return throwError(() => err);
            }),
          );
        }
        this.authService.clear();
        this.router.navigate(['/']);
        return throwError(() => httpError);
      }

      if (httpError.status === 503 && httpError.error.message === 'B2B Serverless maintenance') {
        this.router.navigate(['/maintenance']);
        return next.handle(request);
      }

      return throwError(() => httpError);
    }));
  }
}
