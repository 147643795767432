import {
  Component,
  ContentChild,
  ElementRef, EventEmitter, HostBinding,
  HostListener,
  Input, Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DialogContentDirective } from '@app/shared/components/atoms/dialog/dialog-content.directive';
import { DialogFooterDirective } from '@app/shared/components/atoms/dialog/dialog-footer.directive';
import { DialogHeaderDirective } from '@app/shared/components/atoms/dialog/dialog-header.directive';
import { IconComponent } from '../icon/icon.component';

// values in rem
export enum DialogSize {
  SMALL = 23.4375,
  MEDIUM = 37.5,
  LARGE = 56.25,
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[appBaseDialog]',
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss'],
  standalone: true,
  imports: [IconComponent],
})
export class BaseDialogComponent {
  @ContentChild(DialogContentDirective) content?: DialogContentDirective;

  @ContentChild(DialogHeaderDirective) header?: DialogHeaderDirective;

  @ContentChild(DialogFooterDirective) footer?: DialogFooterDirective;

  @ViewChild('contentVcr', { read: ViewContainerRef, static: true })
  private contentVcr?: ViewContainerRef;

  @ViewChild('headerVcr', { read: ViewContainerRef, static: true })
  private headerVcr?: ViewContainerRef;

  @ViewChild('footerVcr', { read: ViewContainerRef, static: true })
  private footerVcr?: ViewContainerRef;

  @HostBinding('style.width') width?: string;

  @Input() size: DialogSize = DialogSize.MEDIUM;

  @Output() cancelTrigger = new EventEmitter<void>();

  constructor(private host: ElementRef) {}

  showModal() {
    const {
      header, content,
      footer, element,
    } = this;

    this.element.removeAttribute('closing');
    element.showModal();
    this.initProperties();
    if (header?.tpl) this.headerVcr?.createEmbeddedView(header.tpl);
    if (content?.tpl) this.contentVcr?.createEmbeddedView(content.tpl);
    if (footer?.tpl) this.footerVcr?.createEmbeddedView(footer.tpl);
  }

  private initProperties() {
    const { size } = this;

    this.width = `${size}rem`;
  }

  close() {
    this.element.addEventListener(
      'animationend',
      (e: AnimationEvent) => {
        if (e.animationName.includes('fadeOut')) {
          this.element.close();
          this.clear();
          this.element.removeAttribute('closing');
        }
      },
      { once: true },
    );

    this.element.setAttribute('closing', 'true');
  }

  cancel() {
    this.cancelTrigger.emit();
    this.close();
  }

  private get element() {
    return this.host.nativeElement;
  }

  private clear() {
    this.footerVcr?.clear();
    this.contentVcr?.clear();
    this.headerVcr?.clear();
  }

  @HostListener('click', ['$event'])
  onDialogClick(event: MouseEvent) {
    if ((event.target as any).nodeName === 'DIALOG') {
      this.close();
      this.cancel();
    }
  }

  @HostListener('cancel')
  onDialogCancel() {
    this.clear();
  }

  get buttonDirection() {
    const { size } = this;
    return size === DialogSize.SMALL ? 'column' : 'row';
  }
}
