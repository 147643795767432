import { Component, Input } from '@angular/core';
import { LabelValue } from './label-value-list-displayer.types';

@Component({
  selector: 'app-label-value-list-displayer',
  templateUrl: './label-value-list-displayer.component.html',
  styleUrls: ['./label-value-list-displayer.component.scss'],
})
export class LabelValueListDisplayerComponent {
  @Input() displayDirection: 'horizontal' | 'vertical' = 'vertical';

  @Input() itemList: LabelValue[] = [];
}
