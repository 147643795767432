import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  standalone: true,
})
export class RadioButtonComponent {
  @Input() name = 'radio';

  @Input() id = 'radio';

  @Input() value = 'value';

  @Input() disabled? = false;

  @Input() checked? = false;

  @Output() checkedChange = new EventEmitter<string>();

  onChange() {
    this.checkedChange.emit(this.id);
  }
}
