import { DateTime } from 'luxon';
import { Offer } from '@app/shared/interfaces/offer.interface';
import { Fluid } from '@app/shared/models/fluid.model';

export function mapCrmOffer(offers: Offer[]): Offer[] {
  const format = 'dd/MM/yyyy';
  const newOffers: Offer[] = [];
  const dateNow = DateTime.now().toUnixInteger();
  offers.forEach((offer: Offer) => {
    const newOffer: Offer = { ...offer };
    switch (newOffer.status) {
      case 'SIGNEE': {
        newOffer.status = 'Signé';
        break;
      }
      case 'SIGNENR': {
        if (dateNow > DateTime.fromFormat(offer.supplyStartDate, format).toUnixInteger()
          && dateNow < DateTime.fromFormat(offer.supplyEndDate, format).toUnixInteger()) {
          newOffer.status = 'Actif';
        } else if (dateNow < DateTime.fromFormat(offer.supplyEndDate, format).toUnixInteger()) {
          newOffer.status = 'Signé';
        } else {
          newOffer.status = 'Résilié';
        }
        break;
      }
      case 'REFUSEE': {
        newOffer.status = 'Refusé';
        break;
      }
      case 'RETRACTEE': {
        newOffer.status = 'Invalidé';
        break;
      }
      case 'RESILIEE': {
        newOffer.status = 'Résilié';
        break;
      }
      case 'PERIMEE': {
        newOffer.status = 'Périmé';
        break;
      }
      default: {
        newOffer.status = 'Non défini';
        break;
      }
    }
    if (!newOffer.linkedSites) newOffer.linkedSites = [];
    newOffers.push(newOffer);
  });
  return newOffers;
}

export function getOffersNature(offers: Offer[]) {
  const offersElec = offers.filter((offer: Offer) => {
    const nature = Fluid.getFluidFromValue(offer.nature);
    return nature === Fluid.ELEC;
  });
  return offersElec.length > 0 ? Fluid.ELEC : Fluid.GAZ;
}
