import {
  Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import { SelectorComponent, SelectorTypeParams } from '@app/shared/components/organisms/selector/selector.component';
import { Pageable } from '@app/shared/interfaces/pagination.interface';
import { IconComponent, Size, Style } from '@app/shared/components/atoms/icon/icon.component';
import { ButtonIcon, ButtonSize, ButtonStyle } from '@app/shared/components/molecules/buttons/button.type';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@app/shared/components/molecules/buttons/button.component';

@Component({
  selector: 'app-table-footer',
  templateUrl: './table-footer.component.html',
  styleUrls: ['./table-footer.component.scss'],
  standalone: true,
  imports: [CommonModule, SelectorComponent, IconComponent, ButtonComponent],
})
export class TableFooterComponent<T> implements OnChanges {
  protected readonly Size = Size;

  protected readonly Style = Style;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonIcon = ButtonIcon;

  protected readonly ButtonStyle = ButtonStyle;

  @Input() source: T[] = [];

  @Input() pageable?: Pageable<any>;

  @Input() limit = 10;

  @Output() limitChange = new EventEmitter<number>();

  @Input() currentPage = 1;

  @Output() currentPageChange = new EventEmitter<number>();

  protected selectPages: number[] = [];

  protected itemLimitArray: SelectorTypeParams[] = this.initLimits([10, 20, 50, 100]);

  itemPageArray: SelectorTypeParams[] = [];

  @Input() set limits(values: number[] | undefined) {
    const [limit] = values ?? [];

    if (!values) return;

    this.limit = limit;
    this.paginatorBuild();
    this.limitChange.emit(limit);
    this.itemLimitArray = this.initLimits(values);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      source, limit, currentPage,
      pageable,
    } = changes;

    if (source || limit || currentPage || pageable) {
      if (this.pageable) {
        const { offset, limit: pageableLimit } = this.pageable;

        this.currentPage = (offset / pageableLimit) + 1;
        this.limit = pageableLimit;
      }
      this.paginatorBuild();
    }
  }

  private initLimits(values: number[]) {
    return values.map((x) => {
      const num = x.toString();
      return { title: num, value: num };
    });
  }

  onLimitChange($event: any) {
    this.limit = $event;
    this.paginatorBuild();
    this.currentPageChange.emit(1);
    this.limitChange.emit(+this.limit);
  }

  onPageChange($event: any) {
    this.currentPage = $event + 1;
    this.currentPageChange.emit(+this.currentPage);
  }

  get totalPage(): number {
    const { source, limit } = this;

    const { length: total } = source;

    return Math.ceil((this.pageable?.total || total) / limit);
  }

  paginatorBuild() {
    const { totalPage, currentPage } = this;

    this.itemPageArray = Array.from(Array(totalPage).keys()).map((x) => {
      const num = (x + 1).toString();
      return { title: num, value: num };
    });

    let from = currentPage;
    if (totalPage > 5) {
      if (from < 3) {
        from = 3;
      } else if (totalPage - from <= 2) {
        from = totalPage - 2;
      }
      this.selectPages = Array.from({ length: 3 }, (_, i) => i + from - 1);
    } else {
      this.selectPages = Array.from({ length: totalPage }, (_, i) => i + 1);
    }
  }

  get offset() {
    const { limit, currentPage } = this;

    return (currentPage - 1) * limit;
  }

  get rangeMax() {
    const {
      limit, offset, source,
      pageable,
    } = this;
    return Math.min(limit + offset, (pageable?.total || source.length));
  }

  get rangeMin() {
    const { offset } = this;
    return offset + 1;
  }

  protected get nbElement(): number {
    const { pageable, source } = this;

    return pageable?.total || source.length;
  }

  protected get pluralWord() {
    const { nbElement } = this;
    return nbElement > 1 ? 's' : '';
  }

  get isLimitSelectorUseful() {
    const { itemLimitArray, source } = this;
    const { length: total } = source;
    const limits = itemLimitArray.map(({ value }) => +value);

    const limitsWithPages = limits.map((limit) => +limit)
      .filter((limit) => {
        const nbPages = Math.ceil((this.pageable?.total || total) / limit);
        return nbPages !== 1;
      });

    return limitsWithPages.length > 0;
  }
}
