import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Pageable } from '@app/shared/interfaces/pagination.interface';

@Component({
  selector: 'app-paginator-container',
  templateUrl: './paginator-container.component.html',
  styleUrls: ['./paginator-container.component.scss'],
})
export class PaginatorContainerComponent {
  _pageable: Pageable<any> = {
    offset: 0,
    limit: 10,
    total: 0,
    content: [],
  };

  @Input() disabled: boolean = false;

  @Output() pageableChange: EventEmitter<Pageable<any>> = new EventEmitter<Pageable<any>>();

  @Input()
  set pageable(pageable: Pageable<any>) {
    // eslint-disable-next-line no-underscore-dangle
    this._pageable = pageable;
    this.pageableChange.next(pageable);
  }

  get pageable(): Pageable<any> {
    // eslint-disable-next-line no-underscore-dangle
    return this._pageable;
  }
}
