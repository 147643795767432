import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren, ElementRef, EmbeddedViewRef, EventEmitter, forwardRef,
  Input, OnChanges, OnDestroy, OnInit, Output,
  QueryList,
  Renderer2, SimpleChanges, ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { TableHeaderDirective } from '@app/shared/components/atoms/table/directives/table-header.directive';
import { TableCellDirective } from '@app/shared/components/atoms/table/directives/table-cell.directive';
import { RowCellsDirective } from '@app/shared/components/atoms/table/directives/row-cells.directive';
import { RowHeadersDirective } from '@app/shared/components/atoms/table/directives/row-headers.directive';
import { ColumnHeaderDirective } from '@app/shared/components/atoms/table/directives/column-header.directive';
import {
  debounceTime,
  distinctUntilChanged,
  map, ReplaySubject, Subject, take, takeUntil,
} from 'rxjs';
import { Pageable } from '@app/shared/interfaces/pagination.interface';
import { CollapseRowDirective } from '@app/shared/components/atoms/table/directives/collapse-row.directive';
import { CollapseCellDirective } from '@app/shared/components/atoms/table/directives/collapse-cell.directive';
import { StickyDirective } from '@app/shared/components/atoms/table/directives/sticky.directive';
import { MESSAGES } from '@app/shared/utils/messages';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TableDirective } from '@app/shared/components/atoms/table/directives/table.directive';
import { ActivatedRoute, Params } from '@angular/router';
import { TableFooterComponent } from './table-footer/table-footer.component';

interface HeaderOption {
  name?: string,
  sort?: 'asc' | 'dec',
  field?: string,
  type?: 'filter' | 'sort'
  lastModifiedDate: number;
  values?: string[];
}

type TemplateBlock = 'loadingBlock' | 'messageBlock' | 'dataBlock';

type GroupByColumn = { [key: string]: StickyDirective[] };

@Component({
  selector: 'app-base-table',
  templateUrl: 'base-table.component.html',
  styleUrls: ['./base-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TableFooterComponent,
    NgxSkeletonLoaderModule,
    TableDirective,
  ],
})
export class BaseTableComponent<T> implements AfterViewInit, OnInit, AfterContentChecked, OnDestroy, OnChanges {
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  @Input() isLoading = true;

  @Input() showFooter = true;

  @Input() id = 'default-table-id';

  @Input() source: T[] = [];

  @Input() pageable?: Pageable<any>;

  @Input() limits?: number[];

  @Output() pageableChange = new EventEmitter<Pageable<any>>();

  @Output() tableDisplayOnChangeEvent = new EventEmitter<void>();

  @Input() displayedColumns?: string[];

  @Input() messageInfo: string = '';

  @Input() tableLayoutAuto: boolean = false;

  public displayedSource: T[] = [];

  protected displayedPage: T[] = [];

  protected rows: number[] = [];

  protected headerRows: number[] = Array.from(Array(1).keys());

  @ContentChildren(forwardRef(() => ColumnHeaderDirective))
  private columns!: QueryList<ColumnHeaderDirective>;

  @ContentChildren(TableHeaderDirective)
  protected headers!: QueryList<TableHeaderDirective>;

  @ContentChildren(TableCellDirective)
  protected cells!: QueryList<TableCellDirective<T>>;

  @ContentChildren(CollapseCellDirective)
  private collapseCells!: QueryList<CollapseCellDirective<T>>;

  @ContentChildren(StickyDirective)
  private stickyElements!: QueryList<StickyDirective>;

  @ContentChild(RowHeadersDirective)
  private rowsHeaders!: RowHeadersDirective;

  @ContentChild(RowCellsDirective)
  private rowsCells!: RowCellsDirective;

  @ContentChild(CollapseRowDirective)
  private collapseRows!: CollapseRowDirective;

  @ViewChildren('headersVcr', { read: ViewContainerRef })
  private headersVcr!: QueryList<ViewContainerRef>;

  @ViewChildren('rowsVcr', { read: ViewContainerRef })
  private rowsVcr!: QueryList<ViewContainerRef>;

  @ViewChild('tableContainer')
  public readonly tableContainer!: ElementRef;

  private trViewRefs: EmbeddedViewRef<any>[] = [];

  private columnsEvent = new ReplaySubject<any>(1);

  headerFilters: HeaderOption[] = [];

  protected readonly Array = Array;

  protected limit: number = 10;

  protected currentPage: number = 1;

  protected noFilteredDataMessage: string = MESSAGES.TXT125;

  constructor(
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.route.queryParams
      .pipe(
        debounceTime(250),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (params) => {
          this.handlePaginationParams(params);

          this.updateTable();
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  handlePaginationParams({ page, pageSize }: Params) {
    if (pageSize) {
      this.limit = +pageSize;
    } else {
      const [limit] = this.limits || [this.limit];
      this.limit = limit;
    }
    this.currentPage = +(page ?? 0) + 1;
  }

  ngOnInit(): void {
    this.displayedSource = [...this.source];

    this.columnsEvent
      .pipe(
        takeUntil(this.unsubscribe$),
        map((columnEvent) => this.mapOptions(columnEvent)),
        distinctUntilChanged((prev: any, curr: any) => {
          const prevShowColumn = JSON.stringify(prev);
          const currShowColumn = JSON.stringify(curr);

          return prevShowColumn === currShowColumn;
        }),
      ).subscribe({
        next: (headerOptions: HeaderOption[]) => {
          this.headerFilters = headerOptions;

          this.currentPage = 1;

          this.updateTable();
          this.tableDisplayOnChangeEvent.emit();
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  ngAfterContentChecked(): void {
    const {
      columnsEvent, stickyElements, columns,
    } = this;

    columnsEvent.next(columns);

    if (stickyElements) this.initStickyElements();
  }

  private initStickyElements() {
    const accPosition: GroupByColumn = {};

    this.stickyElements.reduce((acc, item) => {
      const { columnName } = item;
      if (acc[columnName]) acc[columnName].push(item);
      else acc[columnName] = [item];

      return acc;
    }, accPosition);

    const position = { left: 0, right: 0 };
    const shadowBoxColumns = this.getExtremityColumns(accPosition);

    Object.entries(accPosition).forEach(([, stickyElements]) => {
      const [item] = stickyElements;
      const { offsetWidth } = item.el.nativeElement;

      stickyElements.forEach((directive) => {
        if (directive.isRightDirection()) directive.setRight(position.right);
        else directive.setLeft(position.left);

        if (shadowBoxColumns.includes(directive.columnName)) directive.addBoxShadow();
      });

      if (item.isRightDirection()) position.right += offsetWidth;
      else position.left += offsetWidth;
    });
  }

  private getExtremityColumns(position: GroupByColumn): string[] {
    const groupByDirection: any = { left: [], right: [] };

    Object.entries(position).map(([name, [element]]) => {
      const direction = element.isRightDirection() ? 'right' : 'left';
      return [name, direction];
    }).reduce((acc, [name, direction]) => {
      if (acc[direction]) acc[direction].push(name);
      else acc[direction] = [name];

      return acc;
    }, groupByDirection);

    const { left, right } = groupByDirection;
    return [left.pop(), right.shift()];
  }

  private removePreviousSort() {
    const [, ...previousSorts] = this.headerFilters
      .filter(({ type }) => type === 'sort')
      .filter(({ lastModifiedDate }) => lastModifiedDate > 0)
      .reverse();

    const columnNames = previousSorts.map(({ name }) => name);

    this.columns
      .filter((column) => column.header !== undefined)
      .filter(({ header }) => columnNames.includes(header!.name))
      .forEach((column) => column.removeSort());
  }

  private mapOptions(columns: QueryList<ColumnHeaderDirective>): HeaderOption[] {
    return columns
      .filter((column) => column.header !== undefined)
      .map(({ header }) => header!)
      .filter(({ field }) => ![field].includes(undefined))
      .sort((a, b) => a.lastModifiedDate - b.lastModifiedDate)
      .map((header) => header.extractOptions());
  }

  private sortTable() {
    const { headerFilters } = this;

    this.removePreviousSort();

    headerFilters
      .filter(({ type }) => type === 'sort')
      .filter(({ sort }) => sort !== undefined)
      .forEach(({ field, sort }) => {
        this.source = this.source.sort((first: T, second: T) => {
          const firstValue = JSON.stringify(this.searchFieldValue(first, field!));

          const secondValue = JSON.stringify(this.searchFieldValue(second, field!));

          const isAsc = sort === 'asc' ? 1 : -1;

          if (firstValue === secondValue) return 0;
          const sortValue = firstValue > secondValue ? 1 : -1;
          return isAsc * sortValue;
        });
      });
  }

  private filterTable() {
    const { headerFilters, source } = this;
    const filters = headerFilters.filter(({ type }) => type === 'filter');

    this.displayedSource = [...source];

    if (!filters.length) {
      return;
    }

    filters
      .forEach(({ field, values }) => {
        this.displayedSource = this.displayedSource.filter((item) => {
          if (!values || !values.length) return true;
          const value = this.searchFieldValue(item, field!);
          return values?.map((v) => v.toLowerCase()).includes(value);
        });
      });

    this.updateFiltersCount();
  }

  private updateFiltersCount() {
    const [, ...previousSorts] = this.headerFilters
      .filter(({ type }) => type === 'filter')
      .reverse();

    const columnNames = previousSorts.map(({ name }) => name);

    this.columns
      .filter((column) => column.header !== undefined)
      .filter(({ header }) => columnNames.includes(header!.name))
      .map((column) => column.header!.updateCount());
  }

  get isFiltered() {
    return !!this.headerFilters.filter(({ type, values }) => type === 'filter' && values?.length).length;
  }

  private searchFieldValue(obj: any, path: string) {
    let current = obj;
    path.split('.').forEach((p) => {
      current = current[p];
    });

    if (typeof current === 'string' || current instanceof String) return current.toLowerCase();

    return current;
  }

  ngAfterViewInit() {
    const {
      rowsVcr, headersVcr,
    } = this;

    headersVcr.changes
      .pipe(take(1))
      .subscribe({
        next: () => this.updateHeaders(),
      });

    rowsVcr.changes
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => this.updateRows(),
      });

    this.updateTable();

    this.changeDetectorRef.detectChanges();
  }

  protected updateTable() {
    const {
      currentPage, limit,
      rowsVcr, headersVcr,
    } = this;

    this.sortTable();
    this.filterTable();
    this.updatePagination();

    const nbItemPerPage = Math.min(this.displayedPage.length, limit * currentPage) % limit || limit;
    this.rows = Array.from(Array(nbItemPerPage).keys());
    this.changeDetectorRef.detectChanges();

    this.cleanTableViews();

    headersVcr?.notifyOnChanges();
    rowsVcr?.notifyOnChanges();

    this.changeDetectorRef.detectChanges();
  }

  private updatePagination() {
    const { limit, currentPage, pageable } = this;

    if (pageable) {
      this.displayedPage = this.displayedSource;
      return;
    }

    const start = (currentPage - 1) * limit;
    const end = currentPage * limit;

    this.displayedPage = this.displayedSource.slice(start, end);
  }

  private updateHeaders() {
    const {
      headersVcr, headers, rowsHeaders,
    } = this;

    if (!rowsHeaders) return;

    headersVcr!.forEach((vcr) => {
      const trView = vcr.createEmbeddedView(rowsHeaders.templateRef);
      const [tr] = trView.rootNodes;

      headers.forEach((directive) => {
        const thView = vcr!.createEmbeddedView(directive.templateRef);
        const [th] = thView.rootNodes;

        this.renderer.appendChild(tr, th);
      });
    });
  }

  private updateRows() {
    const {
      displayedPage, trViewRefs,
      cells, collapseCells,
      rowsVcr, rowsCells,
      hasCollapseRow, collapseRows,
      headers,
    } = this;

    rowsVcr!.forEach((vcr, index) => {
      const $implicit = displayedPage[index];
      if (!$implicit) return;

      const context = { $implicit, index };
      const trView = vcr.createEmbeddedView(rowsCells.templateRef, context);
      trViewRefs.push(trView);
      const [tr] = trView.rootNodes;

      if (hasCollapseRow) {
        const collapseTrView = vcr.createEmbeddedView(collapseRows.templateRef, context);
        trViewRefs.push(collapseTrView);
        const [collapseTr] = collapseTrView.rootNodes;
        this.renderer.addClass(collapseTr, 'expanded-row');

        collapseCells.forEach((directive) => {
          const tdView = vcr!.createEmbeddedView(directive.templateRef, context);
          const [td] = tdView.rootNodes;

          this.renderer.setAttribute(td, 'colspan', `${headers.length}`);
          this.renderer.appendChild(collapseTr, td);
        });
      }

      cells.forEach((directive) => {
        const tdView = vcr!.createEmbeddedView(directive.templateRef, context);
        const [td] = tdView.rootNodes;

        this.renderer.appendChild(tr, td);
      });
    });
  }

  private cleanTableViews() {
    const { trViewRefs } = this;
    trViewRefs.forEach((trViewRef) => trViewRef.destroy());

    this.trViewRefs = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { source, headers } = this;

    if (changes.source && source && source.length > 0) {
      const { currentValue, previousValue } = changes.source;
      const equals = JSON.stringify(currentValue) === JSON.stringify(previousValue);
      this.currentPage = 1;

      if (headers?.length > 0 && !equals) {
        this.updateTable();
      }
    }
  }

  protected get currentTemplate(): TemplateBlock {
    const {
      isLoading, messageInfo, source, displayedSource,
    } = this;
    if (isLoading) return 'loadingBlock';
    if ((messageInfo && !source.length) || !displayedSource.length) return 'messageBlock';
    return 'dataBlock';
  }

  get displayableRows() {
    return this.displayedSource;
  }

  onLimitChange(limit: number) {
    const { pageable } = this;
    this.tableDisplayOnChangeEvent.emit();

    if (!pageable) {
      this.updateTable();
      return;
    }

    this.pageableChange.emit({ ...pageable, limit, offset: 0 });
  }

  onPageChange(currentPage: number) {
    const { pageable } = this;
    this.tableDisplayOnChangeEvent.emit();

    if (!pageable) {
      this.updateTable();
      return;
    }

    const offset = (currentPage - 1) * pageable.limit;

    this.pageableChange.emit({ ...pageable, offset });
  }

  get hasCollapseRow(): boolean {
    return this.collapseRows !== undefined;
  }

  ngOnDestroy(): void {
    this.columnsEvent.complete();
    this.unsubscribe$.next(true);
  }
}
