<div class="container">
  <label class="switch">
    <input
      type="checkbox"
      [checked]="checked"
      (change)="toggleChecked()"
      [disabled]="disabled"
      [class.disabled]="disabled"
    >
    <span class="slider round" [class.disabled]="disabled"></span>
  </label>
  <app-label
    [typography]="Typography.body2"
    [weight]="FontWeight.regular"
    [color]="disabled ? Color.grey500 : Color.grey800"
  >
    {{label}}
  </app-label>
  <app-icon
      *ngIf="tooltip"
      phosphorClass="ph-info-fill tooltip"
      [iconStyle]="Style.fill"
      [class.disabled]="disabled"
      [appTooltip]="tooltip"
      appTooltipPosition="top">
  </app-icon>
</div>
