import { CommonModule } from '@angular/common';
import {
  AfterViewInit, Component, ElementRef, Input, QueryList, ViewChildren,
} from '@angular/core';
import { Color } from '@app/shared/models/color';

interface TextParams {
  content: string
  position?: 'start' | 'middle' | 'end'
  color?: Color
}

@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class DividerComponent implements AfterViewInit {
  @Input() direction: 'horizontal' | 'vertical' = 'horizontal';

  @Input() color: Color = Color.grey300;

  @Input() pixelSize: number = 1;

  @Input() text?: TextParams;

  @ViewChildren('hr') private hrEls?: QueryList<ElementRef>;

  ngAfterViewInit(): void {
    const borderStyle = `${this.pixelSize}px solid ${this.color}`;

    this.hrEls?.map((hrEl, index) => {
      const curHrEl = hrEl;
      if (this.direction === 'horizontal') {
        curHrEl.nativeElement.style.borderTop = borderStyle;
      } else {
        curHrEl.nativeElement.style.borderLeft = borderStyle;
        curHrEl.nativeElement.style.height = '100%';
      }

      let flexVal: Array<string | number> = ['auto', 'auto'];
      switch (this.textParams.position) {
        case 'start':
          flexVal = [1, 4];
          break;
        case 'end':
          flexVal = [4, 1];
          break;
        default:
          break;
      }

      curHrEl.nativeElement.style.flex = flexVal[index];

      return curHrEl;
    });
  }

  protected get textParams(): TextParams {
    return {
      content: this.text?.content || '',
      position: this.text?.position || 'middle',
      color: this.text?.color || Color.grey800,
    };
  }

  protected get flexDirectionStyle() {
    return this.direction === 'horizontal' ? 'row' : 'column';
  }
}
