import { Component, HostBinding, Input } from '@angular/core';
import { Size, Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';
import { Typography } from '@app/shared/models/typography';
import { FontWeight } from '@app/shared/models/font-weight';
import { MESSAGES } from '../../../utils/messages';

@Component({
  selector: 'app-sub-section-item',
  templateUrl: './sub-section-item.component.html',
  styleUrls: ['./sub-section-item.component.scss'],
})
export class SubSectionItemComponent {
  @Input() label?: string = '';

  @Input() value?: string = '';

  @Input() icon: string = '';

  @Input() iconStyle?: Style;

  @Input() infoTip: string = '';

  @Input() isLoading: boolean = false;

  @Input() @HostBinding('class.value-align') public valueIsAlign = false;

  @Input() direction: 'row' | 'column' = 'column';

  @Input() justifyContent: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' = 'center';

  protected readonly MESSAGES = MESSAGES;

  protected readonly Style = Style;

  protected readonly Color = Color;

  protected readonly Typography = Typography;

  protected readonly FontWeight = FontWeight;

  protected readonly Size = Size;
}
