<div class="header-card">
  <h1 [appTranslate]="welcomeMessage"></h1>

  <ng-container [ngSwitch]="theme">
    <ng-template [ngSwitchCase]="WelcomeCardTheme.confetti">
      <div *ngFor="let position of confettiPositions; let i = index"
           class="confetti-anim"
           [class.unpair]="i % 2 !== 0"
           [style.right]="position">
      </div>
    </ng-template>
  </ng-container>
</div>


