import {
  Component, Input, OnChanges, OnInit, SimpleChanges,
} from '@angular/core';
import { Chart } from 'angular-highcharts';
import {
  ChartZoomingOptions, ExportingMimeTypeValue,
  SeriesOptionsType,
  TooltipFormatterCallbackFunction,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';
import { UnitTypes } from '@app/shared/interfaces/consumptions.interface';
import { fractionDigitsByUnit } from '@app/consumptions/chart-services/mapper-shared';

@Component({
  selector: 'app-highcharts',
  templateUrl: './highcharts.component.html',
  styleUrls: ['./highcharts.component.scss'],
})
export class HighchartsComponent implements OnInit, OnChanges {
  @Input() xAxis: XAxisOptions | Array<XAxisOptions> | undefined = undefined;

  @Input() yAxis: YAxisOptions | Array<YAxisOptions> | undefined = undefined;

  @Input() series: SeriesOptionsType[] | undefined = undefined;

  @Input() zooming: ChartZoomingOptions | undefined = undefined;

  @Input() tooltipFormatter: TooltipFormatterCallbackFunction | undefined = undefined;

  @Input() unit: UnitTypes = UnitTypes.MWh;

  @Input() exporting = false;

  @Input() segment: string | undefined = undefined;

  @Input() tooltipDateFormat: string | undefined = '%A %e %B %Y - %H:%M';

  @Input() tooltipEnabled: boolean = true;

  @Input() tooltipShared: boolean = true;

  @Input() legendEnabled: boolean = false;

  @Input() legendreverse: boolean = false;

  chart: Chart | undefined = undefined;

  @Input() active?: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.active || changes.series) {
      this.initChart();
    }
  }

  initChart() {
    const valueDecimals = fractionDigitsByUnit(this.unit, this.segment);
    if (this.chart) this.chart?.destroy();

    this.chart = new Chart({
      exporting: {
        enabled: this.exporting,
        buttons: {
          contextButton: {
            menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
          },
        },
      },
      accessibility: {
        enabled: false,
      },
      chart: {
        type: 'area',
        zooming: this.zooming,
        marginTop: 50,
      },
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: this.legendEnabled,
        reversed: this.legendreverse,
      },
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      tooltip: {
        shared: this.tooltipShared,
        enabled: this.tooltipEnabled,
        borderRadius: 10,
        borderWidth: 0,
        shadow: {
          color: 'rgba(0, 0, 0, 0.5)',
        },
        padding: 15,
        backgroundColor: '#F1F2F5',
        xDateFormat: this.tooltipDateFormat,
        valueSuffix: ` ${this.unit}`,
        valueDecimals,
        formatter: this.tooltipFormatter,
      },
      plotOptions: {
        series: {
          turboThreshold: 0,
        },
        column: {
          borderRadius: 5,
        },
        area: {
          fillColor: {
            linearGradient: {
              x1: 0, x2: 0, y1: 0, y2: 1,
            },
            stops: [
              [0, '#B0FFDB'],
              [1, '#E3FFF200'],
            ],
          },
          marker: {
            radius: 2,
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
        },
      },
      time: {
        useUTC: false,
        timezone: 'Europe/Paris',
      },
      series: this.series,
    });
  }

  ngOnInit(): void {
    this.initChart();
  }

  print() {
    this.chart?.ref$.subscribe((chart) => {
      chart.print();
    });
  }

  export(type: ExportingMimeTypeValue) {
    this.chart?.ref$.subscribe((chart) => {
      chart.exportChart({
        type,
      }, {});
    });
  }

  fullscreenDisplay() {
    this.chart?.ref$.subscribe((chart) => {
      chart.fullscreen.open();
    });
  }
}
