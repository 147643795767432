import {
  animate, style, transition, trigger,
} from '@angular/animations';
import {
  Component, Output, EventEmitter, OnInit,
} from '@angular/core';
import { Style } from '@app/shared/components/atoms/icon/icon.component';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('toastTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(500px)' }),
        animate('1000ms cubic-bezier(.47,1.64,.41,.8)', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0, transform: 'translateX(500px)' })),
      ]),
    ]),
  ],
})
export class ToastComponent implements OnInit {
  isVisible: boolean = false;

  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    this.isVisible = true;
  }

  close(): void {
    this.isVisible = false;
    this.closeEvent.emit();
  }

  protected readonly Style = Style;
}
