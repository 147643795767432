import {
  AbstractControl, ValidationErrors, ValidatorFn,
} from '@angular/forms';

export class CustomMatchingValidators {
  static mustMatch(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const passwordConfirm = control.get('passwordConfirm');

    if (passwordConfirm?.errors && !passwordConfirm?.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return null;
    }

    // set error on matchingControl if validation fails
    if (password?.value !== passwordConfirm?.value) {
      passwordConfirm?.setErrors({ mustMatch: true });
      return { mustMatch: true };
    }

    passwordConfirm?.setErrors(null);
    return null;
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static cantMatchOldPassword(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const oldPassword = control.get('oldPassword');

    const oldPasswordEmpty = (oldPassword?.value || '').length === 0;

    if (!password?.dirty && oldPasswordEmpty) {
      return null;
    }

    if (password?.value === oldPassword?.value) {
      if (!control.hasError('cantMatch')) {
        password?.setErrors({ cantMatch: true });
        return { cantMatch: true };
      }
      return null;
    }
    if (password?.hasError('cantMatch')) {
      delete password?.errors?.cantMatch;
      control.updateValueAndValidity();
    }
    return null;
  }
}
