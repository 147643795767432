<div class="loading">
  <div class="dot-1"></div>
  <div class="dot-2"></div>
  <div class="dot-3"></div>
  <div class="dot-4"></div>
  <div class="dot-5"></div>
  <div class="dot-6"></div>
  <div class="dot-7"></div>
  <div class="dot-8"></div>
  <div class="dot-9"></div>
  <div class="dot-10"></div>
</div>
