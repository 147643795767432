import {
  AfterViewInit,
  Component, OnDestroy, OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import {
  catchError, forkJoin,
  map, mergeMap, Observable, Subject, takeUntil, tap,
} from 'rxjs';
import { AuthService } from '@app/shared/auth/auth.service';
import { AlertService } from '@app/shared/components/organisms/alert/alert.service';
import { OffersService } from '@app/offers/offers.service';
import { Fluid } from '@app/shared/models/fluid.model';
import { Offer } from '@app/shared/interfaces/offer.interface';
import { InitAppService } from '@app/shared/resolvers/init-app.service';
import { LoaderService } from '@app/shared/services/loader.service';
import { getOffersNature } from '@app/offers/mapper';
import { Pageable } from '@app/shared/interfaces/pagination.interface';
import { SiteOffer } from '@app/shared/interfaces/site.interface';
import { FluidService } from '@app/shared/services/fluid.service';
import { SiteOffersService } from '@app/site-offers/site-offers.service';
import { ProfileService } from '@app/shared/services/profile.service';
import { pingLogin } from '@app/shared/decorators/data-layer.decorator';
import { MESSAGES } from '@app/shared/utils/messages';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy, AfterViewInit {
  onProfileChange$: Subject<boolean> = new Subject<boolean>();

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  public userConnectedEmail?: string;

  fluid?: Fluid;

  profile: string | null = null;

  offers: Offer[] = [];

  resetPasswordAlertMessage = false;

  readonly statusWhiteList = ['Actif', 'Résilié', 'Signé'];

  constructor(
    private authentication: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    protected alertService: AlertService,
    public offersService: OffersService,
    private initAppService: InitAppService,
    private loaderService: LoaderService,
    private fluidService: FluidService,
    private siteOffersService: SiteOffersService,
    private profileService: ProfileService,
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state?.passwordReset) {
      this.resetPasswordAlertMessage = true;
    }

    this.profileService.currentProfile$()
      .pipe(
        map(({ profile }) => profile?.id),
        takeUntil(this.unsubscribe$),
      ).subscribe({
        next: (profile) => {
          this.loaderService.showGlobal();
          this.profile = profile || null;
          this.offersService.isNatureOfferLoading = true;
          this.onProfileChange$.next(true);

          forkJoin([
            this.getSiteOffers(),
            this.getOffers(),
          ]).pipe(
            map(([{ content: siteOffers }, offers]) => ({
              siteOffers, offers, profile,
            })),
            map((data) => {
              const { siteOffers } = data;
              const monosite = {
                elec: this.isMonosite(siteOffers, Fluid.ELEC),
                gas: this.isMonosite(siteOffers, Fluid.GAZ),
              };
              return { ...data, monosite };
            }),
            tap(({ offers }) => this.initFluid(offers)),
            mergeMap((data) => this.fluidService.currentFluid$().pipe(map((fluid) => Object.assign(data, fluid)))),
            takeUntil(this.onProfileChange$),
            tap((res) => {
              this.initAppService.next(res);
              this.offersService.isNatureOfferLoading = false;
              this.loaderService.hideGlobal();
            }),
          ).pipe(
            takeUntil(this.unsubscribe$),
            InitAppService.filterByFluid(),
          ).subscribe({
            next: ({ fluid, offers }) => {
              this.fluid = fluid!;
              this.offers = offers!;
            },
          });
        },
      });
  }

  ngOnInit(): void {
    pingLogin();
    const user = this.authentication.currentUserValue;
    this.userConnectedEmail = user?.email;
  }

  ngAfterViewInit(): void {
    if (this.resetPasswordAlertMessage) {
      this.alertService.success(MESSAGES.TXT32);
    }
  }

  initFluid(offers: Offer[]) {
    const { fluid } = this.route.snapshot.queryParams;
    const routeFluid = fluid ? Fluid.getFluidFromValue(fluid) : undefined;
    const calculatedFluid = getOffersNature(offers);

    const newFluid = routeFluid ?? calculatedFluid;
    this.fluidService.setFluid(newFluid);
  }

  getOffers() {
    const profile = this.profile ?? undefined;
    return this.offersService.getOffers({ profile })
      .pipe(
        tap((offers) => this.offersService.currentOffers$.next({ offers })),
        catchError(() => []),
      );
  }

  getSiteOffers(): Observable<Pageable<SiteOffer>> {
    const profile = this.profile ?? undefined;
    return this.siteOffersService.getSitesOffers({ profile });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();

    this.onProfileChange$.next(true);
    this.onProfileChange$.complete();
  }

  logout() {
    this.authentication.signOut();
  }

  isRouteActive(path: string) {
    return this.router.isActive(path, {
      paths: 'subset',
      queryParams: 'ignored',
      matrixParams: 'ignored',
      fragment: 'ignored',
    });
  }

  containsOffers() {
    const { statusWhiteList, offers } = this;
    const { isNatureOfferLoading } = this.offersService;

    if (this.isRouteActive('/account')) return true;

    if (isNatureOfferLoading) return true;
    const containsValidOffers = offers.filter(({ status }) => statusWhiteList.includes(status));

    return containsValidOffers.length > 0;
  }

  isMonosite(siteOffers: SiteOffer[], fluid: Fluid) {
    const activeSiteOffers = siteOffers.filter(({ nature }) => nature === fluid.key);
    const activeExchangeRefs = [...new Set(activeSiteOffers.map(({ site }) => site.exchangeRef))];

    return activeExchangeRefs.length === 1;
  }

  get loading$() {
    return this.loaderService.global();
  }
}
