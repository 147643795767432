import {
  Directive, Host, Input, OnInit, TemplateRef, ViewContainerRef,
} from '@angular/core';
import { BaseTableComponent } from '@app/shared/components/atoms/table/base-table.component';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appColumn]',
})
export class ColumnDirective implements OnInit {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  @Input('appColumn') columnName!: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Host() private baseTableComponent: BaseTableComponent<any>,
  ) { }

  ngOnInit(): void {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  showColumn() {
    const { columnName } = this;
    const { displayedColumns } = this.baseTableComponent;

    return displayedColumns?.includes(columnName) ?? true;
  }
}
