<dialog appBaseDialog #dialog id="daily-subscription-modal" [size]="DialogSize.MEDIUM" (cancelTrigger)="cancel()" >
  <ng-template appDialogHeader>
    <span>{{ (segment === 'C5') ? 'Activation collecte courbe de charge' : 'Données journalières' }}</span>
  </ng-template>

  <ng-template appDialogContent>
    <p>{{ (segment === 'C5') ? MESSAGES.TXT66 : MESSAGES.TXT46 }}</p>

    <div class="form-tips">
      <app-icon phosphorClass="ph-info" [iconStyle]="Style.fill" class="icon" [color]="Color.info500"></app-icon>
      <span>
        {{MESSAGES.TXT47}}
      </span>
    </div>
  </ng-template>

  <ng-template appDialogFooter>
    <app-button
      buttonId="cancel-request-btn"
      [style]="ButtonStyle.Outlined"
      (onclick)="cancel()"
      >Annuler</app-button>
    <app-button
      buttonId="validate-btn"
      (onclick)="submitForm()"
    >Accepter</app-button>
  </ng-template>
</dialog>
