<div
  [id]="id || cardId"
  class="card"
  (click)="enableClick ? onClick(): undefined"
  [style.cursor]="cursor">
  <div class="card-title">
    <div class="title">
      <app-icon [phosphorClass]="iconName"></app-icon>
      <span>{{ cardTitle }}</span>
      <app-icon *ngIf="tooltipTitle"
          phosphorClass="ph-info"
          [iconStyle]="Style.fill"
          class="tooltip"
          [appTooltip]="tooltipTitle"
          appTooltipPosition="left">
      </app-icon>
    </div>
    <app-icon phosphorClass="ph-caret-right" class="icon-right icon" *ngIf="enableClick"></app-icon>
  </div>
  <div class="card-content" [class.pointer]="!isContentClickable" (click)="isContentClickable ? $event.stopPropagation() : undefined">
    <ng-content></ng-content>
  </div>
</div>
