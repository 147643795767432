<div class="container" [style.flex-direction]="direction" [style.gap]="gap">
  <app-radio-button *ngFor="let radio of radioGroup; let index = index"
    [id]="radio.key"
    (checkedChange)="onRadioChange($event)"
    [name]="name"
    [checked]="radio.checked"
    [value]="radio.value"
    [disabled]="radio.disabled">
  </app-radio-button>
</div>
