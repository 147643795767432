import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'perimeterNameConcat',
})
export class PerimeterNameConcatPipe implements PipeTransform {
  transform(value: string): string {
    if (value.length <= 20) {
      return value;
    }
    const substringValue = value.substring(0, 18);
    return `${substringValue}…`;
  }
}
