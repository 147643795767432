<div class="footer-container">
    <div class="pagination-legend" [style.visibility]="isLimitSelectorUseful ? 'visible' : 'hidden'">
        <span>Éléments par page</span>
        <app-selector
          selectId="paginator-limit-select"
          [coverSpacing]="'spaceEvenly'"
          [params]="itemLimitArray"
          [value]="limit.toString()"
          (valueChange)="onLimitChange($event)">
        </app-selector>
    </div>
    <div class="pagination-legend">
      <div *ngIf="isLimitSelectorUseful; then thenElementPerPageMessage else elseSinglePageMessage"></div>
      <ng-template #thenElementPerPageMessage><span>{{ rangeMin }} à {{ rangeMax }} sur {{ nbElement }} éléments</span></ng-template>
      <ng-template #elseSinglePageMessage><span>{{ nbElement }} élément{{ pluralWord }}</span></ng-template>
    </div>
    <div class="pagination-selection" [style.visibility]="isLimitSelectorUseful ? 'visible' : 'hidden'">
        <ul class="paginator">
            <li>
                <app-button
                  buttonId="caret-left-btn"
                  [style]="ButtonStyle.Secondary"
                  [size]="ButtonSize.Small"
                  [iconOptions]="{phosphorClass: 'ph-caret-left', iconStyle: Style.bold, position: ButtonIcon.OnlyIcon}"
                  [isDisabled]="currentPage === 1"
                  (onclick)="onPageChange(currentPage -2)">
                  <app-icon phosphorClass="ph-caret-left" [iconStyle]="Style.bold"></app-icon>
                </app-button>
            </li>

            <li *ngIf="totalPage > 5">
                <app-button
                  buttonId="{{currentPage === 1 && 'active'}}"
                  [style]="currentPage === 1 ? ButtonStyle.Primary : ButtonStyle.Secondary"
                  [isSelected]="currentPage === 1"
                  [size]="ButtonSize.Small"
                  (onclick)="onPageChange(0)">
                    {{totalPage ? '1' : '0'}}
                </app-button>
            </li>

            <li *ngIf="selectPages[0] >= 3">
                <span>...</span>
            </li>

            <li *ngFor="let selectPage of selectPages">
                <app-button
                  buttonId="{{selectPage === currentPage && 'active'}}"
                  [style]="selectPage === currentPage ? ButtonStyle.Primary : ButtonStyle.Secondary"
                  [isSelected]="selectPage === currentPage"
                  [size]="ButtonSize.Small"
                  (onclick)="onPageChange(selectPage - 1)">
                    {{selectPage}}
                </app-button>
            </li>

            <li *ngIf="selectPages[2] <= (totalPage-2) && totalPage > 5">
                <span>...</span>
            </li>

            <li *ngIf="totalPage > 5">
              <app-button
                buttonId="{{currentPage === totalPage  && 'active'}}"
                [style]="currentPage === totalPage  ? ButtonStyle.Primary : ButtonStyle.Secondary"
                [isSelected]="currentPage === totalPage "
                [size]="ButtonSize.Small"
                (onclick)="onPageChange(totalPage - 1)">
                  {{totalPage}}
                </app-button>
            </li>

            <li>
                <app-button
                  buttonId="caret-right-btn"
                  [style]="ButtonStyle.Secondary"
                  [size]="ButtonSize.Small"
                  [iconOptions]="{phosphorClass: 'ph-caret-right', iconStyle: Style.bold, position: ButtonIcon.OnlyIcon}"
                  [isDisabled]="currentPage === totalPage || totalPage === 0"
                  (onclick)="onPageChange(currentPage)">
                  <app-icon phosphorClass="ph-caret-right" [iconStyle]="Style.bold"></app-icon>
                </app-button>
            </li>
        </ul>
        <app-selector
          selectId="paginator-currentPage-select"
          [coverSpacing]="'spaceEvenly'"
          [params]="itemPageArray"
          [value]="currentPage.toString()"
          (valueChange)="onPageChange(+$event - 1)">
        </app-selector>
    </div>
</div>
