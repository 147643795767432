<div class="container">
  <section>
    <app-welcome-card [theme]="WelcomeCardTheme.default"></app-welcome-card>
  </section>
  <section>
    <div class="no-offer-card">
      <div class="logo">
        <img id="data-img" src="assets/missing-offer-{{ currentFluid?.key }}.svg" alt="icône aucun contrat {{ currentFluid?.key }}">
      </div>
      <div id="message" class="message no-data" [innerHTML]="getMessage()"></div>
    </div>
  </section>
</div>
