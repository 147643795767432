import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'civility',
})
export class CivilityPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) return '';
    if (['MRS', 'Mme', 'Madame'].includes(value)) return 'Madame';
    if (['MR', 'M.', 'Monsieur'].includes(value)) return 'Monsieur';
    return value;
  }
}
