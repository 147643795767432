import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedCommonModule } from '@app/shared/shared-common.module';
import { SharedLibsModule } from '@app/shared/shared-libs.module';

@NgModule({
  imports: [SharedLibsModule, SharedCommonModule],
  exports: [
    SharedCommonModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
    };
  }
}
