import jwt_decode from 'jwt-decode';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEFAULT_DECORATOR_FUNCTION = (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {};

function pushEvent(event: any) {
  // @ts-ignore
  window.dataLayer.push(event);
}

export function pingLogin() {
  const idToken = localStorage.getItem('idToken') ?? '';

  if (!idToken) return DEFAULT_DECORATOR_FUNCTION;

  const { sub: userId } = jwt_decode(idToken) as any;

  pushEvent({
    event: 'login',
    userId,
  });

  return DEFAULT_DECORATOR_FUNCTION;
}
