import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { Router } from '@angular/router';
import { MESSAGES } from '@app/shared/utils/messages';
import { Duration } from 'luxon';

const DEFAULT_AUTO_RELOAD = Duration.fromObject({ minutes: 5 }).toMillis();

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  MESSAGES = MESSAGES;

  private intervalId?: NodeJS.Timer;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.checkEndOfMaintenance();

    this.intervalId = setInterval(() => {
      this.checkEndOfMaintenance();
    }, DEFAULT_AUTO_RELOAD);
  }

  private checkEndOfMaintenance() {
    this.authService.healthCheck().subscribe({
      next: () => this.router.navigate(['/']),
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
