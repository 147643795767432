<footer class="footer-container">
  <div class="slogan-container">
    <span class="slogan">L'énergie est notre avenir, économisons-la !
    </span>
  </div>
  <app-divider></app-divider>
  <div class="footer-menu">
    <ul>
      <li><a class="links" target="_blank" href="https://www.alterna-energie.fr/politique-de-confidentialite">Conditions
        générales d'utilisation</a></li>
      <li><a class="links" target="_blank" href="https://www.alterna-energie.fr/mentions-legales">Mentions légales</a>
      </li>
      <li><a id="btn-cookies" (click)="handleClickAxeptio()" class="links">Politique de cookies</a></li>
      <li><a [href]="zendeskUrl" target="_blank" class="links">Contactez-nous</a></li>
    </ul>
  </div>
</footer>
