import { Component, Input } from '@angular/core';
import { Size, Style } from '@app/shared/components/atoms/icon/icon.component';
import { Typography } from '@app/shared/models/typography';
import { FontWeight } from '@app/shared/models/font-weight';
import { MESSAGES } from '../../../utils/messages';

@Component({
  selector: 'app-sub-section',
  templateUrl: './sub-section.component.html',
  styleUrls: ['./sub-section.component.scss'],
})
export class SubSectionComponent {
  @Input() infoTip: string = '';

  @Input() sbcId: string = '';

  @Input() isLoading: boolean = false;

  protected readonly MESSAGES = MESSAGES;

  protected readonly Style = Style;

  protected readonly Typography = Typography;

  protected readonly FontWeight = FontWeight;

  protected readonly Size = Size;
}
