import { environment } from '@environments/environment';

const CONTACTER_SERVICE_FIDELISATION = `veuillez <b><u><a href="${environment.ZENDESK_SUPPORT_URL}" target="_blank">cliquer ici</a></u></b> pour contacter notre service fidélisation.`;
const CONTACTER_SERVICE_FIDELISATION_CLIQUEZ_ICI = `<b><u><a href="${environment.ZENDESK_SUPPORT_URL}" target="_blank">cliquer ici</a></u></b>`;

export const MESSAGES: {
  [key: string]: any
} = {
  TXT01: `Vous n’avez pas de contrat gaz, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT02: `Vous n’avez pas de contrat électricité, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT03: `Vous n’avez pas de site gaz, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT04: `Vous n’avez pas de site électricité, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT05: `Vous n’avez pas de facture gaz, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT06: `Vous n’avez pas de facture électricité, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT07: 'Téléchargement de la facture terminé.',
  TXT08: 'Téléchargement du mémoire terminé.',
  TXT09: `Votre facture est indisponible, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT10: `Votre mémoire est indisponible, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT11: 'Oups ! Notre espace client recharge ses batteries, revenez plus tard.',
  TXT12: 'Oups ! Notre espace client recharge ses batteries, revenez plus tard.',
  TXT13: `Aucun document disponible, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT14: 'Electricité Forward France : prix à terme ; Base CAL : sur une année calendaire ; Peak CAL : heures de 8h à 20h uniquement en semaine, en jours ouvrés ; Prix SPOT France : prix établis sur le marché de l\'électricité par les bourses le jour J pour le lendemain',
  TXT15: 'PEG : Prix au Point d\'Echange Gaz à terme ; Clean Spark Spread : différence entre le prix peak de l’électricité et 2 fois le prix du gaz + 1/3 du prix du CO2',
  TXT16: 'Vous avez généré trop de code, faites une pause, réessayez plus tard.',
  TXT17: 'Oulàlà, une erreur est survenue, réessayez ultérieurement.',
  TXT18: 'Consommation Annuelle de Référence (CAR) actualisée, mise à jour par le GRD en avril de chaque année.',
  TXT19: 'Si une modification de puissance est en cours, les nouvelles puissances seront visibles au maximum 7 jours après la date de l\'intervention technique.',
  TXT21: 'La date de fin doit être postérieure à la date de début.',
  TXT22: `Les détails du site ne sont pas accessibles, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT23: `Oups, une erreur est survenue, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT24: `Le fichier est un peu trop lourd, il ne peut pas être téléchargé, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT25: `Oups, une erreur est survenue lors du téléchargement, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT26: 'Votre export est en cours.',
  TXT27: `Vous n’avez pas de données de facturation disponibles, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT28: `Une erreur est survenue, veuillez vérifier les informations saisies. Si l’erreur persiste, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT29: 'La date de début doit être antérieure à la date de fin.',
  TXT31: 'Une erreur s\'est produite lors de la connexion',
  TXT32: 'Félicitations votre mot de passe a été modifié.',
  TXT33: 'Les jours PP1, Période de Pointe, correspondent à des journées de tension sur le système électrique (pic de consommation d\'énergie). Ces journées définies par RTE surviennent particulièrement en hiver lors de vagues de froid (pointe hivernale).',
  TXT34: 'Les données de consommation pour les contrats gaz ne sont pas proposées à ce jour.',
  TXT35: `Vous n’avez pas de données de consommation disponibles, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT36: {
    p1: 'Données de courbe de charge non disponibles.',
    p2: 'Deux raisons possibles à cela :',
    p3: '• Votre Gestionnaire de réseau (GRD) ne publie pas vos courbes',
    p4: '• Vos courbes vont être publiées prochainement, soyez patient...',
  },
  TXT37: 'Les données de consommation pour les sites C5 ne sont pas proposées à ce jour.',
  TXT39: 'La date sélectionnée ne peut pas être antérieure au',
  TXT40: 'La date sélectionnée ne peut pas être postérieure au',
  TXT41: `Vous n’avez pas de donnée de consommation électricité, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT42: 'Votre demande a bien été prise en compte, elle sera effective d’ici 72 heures.',
  TXT43: 'La date de début de période affichée correspond à la date de relève de votre gestionnaire de réseau.',
  TXT44: 'Vous n’avez pas de collaborateurs',
  TXT45: 'Je n’autorise plus Alterna Energie à collecter mes données de consommation de manière journalière.',
  TXT46: 'J’autorise Alterna Energie à collecter les données de ma consommation journalière (Index quotidien, Pmax atteinte quotidienne, Courbe de charge aux pas restitués par le GRD) pendant toute la durée de mon contrat.',
  TXT47: 'Je pourrai à tout moment, librement, gratuitement et sans préjudice, retirer mon consentement.',
  TXT48: 'a été ajouté à votre liste de collaborateurs en tant que',
  TXT50: {
    title: 'Prochainement, ma consommation détaillée',
    message: 'Patience. Les données de consommation GAZ seront bientôt disponibles. Nos équipes Alterna Energie font leur max !',
  },
  TXT51: 'Cette personne a déjà accès à l’espace client. Veuillez vérifier ses droits en vous rendant sur son profil.',
  TXT52: {
    question: 'C\'est votre première connexion et vous ne parvenez pas à vous connecter :',
    resp1: 'Vérifiez le mot de passe saisi.',
    resp2: 'Sinon générez un nouveau mot de passe provisoire.',
  },
  TXT55: 'Aucun résultat ne correspond à cette recherche, veuillez vérifier les informations saisies.',
  TXT59: 'Email et/ou mot de passe incorrect',
  TXT60: `Il se peut cependant que votre session existe sur l’ancien Espace Client.
            Nous vous invitons donc à essayer de vous connecter avec vos identifiants en suivant le lien ci-dessous.`,
  TXT61: 'Nous avons envoyé un mot de passe provisoire à l\'adresse ',
  TXT62: 'Votre session a expiré, veuillez vous reconnecter.',
  TXT63: 'Site en maintenance',
  TXT64: 'Votre compteur n’est pas encore communicant : le suivi de consommation de votre installation n’est pas disponible.',
  TXT65: 'La courbe de charge n’est pas collectée par Alterna énergie auprès de votre gestionnaire de réseau, cliquez pour ',
  TXT66: 'J’autorise Alterna Energie à collecter les données de ma consommation (Index quotidien, Pmax atteinte quotidienne, Courbe de charge aux pas restitués par le GRD) pendant toute la durée de mon contrat.',
  TXT67: 'Je n’autorise plus Alterna Energie à collecter mes données de consommation.',
  TXT69: 'L\'option comparaison est incompatible avec le découpage par poste.',
  TXT70: 'L\'option découpage par poste est incompatible avec les granularités Heure, Année, Semaine.',
  TXT72: 'Retrouvez nous très bientôt. Merci de votre patience.',
  TXT73: 'L’export des sites actifs gaz n’est pas proposé à ce jour.',
  TXT74: `Vous n’avez pas de sites actifs, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT75: 'L\'export de vos documents est en cours. Nous y travaillons en arrière-plan, vous recevrez un mail lorsque l\'opération sera terminée.',
  TXT76: 'Vous n\'avez pas encore réalisé votre premier export.',
  TXT85: 'Retrouvez les tendances des prix du marché de l’électricité et du gaz.',
  TXT86: 'Cela inclus : le montant de vos consommations et de votre abonnement éventuel.',
  TXT87: `Cela inclus :
  - Les contributions aux obligations d’économies d’énergie
  - Les contributions au mécanisme de garantie de capacité
  - Les options et prestations GRD`,
  TXT88: 'Cela inclus : Les taxes, contributions et TVA fixées par les pouvoirs publics.',
  TXT89: 'Budget en cours d\'estimation',
  TXT90: 'La période ne doit pas excéder 12 mois',
  TXT91: 'Aucune donnée de consommation disponible.',
  TXT92: 'Comparaison des consommations cumulées depuis le 1er janvier',
  TXT93: {
    link: 'Cliquez ici',
    rest: ' pour accéder à l\'ensemble des données de consommation des sites de votre périmètre',
  },
  TXT94: 'Pour accéder à la liste des utilisateurs, vous devez choisir un seul périmètre.',
  TXT94b: 'Il vous suffit d\'aller dans le menu situé dans le volet supérieur de l\'Espace Client, puis de cliquer sur "Tous mes périmètres".',
  TXT95: 'Le format de l\'email n\'est pas valide',
  TXT96: 'L\'email est requis',
  TXT97: 'Le mot de passe est requis',
  TXT98: 'Les deux mots de passe ne sont pas identiques',
  TXT100: 'La période ne doit pas excéder 24 mois',
  TXT101: `Aucun site lié à ce contrat, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT104: 'Vous devez saisir les dates et le ou les site(s) concerné(s) par votre export avant de pouvoir cliquer sur le bouton "Générer l’export"',
  TXT105: 'L’export reprend les éléments présents sur la facture, chaque ligne du fichier représente donc une facture. Les dates sélectionnées pour générer l’export correspondent aux dates d’émission des factures.',
  TXT106: 'L’export reprend les éléments présents sur la facture, chaque ligne du fichier regroupe les factures de la période concernée pour un PRM. Les dates sélectionnées pour générer l’export correspondent aux dates d’émission des factures.',
  TXT107: 'Ma consommation correspond à celle enregistrée par mon compteur et qui est communiquée par mon gestionnaire de réseau (GRD) à mon fournisseur (Alterna énergie) : elle est exprimée en MWh ou kWh (1 MWh=1000 kWh). Autrement dit, c’est votre “<b>profil de consommation</b>” sur une période donnée.',
  TXT108: `Téléchargement des documents indisponible, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT109: 'C’est le relevé des puissances atteintes de votre compteur par votre gestionnaire de réseau (GRD). Il permet d’établir une courbe qui représente votre façon de consommer. Le relevé est effectué du jour 1 à 0h00 au jour N à 0h00. La “Puissance atteinte” affichée à 00h00 est la moyenne des valeurs entre 00h00 et 00h10 pour le pas 10 min. ',
  TXT110: 'Votre demande est bien prise en compte, elle sera traitée par notre service fidélisation dans les plus brefs délais.',
  TXT111: 'Date d\'échéance de paiement contractuelle dépassée',
  TXT112: `Votre relevé d'information est indisponible, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT114: `<span>Bonjour,
Vous n’avez pas encore de contrat $$fluid avec Alterna énergie.
Nous aimerions vous compter parmi nos clients, pour cela vous pouvez nous contacter par mail à <a href="mailto:moncommercial@alterna-energie.fr">moncommercial@alterna-energie.fr</a></span>`,
  TXT118: 'Vous devez choisir un site avant de pouvoir cliquer sur le bouton "Accéder aux données"',
  TXT120: 'Affichage du dernier mois des données disponibles.',
  TXT121: 'Affichage des 3 derniers mois des données disponibles.',
  TXT122: 'Affichage de la dernière année des données disponibles.',
  TXT123: 'L\'export de vos courbes de charge est en cours. Nous y travaillons en arrière-plan, vous recevrez un mail lorsque l\'opération sera terminée.',
  TXT125: 'Aucun résultat ne correspond aux filtres appliqués.',
  TXT126: 'Il n\'y a pas de contrat ou de courbe sur l’ensemble de cette période.',
  TXT127: `La limite de changement de mot de passe a été atteinte, veuillez réessayer dans 30 minutes environs. Si l’erreur persiste, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT128: {
    PRM: {
      monosite: {
        title: 'Mes données de facturation synthétiques',
        message: 'Exporter en masse toutes les factures de mon PRM sur une période de 12 mois maximum.',
        tooltip: 'L’export reprend les éléments présents sur la facture, chaque ligne du fichier regroupe les factures de la période concernée pour un PRM. Les dates sélectionnées pour générer l’export correspondent aux dates d’émission des factures.',
      },
      multisite: {
        title: 'Mes données de facturation synthétiques par PRM',
        message: 'Exporter en masse toutes les factures d\'un PRM sur une période de 12 mois maximum.',
        tooltip: 'L’export reprend les éléments présents sur la facture, chaque ligne du fichier regroupe les factures de la période concernée pour un PRM. Les dates sélectionnées pour générer l’export correspondent aux dates d’émission des factures.',
      },
    },
    PCE: {
      monosite: {
        title: 'Mes données de facturation synthétiques',
        message: 'Exporter en masse toutes les factures de mon PCE sur une période de 12 mois maximum.',
        tooltip: 'L’export reprend les éléments présents sur la facture, chaque ligne du fichier regroupe les factures de la période concernée pour un PCE. Les dates sélectionnées pour générer l’export correspondent aux dates d’émission des factures.',
      },
      multisite: {
        title: 'Mes données de facturation synthétiques par PCE',
        message: 'Exporter en masse toutes les factures d\'un PCE sur une période de 12 mois maximum.',
        tooltip: 'L’export reprend les éléments présents sur la facture, chaque ligne du fichier regroupe les factures de la période concernée pour un PCE. Les dates sélectionnées pour générer l’export correspondent aux dates d’émission des factures.',
      },
    },
  },
  TXT129: {
    title: 'Mes données de facturation détaillées',
    message: 'Exporter en masse toutes les factures sur une période de 12 mois maximum.',
  },
  TXT130: {
    monosite: {
      title: 'Mon site actif',
      message: 'Exporter les informations détaillées de mon site.',
    },
    multisite: {
      title: 'Mes sites actifs',
      message: 'Exporter la liste des sites actifs de mon périmètre à date.',
    },
  },
  TXT131: {
    PRM: {
      monosite: {
        title: '', // Untreated case. Cannot be reached
        message: '', // Untreated case. Cannot be reached
      },
      multisite: {
        title: 'Mes courbes de charge',
        message: 'Exporter en masse les relevés des puissances atteintes de mon compteur au format csv.',
      },
    },
    PCE: {
      monosite: {
        title: '', // Untreated case. Cannot be reached
        message: '', // Untreated case. Cannot be reached
      },
      multisite: {
        title: '', // Untreated case. Cannot be reached
        message: '', // Untreated case. Cannot be reached
      },
    },
  },
  TXT134: 'Trouvez un contrat en saisissant le titulaire.',
  TXT135: {
    elec: 'Trouvez un site en saisissant le numéro de PRM, le nom du site ou l’adresse.',
    gaz: 'Trouvez un site en saisissant le numéro de PCE, le nom du site ou l’adresse.',
  },
  TXT136: {
    elec: 'Trouvez un site en saisissant le numéro de PRM, le nom du site, l’adresse ou le titulaire.',
    gaz: 'Trouvez un site en saisissant le numéro de PCE, le nom du site, l’adresse ou le titulaire.',
  },
  TXT137: {
    elec: 'Trouvez un site en saisissant le numéro de PRM, le nom du site, l’adresse ou le titulaire.',
  },
  TXT138: 'Trouvez un export en saisissant le nom du fichier.',
  TXT139: {
    elec: 'Trouvez un site en saisissant le numéro de PRM, le nom du site ou l’adresse.',
    gaz: 'Trouvez un site en saisissant le numéro de PCE, le nom du site ou l’adresse.',
  },
  TXT140: `Aucune courbe de charge n’est disponible sur la période demandée. Veuillez vérifier les dates sélectionnées. Si vous souhaitez contacter notre service fidélisation, veuillez ${CONTACTER_SERVICE_FIDELISATION_CLIQUEZ_ICI}.`,
  TXT143: 'Téléchargement de $nb_documents documents en cours, veuillez patienter.',
  TXT144: 'Téléchargement des documents terminé.',
  TXT145: 'Vous êtes sur le point de modifier le nom de ce site. La modification sera mise à jour sur tout notre système d’information et apparaîtra sur tous les documents concernés (contrats, factures, etc...)<br/><br/><b>Êtes-vous sûr de vouloir poursuivre ?</b>',
  TXT146: 'La modification de nom de votre site a bien été prise en compte, elle sera effective d’ici 72 heures.',
  TXT147: 'Trouvez un fichier en saisissant son nom.',
  TXT149: `Vous n’avez pas de données de facturation disponibles, ${CONTACTER_SERVICE_FIDELISATION}`,
  TXT150: 'Le nom du site doit être renseigné.',
};
