import {
  Component, Input, OnInit,
} from '@angular/core';
import { Fluid } from '@app/shared/models/fluid.model';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { SessionStorageService } from '@app/shared/auth/session-storage.service';
import { AuthService } from '@app/shared/auth/auth.service';
import { InitAppService } from '@app/shared/resolvers/init-app.service';
import { CDN_SOREGIES_URL } from '@app/shared/utils/urls';
import { map, Observable, shareReplay } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OffersService } from '@app/offers/offers.service';
import { Color } from '@app/shared/models/color';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
})
export class SidenavMenuComponent implements OnInit {
  CDN_SOREGIES_URL = CDN_SOREGIES_URL;

  isMenuOpen = false;

  protected readonly Color = Color;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );

  @Input() disabled = false;

  currentFluid?: Fluid;

  isActiveOptions: IsActiveMatchOptions = {
    paths: 'subset',
    queryParams: 'ignored',
    matrixParams: 'ignored',
    fragment: 'ignored',
  };

  private openMenuDelay: any;

  private currentProfile: string | null = null;

  constructor(
    private sessionStorage: SessionStorageService,
    private initAppService: InitAppService,
    public offersService: OffersService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initAppService.current().subscribe({
      next: ({ fluid }) => {
        this.currentFluid = fluid;
        this.currentProfile = this.sessionStorage.get(AuthService.PROFILE_ID);
      },
    });
  }

  onMenuToggle($event: MouseEvent) {
    this.isMenuOpen = !this.isMenuOpen;
    $event.stopPropagation();
  }

  handleCloseMenu() {
    clearTimeout(this.openMenuDelay);
    this.isMenuOpen = false;
  }

  handleOpenMenu() {
    this.openMenuDelay = setTimeout(() => {
      this.isMenuOpen = true;
    }, 330);
  }

  get fluid() {
    return this.currentFluid?.key;
  }

  get profile() {
    const { currentProfile } = this;
    if (currentProfile === AuthService.PROFILE_ALL) return null;
    return currentProfile;
  }

  get isSubHomeRoute() {
    const currentPath = this.router.url.split('?')[0];

    return ['/market-price'].includes(currentPath);
  }
}
