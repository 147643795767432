<ng-container *ngTemplateOutlet="this.selectedTmpl">
</ng-container>

<ng-template #gas >
  <div class="fluid-icon gas"
       [style.width]="icon.width"
       [style.height]="icon.height">
    <app-icon
      phosphorClass="ph-flame"
      [iconStyle]="Style.fill"
      [size]="size"
      [color]="Color.greyWhite"
    ></app-icon>
  </div>
</ng-template>

<ng-template #elec >
  <div class="fluid-icon elec"
     [style.width]="icon.width"
     [style.height]="icon.height">
    <app-icon
      phosphorClass="ph-lightning"
      [iconStyle]="Style.fill"
      [size]="size"
      [color]="Color.primary700"
    ></app-icon>
  </div>
</ng-template>
