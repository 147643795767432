import {
  AfterContentChecked,
  Directive, ElementRef, Host, HostBinding, Input,
} from '@angular/core';
import { ColumnDirective } from '@app/shared/components/atoms/table/directives/column.directive';
import { BaseTableComponent } from '@app/shared/components/atoms/table/base-table.component';

const defaultBoxShadow = '2px 1px 3px 0px rgba(0, 0, 0, 0.13)';

@Directive({
  selector: 'appSticky, th[appSticky], td[appSticky]',
})
export class StickyDirective implements AfterContentChecked {
  @HostBinding('style.position') position: string = 'sticky';

  @HostBinding('style.background-color') backgroundColor: string = 'inherit';

  @HostBinding('style.left') left?: string;

  @HostBinding('style.right') right?: string;

  @HostBinding('style.z-index') zIndex: number = 500;

  @HostBinding('style.box-shadow') boxShadow?: string;

  @Input() appSticky?: 'left' | 'right' | '';

  public readonly columnName: string;

  constructor(
    public el: ElementRef,
    @Host() column: ColumnDirective,
    @Host() private baseTable: BaseTableComponent<any>,
  ) {
    this.columnName = column.columnName;
  }

  get tableIsScrollable() {
    const { scrollWidth, clientWidth } = this.baseTable.tableContainer.nativeElement;
    return scrollWidth > clientWidth;
  }

  setRight(value: number) {
    this.right = `${value}px`;
  }

  setLeft(value: number) {
    this.left = `${value}px`;
  }

  addBoxShadow() {
    if (!this.baseTable.isLoading && this.tableIsScrollable) {
      this.boxShadow = `${this.isRightDirection() ? '-' : ''}${defaultBoxShadow}`;
    }
  }

  isRightDirection() {
    return this.appSticky === 'right';
  }

  ngAfterContentChecked(): void {
    if (!this.tableIsScrollable) this.boxShadow = undefined;
  }
}
