<dialog class="dialog" appBaseDialog #dialog id="rename-site-offer-dialog" [size]="DialogSize.MEDIUM" (cancelTrigger)="cancel()">
  <ng-template appDialogHeader>
    <span>Editer nom du site</span>
  </ng-template>

  <ng-template appDialogContent>
    <span [innerHTML]="MESSAGES.TXT145" ></span>
  </ng-template>

  <ng-template appDialogFooter>
    <app-button
      buttonId="cancel-btn"
      [style]="ButtonStyle.Outlined"
      (onclick)="cancel()"
      >Annuler</app-button>

    <app-button
      buttonId="confirm-button"
      (onclick)="confirm()"
      >Confirmer</app-button>
  </ng-template>
</dialog>
