import { DateTime } from 'luxon';
import {
  PowerCode,
  SiteDetailContractFeatures,
  SiteDetailContractFeaturesResponse,
  SiteOffer,
  SiteOfferDetails, SiteOfferDetailsResponse,
} from '@app/shared/interfaces/site.interface';
import { Pageable } from '@app/shared/interfaces/pagination.interface';

export function mapSiteOfferStatus(
  status: string,
  supplyStartDate: string,
  supplyEndDate: string,
  terminationDate: string,
) {
  let siteOfferStatus: string;
  const format = 'dd/MM/yyyy';
  const dateNow = DateTime.now().toUnixInteger();
  switch (status) {
    case 'SIGNEE': {
      siteOfferStatus = 'Signé';
      break;
    }
    case 'SIGNENR': {
      if (dateNow > DateTime.fromFormat(supplyStartDate, format).toUnixInteger()
      && dateNow < DateTime.fromFormat(supplyEndDate, format).toUnixInteger()) {
        if (terminationDate
          && terminationDate.length > 0
          && (
            DateTime
              .fromFormat(terminationDate, format)
              .toUnixInteger() > DateTime.now()
              .toUnixInteger())) {
          siteOfferStatus = 'En cours de résiliation';
        } else if (
          terminationDate && (DateTime
            .fromFormat(terminationDate, format)
            .toUnixInteger() <= DateTime.now()
            .toUnixInteger()
          )) {
          siteOfferStatus = 'Résilié';
        } else {
          siteOfferStatus = 'Actif';
        }
      } else if (dateNow < DateTime.fromFormat(supplyEndDate, format).toUnixInteger()) {
        siteOfferStatus = 'Signé';
      } else {
        siteOfferStatus = 'Résilié';
      }
      break;
    }
    case 'REFUSEE': {
      siteOfferStatus = 'Refusé';
      break;
    }
    case 'RETRACTEE': {
      siteOfferStatus = 'Invalidé';
      break;
    }
    case 'RESILIEE': {
      siteOfferStatus = 'Résilié';
      break;
    }
    case 'PERIMEE': {
      siteOfferStatus = 'Périmé';
      break;
    }
    default: {
      siteOfferStatus = 'Non défini';
      break;
    }
  }
  return siteOfferStatus;
}

export function mapCrmSitesOffers(
  {
    content, total, limit, offset,
  }: Pageable<SiteOffer>,
): Pageable<SiteOffer> {
  const sitesOffers: SiteOffer[] = [];
  content.forEach((siteOfferResponse: any) => {
    const siteOffer: SiteOffer = { ...siteOfferResponse };
    const { address } = siteOfferResponse.site;
    siteOffer.site.address.fullName = `${[address.line1, address.line2, address.line3, address.zipCode].filter(Boolean).join(', ')} ${address.city}`;
    siteOffer.status = mapSiteOfferStatus(
      siteOfferResponse.status,
      siteOffer.supplyStartDate,
      siteOffer.supplyEndDate,
      siteOffer.terminationDate,
    );
    sitesOffers.push(siteOffer);
  });
  return {
    content: sitesOffers,
    total,
    offset,
    limit: limit || total,
  };
}

export function mapCrmSiteOfferDetails(response: SiteOfferDetailsResponse) {
  const mappedSiteOfferDetails: SiteOfferDetails = JSON.parse(JSON.stringify(response));
  const { address } = mappedSiteOfferDetails.siteOffer.site;

  mappedSiteOfferDetails.holder.fullName = response?.holder?.legalStatus?.code !== 'SSOBJ'
    ? `${response?.holder?.legalStatus?.code} ${response?.holder?.companyName}`
    : response?.holder?.companyName;
  mappedSiteOfferDetails.siteOffer.site.address.fullName = `${[address.line1, address.line2, address.line3, address.zipCode]
    .filter(Boolean).join(', ')} ${address.city}`;
  return mappedSiteOfferDetails;
}

export function mapPowerList(response: SiteDetailContractFeaturesResponse) : SiteDetailContractFeatures {
  const siteDetailContractFeatures: SiteDetailContractFeatures = JSON.parse(JSON.stringify(response));
  const powerCodes = Object.values(PowerCode);

  siteDetailContractFeatures?.routingSubscribedPowerList.sort((a, b) => {
    const codeA = a.code as keyof typeof PowerCode;
    const codeB = b.code as keyof typeof PowerCode;
    return powerCodes.indexOf(PowerCode[codeA]) - powerCodes.indexOf(PowerCode[codeB]);
  });
  return siteDetailContractFeatures;
}
