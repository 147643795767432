export enum Typography {
  pageTitle = 'title-page',
  title2 = 'title-2',
  title3 = 'title-3',
  title4 = 'title-4',
  title5 = 'title-5',
  heading = 'heading',
  body1 = 'body-1',
  body2 = 'body-2',
  body3 = 'body-3',
  caption = 'caption',
  subcaption = 'subcaption',
  button1 = 'button-1',
  button2 = 'button-2',
  button3 = 'button-3',
}
