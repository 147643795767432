import { Component, Input } from '@angular/core';
import { Typography } from '@app/shared/models/typography';
import { FontWeight } from '@app/shared/models/font-weight';

@Component({
  selector: 'app-feature-not-exist-org',
  templateUrl: './feature-not-exist-org.component.html',
  styleUrls: ['./feature-not-exist-org.component.scss'],
})
export class FeatureNotExistOrgComponent {
  @Input() imageSrc: string = '';

  @Input() imageAlt: string = '';

  @Input() titleText: string = '';

  @Input() descriptionText: string = '';

  titleTypo: Typography = Typography.title3;

  titleWeight: FontWeight = FontWeight.semibold;

  descriptionTypo: Typography = Typography.body1;
}
