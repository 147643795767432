import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Style } from '@app/shared/components/atoms/icon/icon.component';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent {
  @Input() id?: string;

  @Input() cardTitle?: string;

  @Input() tooltipTitle?: string;

  @Input() iconName: string = '';

  @Input() enableClick: boolean = false;

  @Input() isContentClickable: boolean = false;

  @Output() visit = new EventEmitter<void>();

  get cardId(): string {
    const { cardTitle } = this;

    if (!cardTitle) return 'default-id';

    return cardTitle.toLowerCase()
      .split(' ')
      .join('-');
  }

  get cursor() {
    const { enableClick } = this;

    return enableClick ? 'pointer' : 'auto';
  }

  onClick() {
    this.visit.emit();
  }

  protected readonly Style = Style;
}
