export const API_CONTEXT = {
  IAM_CONTEXT: '/iam',
  BILLINGS_CONTEXT: '/billing-system',
  IRIS_CONTEXT: '/iris',
  GOUV_CONTEXT: '/gouv',
  CRM_CONTEXT: '/crm-btob',
  EDITION_CONTEXT: '/edition',
  SIGNALS_CONTEXT: '/rte',
  OPERA_CONTEXT: '/opera',
  MARKET_DATA_CONTEXT: '/market-data',
};

export const API_ROUTES = {
  PRICE: '/price',
  HEALTH_CHECK: '/healthcheck',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PROFILES: '/profiles',
  COLLABORATORS: '/profiles/{profileId}/company/{companyId}',
  INVOICES: '/invoices',
  INFORMATION_SUMMARIES: '/invoices/information-summaries',
  INVOICES_AMOUNT: '/invoices/amount',
  INVOICES_TOTAL_AMOUNT: '/invoices/total-amount',
  INVOICES_ASYNC: '/invoices-async',
  OFFERS: '/offers',
  SITES_OFFERS: '/sites-offers',
  SITES: '/sites',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/users/{email}/change-password',
  USERS: '/front/users',
  MANAGERS: '/managers',
  COMMERCIAL: '/commercial',
  CONTRACTS: '/contracts',
  INVOICING_MANAGER_TEAM: '/contracts/invoicing-manager-team',
  SIGNALS: '/signals',
  SIGNALS_HISTORY: '/signals/history',
  DOCUMENTS: '/documents',
  EXPORTS_INVOICING_DATA: '/exports/invoicing-data',
  PERIODIC_CONSUMPTIONS: '/consumptions',
  CURVE: '/curve',
  SAML_AUTH: '/saml-token',
  SEARCH_F300B_SERVICE: '/measures/{pointId}',
  START_DAILY_SUBSCRIPTION_SERVICE: '/measures/{pointId}/services',
  STOP_DAILY_SUBSCRIPTION_SERVICE: '/measures/{pointId}/services/{serviceId}',
  CONSUMPTION: '/histogram',
  STATISTICS: '/statistics',
  ROLES: '/front/roles',
  CREATE_ACCOUNT: '/accounts',
  CREATE_SILHOUETTE: '/silhouettes',
  SERVICES: '/services',
  PUBLIC_HOLIDAYS: '/public-holidays',
  EXPORTS_SITES_DATA: '/exports/sites',
  EXPORTS_HISTORY: '/download-history',
  EXPORTS_DOWNLOAD_URL: '/get-download-url',
  SURVEY: '/survey',
  INFO_NEWS: '/info-news',
  CURVES_EXPORT: '/curve/export',
};
