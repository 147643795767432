import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { Pageable } from '@app/shared/interfaces/pagination.interface';
import { SelectorTypeParams } from '@app/shared/components/organisms/selector/selector.component';
import {
  ButtonIcon,
  ButtonSize,
  ButtonStyle,
} from '@app/shared/components/molecules/buttons/button.type';
import { Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  itemLimitArray: SelectorTypeParams[] = [10, 20, 50, 100].map((x) => {
    const num = x.toString();
    return { title: num, value: num };
  });

  selectPages: Array<number> = [];

  _pageable: Pageable<any> = {
    offset: 0,
    limit: 10,
    total: 0,
    content: [],
  };

  @Input() disabled: boolean = false;

  @Output() pageableChange: EventEmitter<Pageable<any>> = new EventEmitter<Pageable<any>>();

  ngOnInit(): void {
    this.paginatorBuild();
  }

  changePage(page: number) {
    const { pageable } = this;

    if (!pageable) return;
    const { limit } = pageable;

    pageable.offset = page * limit;
    this.pageableChange.next(pageable);
  }

  changeLimit(limit: number) {
    const { pageable } = this;

    if (!pageable) return;

    pageable.offset = 0;
    pageable.limit = limit;
    this.pageableChange.next(pageable);
  }

  changeDisplayedItemNumber(limit: number) {
    this.changeLimit(limit);
  }

  get totalPage(): number {
    const { pageable } = this;
    if (!pageable) return 0;

    const { total, limit } = pageable;

    return Math.ceil(total / limit);
  }

  paginatorBuild() {
    const { pageable, totalPage } = this;

    if (!pageable) return;

    const { currentPage } = this;
    let from = currentPage;
    if (totalPage > 5) {
      if (from < 3) {
        from = 3;
      } else if (totalPage - from <= 2) {
        from = totalPage - 2;
      }
      this.selectPages = Array.from({ length: 3 }, (_, i) => i + from - 1);
    } else {
      this.selectPages = Array.from({ length: totalPage }, (_, i) => i + 1);
    }
  }

  get currentPage(): number {
    const { offset, limit } = this.pageable || {};

    return (offset / limit) + 1;
  }

  get resultViewed(): number {
    const { offset, content } = this.pageable || {};

    return offset + content.length;
  }

  @Input()
  set pageable(pageable: Pageable<any>) {
    // eslint-disable-next-line no-underscore-dangle
    this._pageable = pageable;
    this.paginatorBuild();
  }

  get pageable(): Pageable<any> {
    // eslint-disable-next-line no-underscore-dangle
    return this._pageable;
  }

  get rangeMax() {
    const { limit, offset, total } = this.pageable;
    return Math.min(limit + offset, total);
  }

  get rangeMin() {
    const { offset } = this.pageable;
    return offset + 1;
  }

  protected readonly Number = Number;

  protected readonly Style = Style;

  protected readonly Color = Color;

  protected readonly ButtonStyle = ButtonStyle;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonIcon = ButtonIcon;
}
