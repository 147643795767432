import {
  AfterViewInit, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild,
} from '@angular/core';
import { IconComponent, Size, Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';
import { CommonModule } from '@angular/common';
import { Fluid } from '../../../models/fluid.model';
import { NamedTmplRef } from '../../../interfaces/template.interfaxe';

export interface IconStyleParams {
  width?: string;
  height?: string;
}

@Component({
  selector: 'app-fluid-icons',
  templateUrl: './fluid-icons.component.html',
  styleUrls: ['./fluid-icons.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent],
})
export class FluidIconsComponent implements AfterViewInit {
  @Input() fluid?: Fluid = Fluid.ELEC;

  @Input() size?: Size = Size.small;

  @ViewChild('elec') private elecIconTmpl!: TemplateRef<object>;

  @ViewChild('gas') private gasIconTmpl!: TemplateRef<object>;

  private tmpls: Array<NamedTmplRef> = [];

  public selectedTmpl: TemplateRef<object> | null = null;

  private readonly DEFAULT_ICON_STYLE: IconStyleParams = {
    width: '2.5rem',
    height: '2.5rem',
  };

  @Input() style?: IconStyleParams;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngAfterViewInit() {
    this.tmpls = [
      { name: Fluid.ELEC.value, template: this.elecIconTmpl },
      { name: Fluid.GAZ.value, template: this.gasIconTmpl },
    ];
    this.selectedTmpl = this.tmpls.find((tmpl) => tmpl.name === this.fluid?.value)!.template;
    this.changeDetectorRef.detectChanges();
  }

  get icon(): IconStyleParams {
    const { style, DEFAULT_ICON_STYLE } = this;
    return Object.assign(DEFAULT_ICON_STYLE, style);
  }

  protected readonly Style = Style;

  protected readonly Size = Size;

  protected readonly Color = Color;
}
