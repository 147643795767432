<dialog appBaseDialog #dialog id="confirm-change-password-dialog" [size]="DialogSize.MEDIUM" (cancelTrigger)="cancel()">
  <ng-template appDialogHeader>
    <span>Modifier mon mot de passe</span>
  </ng-template>

  <ng-template appDialogContent>
    <p>Votre mot de passe a été modifié avec succès.</p>
    <p>Vous pouvez désormais vous connecter en utilisant votre nouveau mot de passe.</p>
  </ng-template>

  <ng-template appDialogFooter>
    <app-button
      buttonId="done-btn"
      (onclick)="cancel()"
    >Terminer</app-button>
  </ng-template>
</dialog>
