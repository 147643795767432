import { GranularityLabelEnum } from '@app/shared/interfaces/consumptions.interface';
import { Granularity } from '@app/consumptions/consumptions.interface';

export function convertFromkW(value: number, outputUnit: string) {
  switch (outputUnit) {
    case 'W':
    case 'Wh':
      return value * (10 ** 3);
    case 'kW':
    case 'kWh':
    case 'kVA':
      return value;
    case 'MW':
    case 'MWh':
      return value / (10 ** 3);
    default:
      throw new Error(`Unit ${outputUnit} Unknown`);
  }
}

export function convertFromW(value: number, outputUnit: string) {
  switch (outputUnit) {
    case 'W':
    case 'Wh':
      return value;
    case 'kW':
    case 'kWh':
    case 'kVA':
      return value / (10 ** 3);
    case 'MW':
    case 'MWh':
      return value / (10 ** 6);
    default:
      throw new Error(`Unit ${outputUnit} Unknown`);
  }
}

export function convertFromMW(value: number, outputUnit: string) {
  switch (outputUnit) {
    case 'W':
    case 'Wh':
      return value * (10 ** 6);
    case 'kW':
    case 'kWh':
    case 'kVA':
      return value * (10 ** 3);
    case 'MW':
    case 'MWh':
      return value;
    default:
      throw new Error(`Unit ${outputUnit} Unknown`);
  }
}

export function unitConversion(value: number, inputUnit: string, outputUnit: string) {
  switch (inputUnit) {
    case 'W':
    case 'Wh':
      return convertFromW(value, outputUnit);
    case 'kW':
    case 'kWh':
      return convertFromkW(value, outputUnit);
    case 'MW':
    case 'MWh':
      return convertFromMW(value, outputUnit);
    default:
      throw new Error(`Unit ${inputUnit} Unknown`);
  }
}

export function getGranularityLabelWithEnum(granularity: Granularity): string {
  switch (granularity) {
    case Granularity.HOUR:
      return GranularityLabelEnum.HOUR;
    case Granularity.DAY:
      return GranularityLabelEnum.DAY;
    case Granularity.WEEK:
      return GranularityLabelEnum.WEEK;
    case Granularity.MONTH:
      return GranularityLabelEnum.MONTH;
    case Granularity.YEAR:
      return GranularityLabelEnum.YEAR;
    default:
      throw new Error(`Granularity ${granularity} unknown`);
  }
}
