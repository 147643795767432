import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DecimalPipe, PercentPipe, registerLocaleData } from '@angular/common';
import localFr from '@angular/common/locales/fr';
import { SharedModule } from '@app/shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { LogoutInterceptor } from '@app/shared/auth/logout.interceptor';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './shared/auth/auth.interceptor';
import { ErrorInterceptor } from './shared/auth/error.interceptor';
import { InitTranslateProvider, InitUserProvider } from './shared/config/app.initializer';

registerLocaleData(localFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule.forRoot(),
  ],
  providers: [
    DecimalPipe,
    PercentPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LogoutInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr' },
    InitUserProvider, InitTranslateProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
