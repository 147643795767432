import {
  Directive, Input, OnDestroy, TemplateRef, ViewContainerRef,
} from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appHasAnyRight]',
})
export class HasAnyRightDirective implements OnDestroy {
  private authorities: string[] = [];

  private authenticationSubscription?: Subscription;

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) { }

  @Input()
  set appHasAnyRight(value: string | string[]) {
    this.authorities = typeof value === 'string' ? [value] : value;
    this.updateView();
    // Get notified each time authentication state changes.
    this.authenticationSubscription = this.authService
      .connectedUser$
      .subscribe(() => this.updateView());
  }

  ngOnDestroy(): void {
    if (this.authenticationSubscription) {
      this.authenticationSubscription.unsubscribe();
    }
  }

  private updateView(): void {
    const hasAnyAuthority = this.authService.hasAnyAuthority(this.authorities);
    this.viewContainerRef.clear();
    if (hasAnyAuthority) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
