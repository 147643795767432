<section class="container">
  <header>
    <ng-container #headerVcr></ng-container>
    <app-icon id="close-btn" phosphorClass="ph-x" class="close-button" (click)="cancel()"></app-icon>
  </header>

  <main>
    <ng-container #contentVcr></ng-container>
  </main>

  <footer [style.flex-direction]="buttonDirection">
    <ng-container #footerVcr></ng-container>
  </footer>
</section>
