<div
  class="basic-style"
>
  <ngx-skeleton-loader
    class="loader"
    [ngStyle]="{'width': width, 'height': height}"
    [count]="count"
    [appearance]="appearance"
    [theme]="{
    'margin': '0'
    }"
  >
  </ngx-skeleton-loader>
</div>
