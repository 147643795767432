import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { Size } from '@app/shared/components/atoms/link/link.enum';
import { Color } from '@app/shared/models/color';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule],
})
export class LinkComponent implements AfterViewInit {
  @Input() color: keyof typeof Color = 'grey800';

  @Input() size: keyof typeof Size = 'DEFAULT';

  @Input() redirectedLink?: string;

  @Input() linkId: string = '';

  @Input() isLoading: boolean = false;

  @Input() disabled: boolean = false;

  @Output() clickAction = new EventEmitter<void>();

  COLOR = Color;

  SIZE = Size;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef,
  ) { }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  onClickButton() {
    this.clickAction.emit();
  }

  protected readonly Object = Object;
}
