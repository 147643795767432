<div class="sbc-item-container" [style.flex-direction]="direction" [style.justify-content]="justifyContent" >
  <app-icon
    *ngIf="icon"
    phosphorClass="{{icon}}"
    [color]="Color.primary600"
  ></app-icon>
  <div class="label">
  <app-label *ngIf="label" [typography]="Typography.body3" [color]="Color.grey800"  [weight]="FontWeight.regular">{{label}}</app-label>
  <app-icon *ngIf="infoTip"
            phosphorClass="ph-info"
            [iconStyle]="Style.fill"
            id="info"
            [appTooltip]="infoTip"
            appTooltipPosition="right"
            [size]="Size.small"
  >
  </app-icon>
  </div>
  @if (value){
    <app-label *ngIf="!isLoading" [typography]="Typography.body2" [color]="Color.grey800"  [weight]="FontWeight.regular">{{value}}</app-label>
  } @else {
    <app-label *ngIf="!isLoading" [typography]="Typography.body2" [color]="Color.grey600"  [weight]="FontWeight.regular">{{ 'Non communiqué'}}</app-label>
  }
</div>

<ng-template #skeletonLoading>
  <ngx-skeleton-loader class="skeleton-loader" count="1"></ngx-skeleton-loader>
</ng-template>
