import { EnvEnum } from '@environments/environnement.interface';

export const environment = {
  production: false,
  local: false,
  demo: false,
  env: EnvEnum.test,
  CA_API_BASE_URL: 'https://api-mon-espace-pro.clients-test.aws.alterna-energie.fr',
  OLD_CUSTOMER_AREA_URL: 'https://test.alterna-energie.fr/',
  USER_SESSION_EXPIRY_DELAY_DAYS: 1,
  ZENDESK_SUPPORT_URL: 'https://aide-pro.alterna-energie.fr/hc/fr/requests/new',
};
