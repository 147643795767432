<div class="header-container">
  <div class="header-content">
    <app-fluid-toggle-switch *ngIf="fluidButtonVisibility" [type]="fluidSwitchType" [disabled]="fluidButtonDisabled || this.offersService.isNatureOfferLoading"
                             [currentFluid]="selectedFluid" (currentFluidChange)="onChangeFluid($event)" ></app-fluid-toggle-switch>


    <div class="us-container">
      <div class="primary-light-contrast-thm">
        <app-contact-button></app-contact-button>
      </div>

      <app-perimeter-block></app-perimeter-block>

      <app-identity-block></app-identity-block>
    </div>
  </div>
</div>
