import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Fluid } from '@app/shared/models/fluid.model';
import {
  FluidToggleSwitchTypeEnum,
} from '@app/shared/components/atoms/switch/toggle-fluid-switch/fluid-toggle-switch.enum';
import { IconComponent, Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-fluid-toggle-switch',
  templateUrl: './fluid-toggle-switch.component.html',
  styleUrls: ['./fluid-toggle-switch.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent],
})
export class FluidToggleSwitchComponent {
  Fluid = Fluid;

  FluidToggleSwitchTypeEnum = FluidToggleSwitchTypeEnum;

  elecIconClass: string = 'fill';

  gasIconClass: string = 'fill';

  @Input() type?: keyof typeof FluidToggleSwitchTypeEnum = 'TEXT_ICON';

  @Input() disabled: boolean = false;

  @Input() currentFluid?: Fluid;

  @Output() currentFluidChange = new EventEmitter<Fluid>();

  onToggle(fluid: Fluid) {
    this.currentFluid = fluid;
    this.currentFluidChange.emit(fluid);
  }

  handleMouseEvent(eventType: string, fluidType: Fluid) {
    if (fluidType === Fluid.ELEC) {
      this.elecIconClass = eventType === 'mouseenter' ? 'fill' : 'bold';
    } else if (fluidType === Fluid.GAZ) {
      this.gasIconClass = eventType === 'mouseenter' ? 'fill' : 'bold';
    }
  }

  isCurrentFluid(fluid: Fluid): boolean {
    return fluid === this.currentFluid;
  }

  protected readonly Style = Style;

  protected readonly Color = Color;
}
