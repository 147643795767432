<div @snackTrigger *ngIf="isShown" class="snack-container">
  <app-alert-card
    [type]="type"
    [title]="title"
    [description]="message"
    [styleOption]="{strip: true, iconCenterAlign: true}"
    [closeIcon]="true"
    (closeEvent)="isShown = false"
  ></app-alert-card>
</div>
