import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Size, Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';
import { TextfieldIconOption } from '@app/shared/components/atoms/textfield/textfield.interface';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
})
export class AutocompleteInputComponent {
  @Input() value!: string;

  @Input() placeholder?: string;

  @Input() prefixIcon?: TextfieldIconOption;

  @Input() styleIcon?: Style;

  @Input() errorMessage?: string;

  @Input() suggestions?: string[];

  @Input() hint?: string;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  isFocused: boolean = false;

  onChange(newValue: string) {
    this.valueChange.emit(newValue);
  }

  onFocus() {
    this.isFocused = true;
  }

  onFocusOut() {
    this.isFocused = false;
  }

  protected readonly Style = Style;

  protected readonly Size = Size;

  protected readonly Color = Color;
}
