import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumberFormat',
})
export class PhoneNumberFormatPipe implements PipeTransform {
  transform(number?: string) {
    if (number) {
      let verifyNumber = number.replaceAll(/\s/g, '');
      verifyNumber = verifyNumber.substring(0, 3) === '+33' ? `0${verifyNumber.substring(3, verifyNumber.length)}` : verifyNumber;

      const formatedNumber = verifyNumber.split('');

      formatedNumber.map((item: string, index: number) => {
        if (index % 2) formatedNumber[index] = `${item} `;
        return null;
      });

      return formatedNumber.join('');
    }
    return number;
  }
}
