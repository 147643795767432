<app-icon
  [color]="isChecked ? Color.positive : Color.negative"
  [size]="Size.small"
  [phosphorClass]="isChecked ? 'ph-check-circle' : 'ph-x-circle'"
></app-icon>
<app-label
  [typography]="Typography.body3"
  [weight]="FontWeight.regular"
  [color]="isChecked ? Color.positive: Color.negative"
>
  {{ text }}
</app-label>
