<div id="sidenav"
     [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
>
  <div id="fixed-container">
    <div id="extended-overlay" *ngIf="isMenuOpen"></div>
    <div id="navmenu" appClickAway (clickAway)="handleCloseMenu()">
      <div class="nav-list-content grey-thm" [class.menu-open]="isMenuOpen" (mouseenter)="handleOpenMenu()"
           (mouseleave)="handleCloseMenu()">
        <div class="menu-content">
          <div [class.menu-open]="isMenuOpen" class="menu-toggle">
            <div [ngSwitch]="isMenuOpen"
                 (click)="onMenuToggle($event)"
                 class="arrow-toggle"
            >
              <app-icon *ngSwitchCase="false" phosphorClass="ph-text-indent"></app-icon>
              <app-icon *ngSwitchCase="true" phosphorClass="ph-text-outdent"></app-icon>
            </div>
          </div>
          <div class="logo-display">
            <img [class.visible]="!isMenuOpen" id='little-alterna-image' alt="image"
                 src="{{CDN_SOREGIES_URL}}logos/ALTERNA/Normal/PNG/mini_logo alterna_vert.png"
                 [routerLinkActiveOptions]="{exact:true}"
                 routerLink="/"
                 routerLinkActive="active-list-item"
                 [queryParams]="{ profile }"
                 href="/"/>
            <img [class.visible]="isMenuOpen" id='big-alterna-image' alt="image"
                 src="{{CDN_SOREGIES_URL}}logos/ALTERNA/Normal/PNG/Logo alterna.png"
                 [routerLinkActiveOptions]="{exact:true}"
                 routerLink="/"
                 routerLinkActive="active-list-item"
                 [queryParams]="{ profile }"
                 href="/"/>
          </div>

          <ul id="menunav">
            <li
              [class.menu-close]="!isMenuOpen"
              [class.menu-open]="isMenuOpen"
            >
              <a
                [class.disabled]="disabled"
                [class.active-list-item]="isSubHomeRoute"
                class="menu-button"
                id="menu-home-button"
                [routerLinkActiveOptions]="{exact:true}"
                routerLink="/"
                routerLinkActive="active-list-item"
                #rla="routerLinkActive"
                [queryParams]="{ profile }"
                href="/"
                (click)="handleCloseMenu()"
                [appTooltipDisabled]="true"
                [appTooltip]="'Accueil'"
                [appTooltipPosition]="'right'"
              >
                <div class="icon-container">
                  <app-icon phosphorClass="ph-house"
                            [color]="rla.isActive ? Color.primary600 : Color.grey700"></app-icon>
                </div>
                <span [class.isVisible]="isMenuOpen">Accueil</span>
              </a>
            </li>
            <li
              [class.menu-close]="!isMenuOpen"
              [class.menu-open]="isMenuOpen"
              *appHasAnyRight="['read_offer']"
            >
              <a
                [class.disabled]="disabled"
                class="menu-button"
                id="menu-offers-button"
                routerLink="/offers"
                [queryParams]="{ fluid, profile }"
                [routerLinkActiveOptions]="isActiveOptions"
                routerLinkActive="active-list-item"
                #rla="routerLinkActive"
                href="/offers"
                [appTooltipDisabled]="true"
                [appTooltip]="'Mes contrats'"
                [appTooltipPosition]="'right'"
                (click)="handleCloseMenu()"
              >
                <div class="icon-container">
                  <app-icon phosphorClass="ph-files"
                            [color]="rla.isActive ? Color.primary600 : Color.grey700"></app-icon>
                </div>
                <span [class.isVisible]="isMenuOpen">Mes contrats</span>
              </a>
            </li>
            <li
              [class.menu-close]="!isMenuOpen"
              [class.menu-open]="isMenuOpen"
              *appHasAnyRight="['read_offer_site']"

            >
              <a
                [class.disabled]="disabled"
                class="menu-button"
                id="menu-sites-button"
                routerLink="/site-offers"
                [queryParams]="{ fluid, profile }"
                [routerLinkActiveOptions]="isActiveOptions"
                routerLinkActive="active-list-item"
                #rla="routerLinkActive"
                href="/site-offers"
                [appTooltipDisabled]="true"
                [appTooltip]="'Mes sites'"
                [appTooltipPosition]="'right'"
                (click)="handleCloseMenu()"
              >
                <div class="icon-container">
                  <app-icon phosphorClass="ph-buildings"
                            [color]="rla.isActive ? Color.primary600 : Color.grey700"></app-icon>
                </div>
                <span [class.isVisible]="isMenuOpen">Mes sites</span>
              </a>
            </li>
            <li
              [class.menu-close]="!isMenuOpen"
              [class.menu-open]="isMenuOpen"
              *appHasAnyRight="['read_offer_site_consumption_and_load_curve']"
            >
              <a
                [class.disabled]="disabled"
                class="menu-button"
                id="menu-consumption-button"
                routerLink="/consumptions"
                routerLinkActive="active-list-item"
                #rla="routerLinkActive"
                [queryParams]="{ fluid, profile }"
                [routerLinkActiveOptions]="isActiveOptions"
                href="/consumptions"
                [appTooltipDisabled]="true"
                [appTooltip]="'Mes consommations'"
                [appTooltipPosition]="'right'"
                (click)="handleCloseMenu()"
              >
                <div class="icon-container">
                  <app-icon phosphorClass="ph-chart-line-up"
                            [color]="rla.isActive ? Color.primary600 : Color.grey700"></app-icon>
                </div>
                <span [class.isVisible]="isMenuOpen">Mes consommations</span>
              </a>
            </li>
            <li
              [class.menu-close]="!isMenuOpen"
              [class.menu-open]="isMenuOpen"
              *appHasAnyRight="['read_invoice']"
            >
              <a
                [class.disabled]="disabled"
                class="menu-button"
                id="menu-invoices-button"
                routerLink="/invoices"
                [queryParams]="{ fluid, profile }"
                [routerLinkActiveOptions]="isActiveOptions"
                routerLinkActive="active-list-item"
                #rla="routerLinkActive"
                href="/invoices"
                [appTooltipDisabled]="true"
                [appTooltip]="'Mes factures'"
                [appTooltipPosition]="'right'"
                (click)="handleCloseMenu()"
              >
                <div class="icon-container">
                  <app-icon phosphorClass="ph-currency-eur"
                            [color]="rla.isActive ? Color.primary600 : Color.grey700"></app-icon>
                </div>
                <span [class.isVisible]="isMenuOpen">Mes factures</span>
              </a>
            </li>
            <li
              [class.menu-close]="!isMenuOpen"
              [class.menu-open]="isMenuOpen"
              *appHasAnyRight="['export_invoice_data']"
            >
              <a
                [class.disabled]="disabled"
                class="menu-button"
                id="menu-exports-button"
                routerLink="/exports"
                [queryParams]="{ fluid, profile }"
                [routerLinkActiveOptions]="isActiveOptions"
                routerLinkActive="active-list-item"
                #rla="routerLinkActive"
                href="/exports"
                [appTooltipDisabled]="true"
                [appTooltip]="'Mes exports'"
                [appTooltipPosition]="'right'"
                (click)="handleCloseMenu()"
              >
                <div class="icon-container">
                  <app-icon phosphorClass="ph-file-arrow-down"
                            [color]="rla.isActive ? Color.primary600 : Color.grey700"></app-icon>
                </div>
                <span [class.isVisible]="isMenuOpen">Mes exports</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

