import { Pipe, PipeTransform } from '@angular/core';
import { unitConversion } from '@app/shared/utils/unit-convertion';
import { UnitTypes } from '@app/shared/interfaces/consumptions.interface';

@Pipe({
  name: 'kwhToMwhConversion',
})
export class KwhToMwhConversionPipe implements PipeTransform {
  transform(value: number): number {
    return unitConversion(value, UnitTypes.kWh, UnitTypes.MWh);
  }
}
