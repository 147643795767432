import { Component, Input } from '@angular/core';
import { NgxSkeletonLoaderConfig, NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-skeleton-loader',
  templateUrl: './custom-skeleton-loader.component.html',
  styleUrls: ['./custom-skeleton-loader.component.scss'],
  standalone: true,
  imports: [NgxSkeletonLoaderModule, CommonModule],
})
export class CustomSkeletonLoaderComponent {
  @Input() appearance: NgxSkeletonLoaderConfig['appearance'] = '';

  @Input() count: NgxSkeletonLoaderConfig['count'] = 1;

  @Input() width: string = '10rem';

  @Input() height: string = '3rem';
}
