import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import { DateTime, Interval, WeekdayNumbers } from 'luxon';
import { IconComponent, Size, Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { ButtonStyle } from '@app/shared/components/molecules/buttons/button.type';
import { ButtonComponent } from '@app/shared/components/molecules/buttons/button.component';
import { PipeModule } from '@app/shared/modules/pipe.module';
import { TooltipComponent } from '@app/shared/components/atoms/tooltip/tooltip.component';
import { TooltipDirective } from '@app/shared/components/atoms/tooltip/tooltip.directive';
import { DateInterval } from '@app/shared/interfaces/date.interface';
import { PreselectedRange } from './rangepicker.type';
import { CoverSpacing, ExpansionPanelComponent } from '../molecules/expansion-panel/expansion-panel.component';

@Component({
  standalone: true,
  selector: 'app-rangepicker',
  templateUrl: './rangepicker.component.html',
  styleUrls: ['./rangepicker.component.scss'],
  imports: [
    IconComponent,
    NgClass,
    NgIf,
    NgForOf,
    ButtonComponent,
    PipeModule,
    TooltipComponent,
    TooltipDirective,
    ExpansionPanelComponent,
  ],
})
export class RangepickerComponent implements OnInit, OnChanges {
  @Input() rangepickerId?: string = '';

  @Input() maxRangeMonths?: number;

  @Input() maxRangeMonthsExceededMessage?: string;

  @Input() dateMin?: DateTime;

  @Input() dateMax?: DateTime;

  @Input() preselectedRanges?: PreselectedRange[] = [];

  @Input() disabled?: boolean = false;

  @Input() startDate?: DateTime;

  @Input() endDate?: DateTime;

  @Input() displayHours?: boolean = false;

  @Output() periodChange: EventEmitter<DateInterval> = new EventEmitter<DateInterval>();

  @Output() preselectedRangeChange: EventEmitter<PreselectedRange> = new EventEmitter<PreselectedRange>();

  @ViewChild(ExpansionPanelComponent) protected expansionPanelRef!: ExpansionPanelComponent;

  daysOfWeek: string[] = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

  monthOfYear: string[] = ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUIN', 'JUI', 'AOU', 'SEP', 'OCT', 'NOV', 'DEC'];

  selectedPreselectedRange: PreselectedRange | undefined = undefined;

  previousSelectedPreselectedRange: PreselectedRange | undefined = undefined;

  displayedMoisDeLAnnee: string[][] = this.calcSelectableMonths();

  selectedRightMonth: DateTime = DateTime.now().startOf('month');

  selectedLeftMonth: DateTime = this.selectedRightMonth.minus({ month: 1 });

  leftDates: Array<DateTime | undefined>[] = [];

  rightDates: Array<DateTime | undefined>[] = [];

  selectedFirstDate?: DateTime;

  selectedLastDate?: DateTime;

  displayLeftMonthSelector: boolean = false;

  displayRightMonthSelector: boolean = false;

  leftSelectableYears: number[][] = this.calcSelectableYears(DateTime.now().year);

  rightSelectableYears: number[][] = this.calcSelectableYears(DateTime.now().year);

  selectedLeftYear?: number;

  selectedRightYear?: number;

  coverSpacing: keyof typeof CoverSpacing = 'spaceBetween';

  hoverDate?: DateTime;

  ngOnInit() {
    this.initValues();
  }

  initValues(): void {
    this.selectedFirstDate = this.startDate;
    this.selectedLastDate = this.endDate;

    if (this.previousSelectedPreselectedRange) {
      this.selectedPreselectedRange = this.previousSelectedPreselectedRange;
    }

    const leftMonth = this.startDate
      ? DateTime.local(this.startDate.year, this.startDate.month, this.startDate.day)
      : undefined;
    const rightMonth = this.endDate
      ? DateTime.local(this.endDate.year, this.endDate.month, this.endDate.day)
      : undefined;
    if (leftMonth) {
      this.selectedLeftMonth = leftMonth.startOf('month');
    }
    if (rightMonth && leftMonth && rightMonth > leftMonth) {
      this.selectedRightMonth = rightMonth.startOf('month');
    }
    this.leftDates = this.calcCalendar(this.selectedLeftMonth);
    this.rightDates = this.calcCalendar(this.selectedRightMonth);
    if (this.isStartAndEndDateOnSameMonth()) {
      this.changeRightMonth(this.selectedRightMonth.plus({ month: 1 }));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startDate || changes.endDate) {
      if (changes.startDate.currentValue) {
        this.selectedLeftMonth = changes.startDate.currentValue;
        this.selectedFirstDate = changes.startDate.currentValue;
      }
      if (changes.endDate.currentValue) {
        this.selectedLastDate = changes.endDate.currentValue;
        this.selectedRightMonth = changes.endDate.currentValue;
        if (this.isStartAndEndDateOnSameMonth()) {
          this.changeRightMonth(this.selectedRightMonth.plus({ month: 1 }));
        }
      }
      this.leftDates = this.calcCalendar(this.selectedLeftMonth);
      this.rightDates = this.calcCalendar(this.selectedRightMonth);
      this.selectedPreselectedRange = undefined;
    }
    if (changes.preselectedRanges) {
      const defaultPreselectedRange = changes.preselectedRanges.currentValue
        .find((preselectedRange: PreselectedRange) => preselectedRange.default);
      this.previousSelectedPreselectedRange = defaultPreselectedRange;
      this.selectPreselectedRange(defaultPreselectedRange);
    }
  }

  private isStartAndEndDateOnSameMonth() {
    const {
      selectedFirstDate, selectedLastDate,
    } = this;

    if (!(selectedFirstDate && selectedLastDate)) return false;
    const isSameMonth = selectedLastDate.month === selectedFirstDate.month;

    return isSameMonth && selectedLastDate.diff(selectedFirstDate, 'month').months < 1;
  }

  selectDate(clickedDate: DateTime | undefined) {
    if (!clickedDate) return;

    if (this.selectedFirstDate && this.selectedLastDate) {
      this.selectedFirstDate = clickedDate;
      this.selectedLastDate = undefined;
    } else if (this.selectedFirstDate) {
      if (this.selectedFirstDate > clickedDate) {
        this.selectedLastDate = this.selectedFirstDate;
        this.selectedFirstDate = clickedDate;
      } else {
        this.selectedLastDate = clickedDate;
      }
    } else {
      this.selectedFirstDate = clickedDate;
    }
    this.selectedPreselectedRange = undefined;
  }

  selectLeftYear(clickedYear: number) {
    if (this.isLeftYearDisabled(clickedYear)) return;
    this.selectedLeftYear = clickedYear;
  }

  selectRightYear(clickedYear: number) {
    if (this.isRightYearDisabled(clickedYear)) return;
    this.selectedRightYear = clickedYear;
  }

  selectLeftMonth(clickedMonth: string) {
    if (!this.selectedLeftYear) return;
    if (this.isLeftMonthDisabled(clickedMonth)) return;
    const selectedMonth: number = this.monthOfYear.indexOf(clickedMonth) + 1;
    const calendarDate: DateTime = DateTime.local(this.selectedLeftYear, selectedMonth);
    this.changeLeftMonth(calendarDate);
    this.selectedLeftYear = undefined;
    this.displayLeftMonthSelector = false;
  }

  selectRightMonth(clickedMonth: string) {
    if (!this.selectedRightYear) return;
    const selectedMonth: number = this.monthOfYear.indexOf(clickedMonth) + 1;
    const calendarDate: DateTime = DateTime.local(this.selectedRightYear, selectedMonth);
    this.changeRightMonth(calendarDate);
    this.selectedRightYear = undefined;
    this.displayRightMonthSelector = false;
  }

  toggleDisplayLeftMonthSelector() {
    this.selectedLeftYear = undefined;
    this.displayLeftMonthSelector = !this.displayLeftMonthSelector;
  }

  toggleDisplayRightMonthSelector() {
    this.selectedRightYear = undefined;
    this.displayRightMonthSelector = !this.displayRightMonthSelector;
  }

  nextLeftMonth() {
    this.changeLeftMonth(this.selectedLeftMonth.plus({ month: 1 }));
  }

  previousLeftMonth() {
    this.changeLeftMonth(this.selectedLeftMonth.minus({ month: 1 }));
  }

  nextRightMonth() {
    this.changeRightMonth(this.selectedRightMonth.plus({ month: 1 }));
  }

  previousRightMonth() {
    this.changeRightMonth(this.selectedRightMonth.minus({ month: 1 }));
  }

  applyChanges() {
    if (!this.selectedFirstDate || !this.selectedLastDate || this.selectedFirstDate > this.selectedLastDate) return;
    this.periodChange.emit({
      startDate: this.selectedFirstDate,
      endDate: this.selectedLastDate,
    });
    this.previousSelectedPreselectedRange = this.selectedPreselectedRange;
    this.expansionPanelRef.hide();
  }

  cancelChanges() {
    this.resetState();
    this.selectedFirstDate = this.startDate;
    this.selectedLastDate = this.endDate;
    this.selectedPreselectedRange = this.previousSelectedPreselectedRange;
    this.expansionPanelRef.hide();
  }

  isMaxRangeMonthsExceeded(): boolean {
    if (!this.selectedFirstDate || !this.selectedLastDate || !this.maxRangeMonths) return false;
    const interval = Interval.fromDateTimes(this.selectedFirstDate, this.selectedLastDate);
    return interval.toDuration('months').months > this.maxRangeMonths;
  }

  selectPreselectedRange(selectedPreselectedRange: PreselectedRange | undefined) {
    if (!selectedPreselectedRange) {
      this.selectedPreselectedRange = undefined;
      this.preselectedRangeChange.emit(undefined);
      return;
    }
    const { startDate, endDate } = selectedPreselectedRange.callback();

    if (!startDate || !endDate) return;

    if ((this.dateMax && (endDate > this.dateMax || startDate > this.dateMax))
      || (this.dateMin && (endDate < this.dateMin || startDate < this.dateMin))) return;

    this.selectedLastDate = endDate;
    this.selectedFirstDate = startDate;
    this.changeRightMonth(this.selectedLastDate);
    this.changeLeftMonth(this.selectedFirstDate);
    this.selectedPreselectedRange = selectedPreselectedRange;
    this.preselectedRangeChange.emit(selectedPreselectedRange);
    this.displayLeftMonthSelector = false;
    this.displayRightMonthSelector = false;
  }

  protected isDateInValidRange(date: DateTime, scope: 'year' | 'month' | 'day') {
    const { dateMin, dateMax } = this;

    if (!(dateMin && dateMax)) return true;

    return Interval.fromDateTimes(dateMin.startOf(scope), dateMax.endOf(scope).plus({ day: 1 }))
      .contains(date);
  }

  protected isLeftMonthDisabled(leftMonth: string): boolean {
    const {
      selectedLeftYear,
      selectedRightMonth, monthOfYear,
    } = this;
    if (!selectedLeftYear) {
      return false;
    }
    const monthNumber = monthOfYear.indexOf(leftMonth) + 1;
    const leftDate = DateTime.local(selectedLeftYear, monthNumber).endOf('month');
    if (!this.isDateInValidRange(leftDate, 'month')) return true;
    return leftDate >= selectedRightMonth.endOf('month');
  }

  protected isRightMonthDisabled(rightMonth: string): boolean {
    const {
      selectedRightYear,
      monthOfYear, selectedLeftMonth,
    } = this;
    if (!selectedRightYear) {
      return false;
    }
    const monthNumber = monthOfYear.indexOf(rightMonth) + 1;
    const rightDate = DateTime.local(selectedRightYear, monthNumber).endOf('month');
    if (!this.isDateInValidRange(rightDate, 'month')) return true;
    return rightDate <= selectedLeftMonth.endOf('month');
  }

  protected isLeftYearDisabled(leftYear: number): boolean {
    const leftDate = DateTime.local(leftYear).startOf('year');

    if (!this.isDateInValidRange(leftDate, 'year')) return true;

    return leftDate >= this.selectedRightMonth.startOf('month');
  }

  protected isRightYearDisabled(rightYear: number): boolean {
    const rightDate = DateTime.local(rightYear).startOf('year');

    if (!this.isDateInValidRange(rightDate, 'year')) return true;

    return DateTime.local(rightYear).endOf('year') <= this.selectedLeftMonth.endOf('month');
  }

  private changeLeftMonth(newMonth: DateTime) {
    this.selectedLeftMonth = newMonth.startOf('month');
    this.leftDates = this.calcCalendar(this.selectedLeftMonth);
  }

  private changeRightMonth(newMonth: DateTime) {
    this.selectedRightMonth = newMonth.startOf('month');
    this.rightDates = this.calcCalendar(this.selectedRightMonth);
  }

  private calcCalendar(month: DateTime): Array<DateTime | undefined>[] {
    const firstDayOfMonth: DateTime = DateTime.local(month.year, month.month, 1);
    const lastDayOfMonth: DateTime = DateTime.local(
      month.year,
      month.month,
      month.daysInMonth,
    );
    const firstWeekdayOfMonth: WeekdayNumbers = firstDayOfMonth.weekday;

    const interval = Interval.fromDateTimes(firstDayOfMonth, lastDayOfMonth.plus({ day: 1 }));
    const arrayOfDates = interval.splitBy({ day: 1 }).map((d) => d.start);

    const displayedArray: Array<DateTime | undefined>[] = [new Array(firstWeekdayOfMonth - 1)];

    displayedArray[0] = displayedArray[0].concat(arrayOfDates.slice(0, 8 - firstWeekdayOfMonth));

    for (let i = 8 - firstWeekdayOfMonth; i < arrayOfDates.length; i += 7) {
      if (displayedArray.length <= 0) {
        displayedArray.push(arrayOfDates.slice(i, i + (7 - arrayOfDates[0].weekday)));
      }
      displayedArray.push(arrayOfDates.slice(i, i + 7));
    }

    return displayedArray;
  }

  private calcSelectableMonths(): string[][] {
    const displayedMonthArray: string[][] = [];
    for (let i = 0; i < this.monthOfYear.length; i += 4) {
      displayedMonthArray.push(this.monthOfYear.slice(i, i + 4));
    }
    return displayedMonthArray;
  }

  private calcSelectableYears(lastYear: number): number[][] {
    const yearArray: number[] = [];
    for (let currYear = lastYear; currYear > lastYear - 20; currYear -= 1) {
      yearArray.unshift(currYear);
    }

    const displayedYearArray: number[][] = [];

    for (let i = 0; i < yearArray.length; i += 4) {
      displayedYearArray.push(yearArray.slice(i, i + 4));
    }

    return displayedYearArray;
  }

  isDateInSelectedRange(date: DateTime | undefined): boolean {
    if (!date || !this.selectedFirstDate) return false;
    if (this.selectedFirstDate && this.selectedLastDate) {
      return date > this.selectedFirstDate && date < this.selectedLastDate;
    }
    if (this.hoverDate && this.selectedFirstDate) {
      return (date < this.selectedFirstDate && date > this.hoverDate)
        || (date > this.selectedFirstDate && date < this.hoverDate);
    }
    return false;
  }

  isRightDayOutOfScope(date: DateTime | undefined): boolean {
    const { dateMin, dateMax } = this;
    return !!(!date || ((dateMin && date < dateMin) || (dateMax && date > dateMax)));
  }

  isRightMonthOutOfScope(monthShort: string): boolean {
    const {
      dateMin, dateMax, monthOfYear, selectedRightYear,
    } = this;
    const curMonthNumber = monthOfYear.indexOf(monthShort) + 1;

    return !!(dateMax && dateMax.year === selectedRightYear && curMonthNumber > dateMax?.month)
      || !!(dateMin && dateMin.year === selectedRightYear && curMonthNumber < dateMin?.month);
  }

  setHoverDate(date: DateTime | undefined) {
    if (!date) return;
    this.hoverDate = date;
  }

  resetState() {
    this.selectedRightMonth = DateTime.now().startOf('month');
    this.selectedLeftMonth = this.selectedRightMonth.minus({ month: 1 });
    this.leftSelectableYears = this.calcSelectableYears(DateTime.now().year);
    this.rightSelectableYears = this.calcSelectableYears(DateTime.now().year);
    this.displayLeftMonthSelector = false;
    this.displayRightMonthSelector = false;
  }

  resetValues() {
    this.resetState();
    this.initValues();
  }

  get coverText(): string {
    if (!this.startDate || !this.endDate) return 'Sélectionner la période';
    return `${this.startDate.toFormat('dd/MM/yyyy')} - ${this.endDate.toFormat('dd/MM/yyyy')}`;
  }

  protected readonly Style = Style;

  protected readonly Size = Size;

  protected readonly Color = Color;

  protected readonly ButtonStyle = ButtonStyle;
}
