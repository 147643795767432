import {
  Directive, ElementRef, Output, EventEmitter, HostListener,
} from '@angular/core';

@Directive({
  selector: '[appClickAway]',
})
export class ClickAwayDirective {
  @Output() clickAway = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: any) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickAway.emit();
    }
  }
}
