import {
  AfterViewChecked,
  Component,
  ElementRef,
  Host,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DateTime } from 'luxon';
import { BaseTableComponent } from '@app/shared/components/atoms/table/base-table.component';
import {
  distinct, ReplaySubject, Subject, takeUntil,
} from 'rxjs';
import { Color } from '@app/shared/models/color';
import {
  Overlay, OverlayConfig, OverlayRef,
} from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { ColumnHeaderDirective } from '@app/shared/components/atoms/table/directives/column-header.directive';
import { IconComponent, Size } from '@app/shared/components/atoms/icon/icon.component';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from '@app/shared/components/checkbox/checkbox.component';
import { BadgeNotificationComponent } from '../../badge-notification/badge-notification.component';

interface FilterItem {
  name: string;
  count: number;
  checked: boolean;
}

@Component({
  selector: 'app-column-header',
  templateUrl: './column-header.component.html',
  styleUrls: ['./column-header.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent, BadgeNotificationComponent, CheckboxComponent, CdkPortal],
})
export class ColumnHeaderComponent implements OnInit, AfterViewChecked, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  @Input() name?: string;

  @Input() sort?: 'asc' | 'dec';

  /*
    This field must be populated when a type is present
    It must contain the path to the field to sort or filter
   */
  @Input() field? = 'value';

  @Input() type?: 'filter' | 'sort';

  @HostBinding('style.float') float?: 'right' | 'left';

  @ViewChild('connectedEl') protected originEl!: ElementRef;

  @ViewChild(CdkPortal) protected contentTemplate2!: CdkPortal;

  private overlayRef!: OverlayRef;

  icon?: string;

  lastModifiedDate = 0;

  filterItems: FilterItem[] = [];

  private hover: boolean = false;

  private $source = new ReplaySubject(1);

  constructor(
  @Host() directive: ColumnHeaderDirective,
    @Host() private parent: BaseTableComponent<any>,
    private overlay: Overlay,
  ) {
    this.float = directive.textAlign === 'end' ? 'right' : 'left';
  }

  ngOnInit(): void {
    const { type } = this;
    this.updateIcon();

    if (this.sort) {
      this.toSort();
    }
    this.initItemFilters();

    if (type !== 'filter') return;

    this.$source
      .pipe(
        takeUntil(this.unsubscribe$),
        distinct(),
      ).subscribe({
        next: () => {
          this.updateIcon();
          this.initItemFilters();
        },
      });
  }

  private initItemFilters() {
    const { field, type } = this;

    if (type !== 'filter' || !field) return;

    this.filterItems = this.parent.source
      .map((item) => {
        const name = this.searchFieldValue(item, field!);
        return { name };
      }).reduce((acc: FilterItem[], { name }) => {
        const item = acc.find(({ name: itemName }) => itemName === name);
        if (item) item.count += 1;
        else acc.push({ name, count: 1, checked: false });
        return acc;
      }, []);
  }

  updateCount() {
    const { parent, name } = this;
    const { headerFilters } = parent;
    let { source } = parent;

    headerFilters
      .filter(({ type }) => type === 'filter')
      .filter((filter) => filter.name !== name)
      .forEach(({ field, values }) => {
        source = source.filter((item) => {
          if (!values || !values.length) return true;

          const value = this.searchFieldValue(item, field!);
          return values?.includes(value);
        });
      });

    const names = source!.map((item) => this.searchFieldValue(item, this.field!));

    this.filterItems.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.count = names.filter((elName) => elName === item.name).length;
    });
  }

  updateIcon() {
    const {
      type, sort, activeFilters,
    } = this;

    const { length: containsFilter } = activeFilters;
    switch (type) {
      case 'filter':
        this.icon = `ph-funnel ${containsFilter > 0 && 'active'}`;
        break;
      case 'sort':
        if (!sort) this.icon = 'ph-arrows-down-up';
        else if (sort === 'asc') this.icon = 'ph-arrow-up active';
        else this.icon = 'ph-arrow-down active';
        break;
      default:
        break;
    }
  }

  toSort() {
    this.updateIcon();
    this.lastModifiedDate = DateTime.now().toMillis();
  }

  switchSort() {
    const { sort } = this;

    this.sort = (sort === 'asc') ? 'dec' : 'asc';
    this.toSort();

    return this.sort;
  }

  extractOptions() {
    const {
      name, sort,
      field, type, lastModifiedDate,
      activeFilters: values,
    } = this;

    return {
      name,
      sort,
      field,
      type,
      lastModifiedDate,
      values,
    };
  }

  removeSort() {
    this.sort = undefined;
    this.updateIcon();

    return this.sort;
  }

  private searchFieldValue(obj: any, path: string) {
    let current = obj;
    path.split('.').forEach((p) => { current = current[p]; });
    return current;
  }

  onFilterItemClick({ name, checked }: FilterItem) {
    this.filterItems.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      if (item.name === name) item.checked = !checked;
    });
    this.updateIcon();
    this.lastModifiedDate = DateTime.now().toMillis();
  }

  showDropdown(): void {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.contentTemplate2);
    this.overlayRef.backdropClick().subscribe(() => this.hide());
  }

  hide(): void {
    this.overlayRef.detach();
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.originEl.nativeElement)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ]);

    const scrollStrategy = this.overlay.scrollStrategies.close();
    return new OverlayConfig({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });
  }

  private get activeFilters(): string[] {
    const { filterItems } = this;

    return filterItems
      .filter(({ checked }) => checked)
      .map(({ name }) => name);
  }

  protected get activeFiltersNumber(): number {
    return this.activeFilters.length;
  }

  get showIcon() {
    const {
      icon, type,
      filterItems, parent,
    } = this;

    if (parent.isLoading || !parent.source.length) return false;
    if (type === 'filter' && filterItems.length === 0) return false;

    return icon !== undefined;
  }

  get isActive() {
    const { sort, activeFilters } = this;
    if (sort && ['asc', 'dec'].includes(sort)) return true;
    return activeFilters.length > 0;
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.hover = true;
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.hover = false;
  }

  get iconColor() {
    const { hover } = this;
    const {
      primary400, primary500,
      grey500,
    } = Color;

    if (hover) return this.isActive ? primary400 : primary500;

    return this.isActive ? primary400 : grey500;
  }

  ngAfterViewChecked(): void {
    const {
      parent, type, $source,
    } = this;
    const { source } = parent;

    if (type !== 'filter') return;

    $source.next(source);
  }

  ngOnDestroy(): void {
    this.$source.complete();
    this.unsubscribe$.next(true);
  }

  protected readonly Color = Color;

  protected readonly Size = Size;

  protected readonly Number = Number;
}
