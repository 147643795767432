<div class="feature-not-exist-org-main-container">
  <app-standard-image [src]="imageSrc" [alt]="imageAlt"></app-standard-image>
  <div class="text-container">
    <app-label [typography]="titleTypo" [weight]="titleWeight">
      {{titleText}}
    </app-label>
    <app-label [typography]="descriptionTypo">
      {{descriptionText}}
    </app-label>
  </div>
</div>
