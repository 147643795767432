import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Typography } from '@app/shared/models/typography';
import { FontWeight } from '@app/shared/models/font-weight';
import { Color } from '@app/shared/models/color';
import { IconComponent, Style } from '@app/shared/components/atoms/icon/icon.component';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from '../tooltip/tooltip.directive';
import { LabelComponent } from '../text/label/label.component';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent, TooltipDirective, LabelComponent],
})
export class SwitchComponent {
  @Input() label: string = '';

  @Input() checked?: boolean = false;

  @Input() disabled?: boolean = false;

  @Input() tooltip?: string;

  @Output() valueHasChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected readonly Typography = Typography;

  protected readonly FontWeight = FontWeight;

  protected readonly Color = Color;

  toggleChecked() {
    this.checked = !this.checked;
    this.valueHasChanged.emit(this.checked);
  }

  protected readonly Style = Style;
}
