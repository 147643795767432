import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  private email = '';

  constructor(private authService: AuthService) {
    this.authService.connectedUser$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        if (user) this.email = user.email;
      });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const { email } = this;
    const idToken = this.authService.getToken();
    if (idToken) {
      const cloned = request.clone({
        headers: request.headers.set('Authorization', idToken),
        params: request.params.set('email', email),
      });

      return next.handle(cloned);
    }
    return next.handle(request);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
