import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Size, Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';
import { FontWeight } from '@app/shared/models/font-weight';
import { Typography } from '@app/shared/models/typography';
import { ButtonIcon, ButtonSize, ButtonStyle } from '@app/shared/components/molecules/buttons/button.type';

@Component({
  selector: 'app-main-information',
  templateUrl: './main-information.component.html',
  styleUrls: ['./main-information.component.scss'],
})
export class MainInformationComponent {
  @Input() loading: boolean = false;

  @Input() title: string = '';

  @Input() value?: string = '';

  @Input() redirectUrl?: string;

  @Input() edited?: boolean;

  @Input() maxLenght?: number;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() isEditingOnError?: boolean = false;

  constructor(private router: Router) {
  }

  redirectTo() {
    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl], { queryParamsHandling: 'preserve' });
    }
  }

  editOnChange(newValue: string) {
    this.valueChange.emit(newValue);
  }

  protected readonly Style = Style;

  protected readonly Size = Size;

  protected readonly Color = Color;

  protected readonly FontWeight = FontWeight;

  protected readonly Typography = Typography;

  protected readonly ButtonStyle = ButtonStyle;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonIcon = ButtonIcon;
}
