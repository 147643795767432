import {
  Component, EventEmitter, Input, Output, forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TextfieldComponent } from '@app/shared/components/atoms/textfield/textfield.component';
import { TextfieldIconOption } from '@app/shared/components/atoms/textfield/textfield.interface';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [TextfieldComponent],
})
export class FormInputComponent implements ControlValueAccessor {
  @Input() type: 'text' | 'password' | 'email' = 'text';

  @Input() title?: string;

  @Input() placeholder?: string;

  @Input() hint?: string;

  @Input() prefixIcon?: TextfieldIconOption;

  @Input() clearSuffixButton?: boolean = false;

  @Input() isOnError?: boolean = false;

  @Input() errorMessage?: string;

  @Input() autocomplete?: string;

  @Input() maxLength?: number = 999999;

  @Input() focusOnView: boolean = false;

  @Output() inputFocus = new EventEmitter<void>();

  @Output() inputFocusOut = new EventEmitter<void>();

  value: string = '';

  disabled = false;

  onChange: any = () => { };

  onTouched: any = () => { };

  valueChange(value: string) {
    this.value = value;
    this.onChange(value);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClear() {
    this.value = '';
    this.onChange('');
  }

  onFocus() {
    this.inputFocus.emit();
  }

  onFocusOut() {
    this.inputFocusOut.emit();
    this.onTouched();
  }
}
