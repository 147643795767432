import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Segment } from '@app/shared/models/segment';

@Pipe({
  name: 'loadCurveNumber',
})
export class LoadCurveNumberPipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe,
  ) {
  }

  transform(value?: string | number, segment?: Segment | string) {
    switch (segment) {
      case 'C5':
        return this.decimalPipe.transform(value, '1.0-2', 'fr-FR');
      case 'C1':
      case 'C4':
      case 'C2':
      case 'C3':
        return this.decimalPipe.transform(value, '1.0-0', 'fr-FR');
      default:
        throw new Error(`Unknown segment ${segment}`);
    }
  }
}
