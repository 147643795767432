<app-expansion-panel [expansionPanelId]="'identity-expansion-panel'"
                     [coverText]="civilityNamesGroup" iconClassName="ph-user"
>
  <ng-container ngProjectAs="[dropdown-content]">
    <div class="content">
      <a href="/account" routerLink="/account" [queryParams]="{ profile }">
        <div id="personal-info" (click)="displayPersonalInfo()">
          <span>Informations personnelles</span>
          <app-icon phosphorClass="ph-caret-right"></app-icon>
        </div>
      </a>

      <app-button
        buttonId="logout-btn"
        [style]="ButtonStyle.Primary"
        [spacing]="ButtonSpacing.FullSpacing"
        [iconOptions]="{phosphorClass: 'ph-sign-out', position: ButtonIcon.LeftIcon}"
        (onclick)="logout()"
      >
        Déconnexion
      </app-button>
    </div>
  </ng-container>
</app-expansion-panel>
