import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { TranslateService } from '@app/shared/translate/translate.service';

export function initializeUser(authService: AuthService) {
  return () => authService.initUser();
}

export function initializeI18n(translateService: TranslateService) {
  return () => translateService.initTranslation();
}

export const InitUserProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeUser,
  deps: [AuthService],
  multi: true,
};

export const InitTranslateProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeI18n,
  deps: [TranslateService],
  multi: true,
};
