import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ProfileDetails } from '@app/shared/interfaces/user-response.interface';
import { AuthService } from '@app/shared/auth/auth.service';
import { Router } from '@angular/router';
import { OffersService } from '@app/offers/offers.service';
import { CivilityPipe } from '@app/shared/pipes/civility.pipe';
import { Fluid } from '@app/shared/models/fluid.model';
import { InitAppService } from '@app/shared/resolvers/init-app.service';
import {
  ButtonIcon,
  ButtonSize,
  ButtonSpacing,
  ButtonStyle,
} from '@app/shared/components/molecules/buttons/button.type';

const civilityPipe: CivilityPipe = new CivilityPipe();
@Component({
  selector: 'app-identity-block',
  templateUrl: './identity-block.component.html',
  styleUrls: ['./identity-block.component.scss'],
})
export class IdentityBlockComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  civilityNamesGroup: string = '';

  currentFluid?: Fluid;

  currentProfile?: string;

  constructor(
    private authentication: AuthService,
    public router: Router,
    public offersService: OffersService,
    private initAppService: InitAppService,
  ) { }

  ngOnInit(): void {
    this.initAppService.current()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ fluid, profile }) => {
        this.currentFluid = fluid;
        this.currentProfile = profile;
      });
    this.authentication.getProfile()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ firstName, lastName, civility }: ProfileDetails) => {
          this.civilityNamesGroup = `${firstName || civilityPipe.transform(civility)} ${lastName}`;
        },
      });
  }

  get profile(): string | undefined {
    return this.currentProfile;
  }

  displayPersonalInfo() {
    const {
      currentProfile: profile,
    } = this;
    this.router.navigate(['/account'], { queryParams: { profile } });
  }

  logout() {
    this.authentication.signOut();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  get fluid() {
    return this.currentFluid?.key;
  }

  protected readonly ButtonSpacing = ButtonSpacing;

  protected readonly ButtonStyle = ButtonStyle;

  protected readonly ButtonSize = ButtonSize;

  protected readonly ButtonIcon = ButtonIcon;
}
