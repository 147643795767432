import {
  ContentChild,
  Directive,
} from '@angular/core';
import { ColumnHeaderComponent } from '@app/shared/components/atoms/table/column-header/column-header.component';

@Directive({
  selector: 'appRow, tr[appRow]',
})
export class RowDirective {
  @ContentChild(ColumnHeaderComponent) header?: ColumnHeaderComponent;
}
