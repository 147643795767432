import { CommonModule } from '@angular/common';
import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { IconComponent, Size, Style } from '@app/shared/components/atoms/icon/icon.component';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent],
})
export class ChipComponent {
  @Input() label: string = '';

  @Input() key: string = '';

  @Input() disabledButton?: boolean = false;

  @Output() removeAction = new EventEmitter<void>();

  remove() {
    this.removeAction.emit();
  }

  protected readonly Style = Style;

  protected readonly Size = Size;
}
