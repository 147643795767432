import { Injectable } from '@angular/core';
import {
  BehaviorSubject, catchError, EMPTY, map, Observable, of, tap, throwError,
} from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { environment } from '@environments/environment';
import {
  AuthenticationResult,
  ChallengeName,
  Profile,
  ProfileDetails,
  UserAttributes,
  UserResponse,
} from '@app/shared/interfaces/user-response.interface';
import { User } from '@app/shared/models/user-info';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import { BASE_API_URL } from '@app/shared/utils/api';
import { FluidService } from '@app/shared/services/fluid.service';
import { ProfileService } from '@app/shared/services/profile.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static ID_TOKEN = 'idToken';

  public static ACCESS_TOKEN = 'accessToken';

  public static REFRESH_TOKEN = 'refreshToken';

  public static CHALLENGE_SESSION = 'challenge-session';

  public static USER_MAIL = 'userEmail';

  public static FIRSTNAME = 'firstname';

  public static LASTNAME = 'lastname';

  public static CREATED_IN = 'date';

  public static PROFILE_ID = 'profileId';

  public static PROFILE_ALL = 'ALL';

  public connectedUser$: BehaviorSubject<User | null>;

  public currentUser: Observable<User | null>;

  public isForgotPassword: boolean = false;

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private authJwtDecoded = {
    auth_time: '',
    email_verified: false,
    sub: '',
    email: '',
    given_name: '',
    family_name: '',
  };

  profiles: Profile[] = [];

  private currentProfile: ProfileDetails | undefined = undefined;

  constructor(
    private http: HttpClient,
    public router: Router,
    public sessionStorage: SessionStorageService,
    public fluidService: FluidService,
    private profileService: ProfileService,
  ) {
    const savedToken = sessionStorage.get(AuthService.ID_TOKEN);
    const user = this.createUserFromToken(savedToken ?? '');
    this.connectedUser$ = new BehaviorSubject<User | null>(user);
    this.currentUser = this.connectedUser$.asObservable();
  }

  public healthCheck(): Observable<any> {
    return this.http
      .get<any>(`${BASE_API_URL.CA_API_BASE_URL_IAM}${API_ROUTES.HEALTH_CHECK}`, {
      headers: this.headers,
    });
  }

  public signIn(email: string, password: string): Observable<any> {
    return this.http
      .post<UserResponse>(`${BASE_API_URL.CA_API_BASE_URL_IAM}${API_ROUTES.LOGIN}`, {
      email, password,
    }, {
      headers: this.headers,
    })
      .pipe(
        map((res: UserResponse) => this.manageChallenge(res)),
      );
  }

  public signOut() {
    const headers = new HttpHeaders({
      Authorization: this.sessionStorage.get(AuthService.ID_TOKEN) ?? [],
    });
    const body = { refreshToken: this.sessionStorage.get(AuthService.REFRESH_TOKEN) };
    this.clear();
    this.http
      .post<any>(`${BASE_API_URL.CA_API_BASE_URL_IAM}${API_ROUTES.LOGOUT}`, body, {
      headers,
    })
      .subscribe(() => {
        this.router.navigate(['/login']);
      });
    this.router.navigate(['/login']);
  }

  clear() {
    this.emptySession();
    this.connectedUser$.next(null);
    this.profiles = [];
  }

  responseLogin(res: UserResponse, updateCredentials = true) {
    const { idToken } = res.authenticationResult;

    if (idToken) {
      this.sessionStorage.set(AuthService.ACCESS_TOKEN, res.authenticationResult.accessToken);
      this.sessionStorage.set(AuthService.ID_TOKEN, idToken);
      if (res.authenticationResult.refreshToken) {
        this.sessionStorage.set(AuthService.REFRESH_TOKEN, res.authenticationResult.refreshToken);
        this.sessionStorage.set(AuthService.CREATED_IN, DateTime.now().toISO());
      }

      if (updateCredentials) {
        const user = this.createUserFromToken(idToken);
        this.connectedUser$.next(user);
      }
    }
    return {};
  }

  responseLoginSaml(authenticationResult: AuthenticationResult) {
    const { idToken, accessToken, refreshToken } = authenticationResult;

    if (idToken) {
      this.sessionStorage.set(AuthService.ACCESS_TOKEN, accessToken);
      this.sessionStorage.set(AuthService.ID_TOKEN, idToken);
      if (refreshToken) {
        this.sessionStorage.set(AuthService.REFRESH_TOKEN, refreshToken);
        this.sessionStorage.set(AuthService.CREATED_IN, DateTime.now().toISO());
      }

      const user = this.createUserFromToken(idToken);
      this.connectedUser$.next(user);
    }
    return {};
  }

  protected manageChallenge(res: UserResponse) {
    const challenge = res.challengeName as keyof typeof ChallengeName;

    if (challenge && ChallengeName[challenge] === ChallengeName.NEW_PASSWORD_REQUIRED) {
      this.sessionStorage.set(AuthService.CHALLENGE_SESSION, res.session);
      const userAttributes: UserAttributes = JSON.parse(
        res.challengeParameters.userAttributes,
      );
      this.sessionStorage.set(AuthService.USER_MAIL, userAttributes.email.toLowerCase());
      this.sessionStorage.set(AuthService.CREATED_IN, DateTime.now().toISO());
      return ChallengeName.NEW_PASSWORD_REQUIRED;
    }
    return this.responseLogin(res);
  }

  forgotPassword(email: string, confirmationCode?: string, newPassword?: string) {
    return this.http
      .post<any>(`${BASE_API_URL.CA_API_BASE_URL_IAM}${API_ROUTES.FORGOT_PASSWORD}`, {
      email,
      confirmationCode,
      newPassword,
    }, {
      headers: this.headers,
    })
      .pipe(
        map((response: any) => response),
      );
  }

  public resetPassword(newPassword: string): Observable<any> {
    const session = this.getChallengeSession();
    const email = this.getUserEmail();

    return this.http
      .post<UserResponse>(`${BASE_API_URL.CA_API_BASE_URL_IAM}${API_ROUTES.RESET_PASSWORD}`, {
      email, newPassword, session,
    }, {
      headers: this.headers,
    })
      .pipe(
        map((response: UserResponse) => {
          this.removeChallengeSession();
          this.removeUserEmail();
          return this.responseLogin(response);
        }),
      );
  }

  public checkTemporaryPassword(email: string): Observable<any> {
    return this.signIn(email, '');
  }

  public generateNewPassword(email: string): Observable<any> {
    if (!email) {
      return throwError(() => new Error('Invalid Email'));
    }
    return this.http.post<any>(`${BASE_API_URL.CA_API_BASE_URL_IAM}${API_ROUTES.USERS}`, {
      email,
    }, {
      headers: this.headers,
    }).pipe(
      map((response: any) => response),
    );
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const accessToken = this.getAccessToken();
    const email = this.currentUserValue?.email;
    if (!email) {
      return throwError(() => new Error('Invalid Email'));
    }
    const urlChangePassword = `${BASE_API_URL.CA_API_BASE_URL_IAM}${API_ROUTES.CHANGE_PASSWORD}`.replace('{email}', email);
    return this.http
      .put<any>(urlChangePassword, {
      accessToken, newPassword, oldPassword,
    }, {
      headers: this.headers,
    })
      .pipe(
        map((response: any) => response),
      );
  }

  public canNavigateToProfileSelection(passwordReset = false): Observable<boolean> {
    return this
      .getProfiles()
      .pipe(map((profiles: string | any[]) => {
        let foundprofile = false;
        if (profiles.length === 1) {
          this.setProfile(profiles[0]);
          foundprofile = true;

          this.router.navigate(['/'], { state: { passwordReset } });
        } if (profiles.length > 1) {
          this.router.navigate(['/login/profiles'], { state: { passwordReset } });

          foundprofile = true;
        }
        return foundprofile;
      }));
  }

  public getProfiles(): Observable<Profile[]> {
    if (this.profiles.length > 0) {
      return of(this.profiles);
    }
    const params = new HttpParams().append('email', this.authJwtDecoded.email);
    return this.http
      .get<Profile[]>(
      `${BASE_API_URL.CA_API_BASE_URL_CRM}${API_ROUTES.PROFILES}`,
      {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).pipe(
      tap((profiles: Profile[]) => {
        this.profiles = profiles;
      }),
      catchError(() => EMPTY),
    );
  }

  public getProfile(): Observable<ProfileDetails> {
    if (this.currentProfile) {
      return of(this.currentProfile);
    }

    const params = new HttpParams()
      .append('email', this.authJwtDecoded.email);

    return this.http
      .get<ProfileDetails>(
      `${BASE_API_URL.CA_API_BASE_URL_CRM}${API_ROUTES.PROFILES}/${this.currentUserValue?.selectedProfile?.id ?? this.profiles[0]?.id}`,
      {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).pipe(
      tap((profileDetails: ProfileDetails) => {
        this.currentProfile = profileDetails;
      }),
    );
  }

  public setAllPerimeter([profile]: Profile[]) {
    this.profileService.setAllProfiles();
    const user = this.currentUserValue;
    if (user) {
      user.selectedProfile = undefined;
      const { firstname, name } = profile;
      user.firstName = firstname;
      user.lastName = name;
      this.connectedUser$.next(user);
      this.currentProfile = undefined;
      this.sessionStorage.set(AuthService.PROFILE_ID, AuthService.PROFILE_ALL);
      this.sessionStorage.set(AuthService.FIRSTNAME, firstname);
      this.sessionStorage.set(AuthService.LASTNAME, name);
    }
  }

  public setProfile(profile: Profile) {
    this.profileService.setProfile(profile);
    const user = this.currentUserValue;
    if (user) {
      user.selectedProfile = profile;
      this.currentProfile = undefined;
      this.sessionStorage.set(AuthService.PROFILE_ID, profile.id);
      this.connectedUser$.next(user);
    }
  }

  public createUserFromToken(idToken: string): User | null {
    if (!idToken) {
      return null;
    }

    this.authJwtDecoded = jwt_decode(idToken);
    return {
      authTime: this.authJwtDecoded.auth_time,
      email: this.authJwtDecoded.email.toLowerCase(),
      emailVerified: this.authJwtDecoded.email_verified,
      authJwt: idToken,
      id: this.authJwtDecoded.sub,
    };
  }

  initUser(): Observable<Profile[]> {
    if (this.isLoggedIn() && !this.isSessionExpired()) {
      const savedToken = this.sessionStorage.get(AuthService.ID_TOKEN);
      const user = this.createUserFromToken(savedToken ?? '');

      if (user) {
        return this.getProfiles().pipe(tap((profiles: Profile[]) => {
          const profile = profiles.find((p) => p.id === this.getProfileID());
          this.profileService.setProfile(profile);
          this.connectedUser$.next({
            ...user,
            firstName: this.sessionStorage.get(AuthService.FIRSTNAME) || undefined,
            lastName: this.sessionStorage.get(AuthService.LASTNAME) || undefined,
            selectedProfile: profile,
          });
        }));
      }
    }
    return EMPTY;
  }

  public emptySession() {
    this.sessionStorage.remove(AuthService.ACCESS_TOKEN);
    this.sessionStorage.remove(AuthService.ID_TOKEN);
    this.sessionStorage.remove(AuthService.REFRESH_TOKEN);
    this.sessionStorage.remove(AuthService.CREATED_IN);
    this.sessionStorage.remove(AuthService.PROFILE_ID);
    this.sessionStorage.remove(AuthService.FIRSTNAME);
    this.sessionStorage.remove(AuthService.LASTNAME);
  }

  public isSessionExpired(): boolean {
    const createdIn = DateTime.fromISO(this.sessionStorage.get(AuthService.CREATED_IN)!);
    const now = DateTime.now();
    return createdIn < now.minus({ day: environment.USER_SESSION_EXPIRY_DELAY_DAYS });
  }

  public isLoggedIn(): boolean {
    return this.sessionStorage.get(AuthService.ID_TOKEN) !== null;
  }

  public get currentUserValue(): User | null {
    return this.connectedUser$.value;
  }

  getToken() {
    return this.sessionStorage.get(AuthService.ID_TOKEN);
  }

  getAccessToken() {
    return this.sessionStorage.get(AuthService.ACCESS_TOKEN);
  }

  getRefreshToken() {
    return this.sessionStorage.get(AuthService.REFRESH_TOKEN);
  }

  getChallengeSession() {
    return this.sessionStorage.get(AuthService.CHALLENGE_SESSION);
  }

  removeChallengeSession() {
    this.sessionStorage.remove(AuthService.CHALLENGE_SESSION);
  }

  getUserEmail() {
    return this.sessionStorage.get(AuthService.USER_MAIL);
  }

  removeUserEmail() {
    this.sessionStorage.remove(AuthService.USER_MAIL);
  }

  getProfileID() {
    return this.sessionStorage.get(AuthService.PROFILE_ID);
  }

  public hasAnyAuthority(authorities: string[]): boolean {
    if (!this.currentUserValue) {
      return false;
    }

    if (!this.currentUserValue.selectedProfile) {
      return true;
    }

    return authorities
      .some((authority) => this.currentUserValue
        ?.selectedProfile
        ?.rights
        ?.includes(authority));
  }

  public samlAuth(code: string): Observable<AuthenticationResult> {
    const params = new HttpParams()
      .append('code', code);
    return this.http
      .post<AuthenticationResult>(
      `${BASE_API_URL.CA_API_BASE_URL_IAM}${API_ROUTES.SAML_AUTH}`,
      {},
      {
        params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  }
}
