export enum Granularity {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum GranularityLabel {
  HOUR = 'Heure',
  DAY = 'Jour',
  WEEK = 'Semaine',
  MONTH = 'Mois',
  YEAR = 'Année',
}

export enum ConsumptionPeriod {
  YEARMINUS1 = 'YEARMINUS1',
  MONTHMINUS1 = 'MONTHMINUS1',
  WEEKMINUS1 = 'WEEKMINUS1',
  DAYMINUS1 = 'DAYMINUS1',
}

export enum ConsumptionPeriodLabel {
  YEARMINUS1 = 'Année -1',
  MONTHMINUS1 = 'Mois -1',
  WEEKMINUS1 = 'Semaine - 1',
  DAYMINUS1 = 'Jour - 1',
}
