import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { FluidService } from '@app/shared/services/fluid.service';
import { BASE_API_URL } from '@app/shared/utils/api';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import { OffersService } from '@app/offers/offers.service';

@Injectable()
export class LogoutInterceptor implements HttpInterceptor {
  readonly logoutUrl = `${BASE_API_URL.CA_API_BASE_URL_IAM}${API_ROUTES.LOGOUT}`;

  constructor(
    public fluidService: FluidService,
    public offersService: OffersService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const { logoutUrl } = this;
    return next.handle(request).pipe(
      finalize(() => {
        if (request.url === logoutUrl) {
          this.fluidService.clear();
          this.offersService.clear();
        }
      }),
    );
  }
}
