import { environment } from '@environments/environment';
import { API_CONTEXT } from '@app/shared/utils/api-routes';

let endpoint = '';

if (environment.local) {
  endpoint = '-test';
}

if (environment.demo) {
  endpoint = '-demo';
}

export const BASE_API_URL = {
  CA_API_BASE_URL_MARKET_DATA: `${environment.CA_API_BASE_URL}${API_CONTEXT.MARKET_DATA_CONTEXT}${endpoint}`,
  CA_API_BASE_URL_IAM: `${environment.CA_API_BASE_URL}${API_CONTEXT.IAM_CONTEXT}${endpoint}`,
  CA_API_BASE_URL_BILLINGS: `${environment.CA_API_BASE_URL}${API_CONTEXT.BILLINGS_CONTEXT}${endpoint}`,
  CA_API_BASE_URL_IRIS: `${environment.CA_API_BASE_URL}${API_CONTEXT.IRIS_CONTEXT}${endpoint}`,
  CA_API_BASE_URL_GOUV: `${environment.CA_API_BASE_URL}${API_CONTEXT.GOUV_CONTEXT}${endpoint}`,
  CA_API_BASE_URL_CRM: `${environment.CA_API_BASE_URL}${API_CONTEXT.CRM_CONTEXT}${endpoint}`,
  CA_API_BASE_URL_EDITION: `${environment.CA_API_BASE_URL}${API_CONTEXT.EDITION_CONTEXT}${endpoint}`,
  CA_API_BASE_URL_SIGNAL: `${environment.CA_API_BASE_URL}${API_CONTEXT.SIGNALS_CONTEXT}${endpoint}`,
  CA_API_BASE_URL_OPERA: `${environment.CA_API_BASE_URL}${API_CONTEXT.OPERA_CONTEXT}${endpoint}`,
};
