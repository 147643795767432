import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable, ReplaySubject, Subject, takeUntil,
} from 'rxjs';
import { Fluid } from '@app/shared/models/fluid.model';

export type DataStore = {
  fluid?: Fluid;
};

@Injectable({
  providedIn: 'root',
})
export class FluidService implements OnDestroy {
  private selectedFluid$: ReplaySubject<DataStore> = new ReplaySubject<DataStore>(1);

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  setFluid(fluid: Fluid) {
    this.selectedFluid$.next({ fluid });
  }

  currentFluid$(): Observable<DataStore> {
    return this.selectedFluid$.pipe(
      takeUntil(this.unsubscribe$),
    );
  }

  clear(): void {
    this.selectedFluid$.next({});
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.selectedFluid$.complete();
    this.unsubscribe$.unsubscribe();
  }
}
