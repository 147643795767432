<div
  id="fluid-toggle-switch"
  class="toggle-container"
  [class.disabled]="disabled"
  [class.larged]="type === FluidToggleSwitchTypeEnum.TEXT_ICON"
>
  <button id="tabs-icon-buttons-{{Fluid.ELEC}}"
          class="toggle-option elec"
          [class.selected]="isCurrentFluid(Fluid.ELEC)"
          (click)="onToggle(Fluid.ELEC)"
          [disabled]="disabled || isCurrentFluid(Fluid.ELEC)"
          (mouseenter)="handleMouseEvent('mouseenter', Fluid.ELEC)"
          (mouseleave)="handleMouseEvent('mouseleave', Fluid.ELEC)"
  >
    <app-icon #elecIcon class="em"
              [iconStyle]="isCurrentFluid(Fluid.ELEC) ? Style.fill : Style.bold"
              phosphorClass="ph-lightning"
              [color]="disabled && isCurrentFluid(Fluid.GAZ) ? Color.grey500 : Color.greyBlack "
    ></app-icon>
    <span *ngIf="type === FluidToggleSwitchTypeEnum.TEXT_ICON">
    {{ Fluid.ELEC.value }}
  </span>
  </button>
  <button id="tabs-icon-buttons-{{Fluid.GAZ}}"
          class="toggle-option gaz"
          [class.selected]="isCurrentFluid(Fluid.GAZ)"
          (click)="onToggle(Fluid.GAZ)"
          [disabled]="disabled || isCurrentFluid(Fluid.GAZ)"
          (mouseenter)="handleMouseEvent('mouseenter', Fluid.GAZ)"
          (mouseleave)="handleMouseEvent('mouseleave', Fluid.GAZ)"
  >
    <app-icon #gazIcon class="em"
              [iconStyle]="isCurrentFluid(Fluid.GAZ) ? Style.fill : Style.bold"
              [color]="disabled && isCurrentFluid(Fluid.ELEC) ? Color.grey500 : Color.greyBlack "
              phosphorClass="ph-flame"
    ></app-icon>
    <span *ngIf="type === FluidToggleSwitchTypeEnum.TEXT_ICON">
      {{ Fluid.GAZ.value }}
    </span>
  </button>
</div>
