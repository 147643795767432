import {
  ChartZoomingOptions, OptionsStackingValue,
  SeriesOptionsType,
  TooltipFormatterCallbackFunction,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';
import { DateTime } from 'luxon';
import { Total } from '@app/consumptions/charts.types';

export interface Point {
  date: string;
  value: number
}

export interface ChartBuildOptions {
  XAxisOption: XAxisOptions;
  YAxisOption: YAxisOptions;
  zoomingOptions: ChartZoomingOptions;
  tooltipFormatter: TooltipFormatterCallbackFunction;
  tooltipShared: boolean;
  seriesOptionsType: SeriesOptionsType[];
}

export interface StackedHistogramBuildOptions {
  chartBuildOptions: ChartBuildOptions;
  totals: Total[];
}

export interface HistogramBuildOptions {
  chartBuildOptions: ChartBuildOptions;
}

export interface CurveBuildOptions {
  chartBuildOptions: ChartBuildOptions;
  minValue?: Point;
  maxValue?: Point;
}

export type Coords = {
  x: number;
  y: number;
};

export type ExportSeriesDefinition = {
  series: SeriesOptionsType[];
  startOfPeriod: DateTime;
  endOfPeriod: DateTime;
};

export enum SeriesIds {
  CONSUMPTIONS = 'consumptions',
  SUBSCRIBEDPOWER = 'subscribedPower',
  COMPAREDCONSUMPTIONS = 'comparedConsumptions',
  PERIODICCONSUMPTIONS = 'periodicConsumptions',
  CURVE = 'curve',
  COMPAREDCURVE = 'comparedCurve',
  CONSUMPTIONSBYTARIFF = 'consumptionsByTariff',
  PP1 = 'pp1',
  HOLIDAYS = 'holidays',
  WEEKEND = 'weekend',
  POINTE = 'pointe',
  HPH = 'HPH',
  HCH = 'HCH',
  HPE = 'HPE',
  HCE = 'HCE',
  HP = 'HP',
  HC = 'HC',
  BASE = 'base',
}

export interface SeriesOptions {
  id?: string,
  name?: string;
  stacking?: OptionsStackingValue;
  color?: string;
  index: number;
}
