import {
  Pipe, PipeTransform,
} from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, valueToHighlight: string, background: boolean): string {
    if (!value || !valueToHighlight) return value;

    const re = new RegExp(valueToHighlight, 'igm');
    const cssClasses: string = background ? 'bold highlighted' : 'bold';
    return value.replace(re, `<span class="${cssClasses}">$&</span>`);
  }
}
