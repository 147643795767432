<app-expansion-panel [expansionPanelId]="rangepickerId"
                     iconClassName="ph-calendar-blank"
                     [iconSize]="Size['medium-small']"
                     [coverText]="coverText"
                     [coverSpacing]="coverSpacing"
                     [state]="disabled ? 'disabled' : 'default'"
                     [hasCloseOnCLick]="false"
                     (panelChanges)="resetValues()">
  <ng-container ngProjectAs="[dropdown-content]">
    <div class="rangepicker" >
      <div class="rangepicker-content">
        <div class="preselected-range-selector" *ngIf="preselectedRanges && preselectedRanges.length > 0">
          <div
            *ngFor="let preselectedRange of preselectedRanges"
            class="select-option"
            [class.selected]="preselectedRange === selectedPreselectedRange"
            [class.disabled]="preselectedRange.disabled"
            (click)="selectPreselectedRange(preselectedRange)"
            [id]="'select-range-'+preselectedRange.period"
          >
            <span>{{ preselectedRange.period }}</span>
            <app-icon phosphorClass="ph-info"
                      [size]="Size.small"
                      *ngIf="preselectedRange.infobulle"
                      [appTooltip]="preselectedRange.infobulle"
                      appTooltipPosition="right">
            </app-icon>
          </div>
          <div class="select-option"
               [class.selected]="!selectedPreselectedRange"
               (click)="selectPreselectedRange(undefined)"
               [id]="'selectRangeCustom'"
          >
            <span>Personnaliser</span>
          </div>
        </div>
        <div class="date-tables-and-footer">
          <div class="date-tables">
            <div class="date-table">
              <div *ngIf="!displayLeftMonthSelector" class="month-select">
                <app-icon phosphorClass="ph-caret-left" class="chevron" (click)="previousLeftMonth()"></app-icon>
                <span id="left-month-toggle" class="month" (click)="toggleDisplayLeftMonthSelector()">
                  {{ selectedLeftMonth.monthLong | upperCaseFirstLetters }} {{ selectedLeftMonth.year }}
                  <app-icon phosphorClass="ph-caret-down" class="chevron" [size]="Size.small"></app-icon>
                </span>
                <app-icon
                  phosphorClass="ph-caret-right"
                  class="chevron"
                  (click)="nextLeftMonth()"
                  [ngClass]="{'disabled': selectedLeftMonth.plus({ month: 1 }) >= selectedRightMonth}"
                ></app-icon>
              </div>
              <div *ngIf="displayLeftMonthSelector" class="year-range-select">
                <span class="month" (click)="toggleDisplayLeftMonthSelector()">
                  {{ selectedLeftYear ? selectedLeftYear : leftSelectableYears[0][0] + '-' + leftSelectableYears[leftSelectableYears.length - 1][3] }}
                </span>
              </div>
              <table *ngIf="!displayLeftMonthSelector">
                <thead>
                <th *ngFor="let day of daysOfWeek">{{ day }}</th>
                </thead>
                <tbody>
                <tr *ngFor="let datesRow of leftDates">
                  <td *ngFor="let date of datesRow"
                      [id]="'left-date-' + date?.day"
                      [ngClass]="{
                          'selected-date': date && (selectedFirstDate && date.hasSame(selectedFirstDate, 'day') || selectedLastDate && date.hasSame(selectedLastDate, 'day')),
                          'date-in-range': isDateInSelectedRange(date),
                          'disabled-date': !date || !isDateInValidRange(date!, 'day')
                        }"
                      (click)="selectDate(date)"
                      (mouseover)="setHoverDate(date)">{{ date?.day }}
                  </td>
                </tr>
                </tbody>
              </table>
              <table *ngIf="displayLeftMonthSelector && !selectedLeftYear">
                <tbody>
                <tr *ngFor="let yearsRow of leftSelectableYears">
                  <td [id]="'left-year-' + year"
                      *ngFor="let year of yearsRow"
                      (click)="selectLeftYear(year)"
                      [ngClass]="{
                        'selected-date': selectedLeftMonth.year === year,
                        'disabled-date': isLeftYearDisabled(year)
                      }"
                  >
                    {{ year }}
                  </td>
                </tr>
                </tbody>
              </table>
              <table *ngIf="displayLeftMonthSelector && selectedLeftYear">
                <tbody>
                <tr *ngFor="let monthsRow of displayedMoisDeLAnnee">
                  <td [id]="'left-month-' + monthOfYear.indexOf(month)"
                      *ngFor="let month of monthsRow"
                      (click)="selectLeftMonth(month)"
                      [ngClass]="{
                        'selected-date': selectedLeftMonth.year === selectedLeftYear && selectedLeftMonth.month === monthOfYear.indexOf(month) + 1,
                        'disabled-date': isLeftMonthDisabled(month)
                      }"
                  >
                    {{ month }}
                  </td>
                </tr>
                </tbody>
              </table>
              <span *ngIf="displayHours  && !displayLeftMonthSelector" class="hour">Heure 00:00</span>
            </div>
            <div class="date-table">
              <div *ngIf="!displayRightMonthSelector" class="month-select">
                <app-icon phosphorClass="ph-caret-left"
                          class="chevron"
                          (click)="previousRightMonth()"
                          [ngClass]="{'disabled': selectedRightMonth.minus({ month: 1 }) <= selectedLeftMonth}"></app-icon>
                <span id="right-month-toggle" class="month" (click)="toggleDisplayRightMonthSelector()">
                  {{ selectedRightMonth.monthLong | upperCaseFirstLetters }} {{ selectedRightMonth.year }}
                  <app-icon phosphorClass="ph-caret-down" class="chevron" [size]="Size.small"></app-icon>
                </span>
                <app-icon phosphorClass="ph-caret-right" class="chevron" (click)="nextRightMonth()"></app-icon>
              </div>
              <div *ngIf="displayRightMonthSelector" class="year-range-select">
                <span class="month" (click)="toggleDisplayRightMonthSelector()">
                  {{ selectedRightYear ? selectedRightYear : rightSelectableYears[0][0] + '-' + rightSelectableYears[rightSelectableYears.length - 1][3] }}
                </span>
              </div>
              <table *ngIf="!displayRightMonthSelector">
                <thead>
                <th *ngFor="let day of daysOfWeek">{{ day }}</th>
                </thead>
                <tbody>
                <tr *ngFor="let datesRow of rightDates">
                  <td [id]="'right-date-' + date?.day"
                      *ngFor="let date of datesRow"
                      [ngClass]="{
                          'selected-date': date && (selectedFirstDate && date.hasSame(selectedFirstDate, 'day') || selectedLastDate && date.hasSame(selectedLastDate, 'day')),
                          'date-in-range': isDateInSelectedRange(date),
                          'disabled-date': isRightDayOutOfScope(date)
                        }"
                      (click)="selectDate(date)"
                      (mouseover)="setHoverDate(date)">{{ date?.day }}
                  </td>
                </tr>
                </tbody>
              </table>
              <table *ngIf="displayRightMonthSelector && !selectedRightYear">
                <tbody>
                <tr *ngFor="let yearsRow of leftSelectableYears">
                  <td [id]="'right-year-' + year"
                      *ngFor="let year of yearsRow"
                      (click)="selectRightYear(year)"
                      [ngClass]="{
                        'selected-date': selectedRightMonth.year === year,
                        'disabled-date': isRightYearDisabled(year)
                      }"
                  >
                    {{ year }}
                  </td>
                </tr>
                </tbody>
              </table>
              <table *ngIf="displayRightMonthSelector && selectedRightYear">
                <tbody>
                <tr *ngFor="let monthsRow of displayedMoisDeLAnnee">
                  <td [id]="'right-month-' + monthOfYear.indexOf(month)"
                      *ngFor="let month of monthsRow"
                      (click)="selectRightMonth(month)"
                      [ngClass]="{
                        'selected-date': selectedRightMonth.year === selectedRightYear && selectedRightMonth.month === monthOfYear.indexOf(month) + 1,
                        'disabled-date': isRightMonthDisabled(month) || isRightMonthOutOfScope(month)
                      }"
                  >
                    {{ month }}
                  </td>
                </tr>
                </tbody>
              </table>
              <span *ngIf="displayHours && !displayRightMonthSelector" class="hour">Heure 00:00</span>
            </div>
          </div>
          <div class="rangepicker-footer">
            <div class="message-container">
              <span *ngIf="maxRangeMonthsExceededMessage && isMaxRangeMonthsExceeded()" class="message" id="message-error">
                <app-icon phosphorClass="ph-warning-circle" [iconStyle]="Style.fill" [color]="Color.danger500"></app-icon>{{ maxRangeMonthsExceededMessage }}</span>
            </div>
            <div class="actions">
              <app-button
                [style]="ButtonStyle.Secondary"
                (onclick)="cancelChanges()"
              >Annuler</app-button>
              <app-button
                buttonId="apply-date-filter"
                [isDisabled]="isMaxRangeMonthsExceeded() || !this.selectedFirstDate || !this.selectedLastDate"
                (onclick)="applyChanges()"
                >Appliquer</app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-expansion-panel>
