import {
  Component, Input, OnChanges, OnInit, SimpleChanges,
} from '@angular/core';
import { Chart } from 'angular-highcharts';
import {
  ColorString, CSSObject,
  SeriesOptionsType, setOptions,
} from 'highcharts';
import { MESSAGES } from '@app/shared/utils/messages';
import { DecimalPipe } from '@angular/common';
import { BillinUnitType, InvoiceAmount } from '@app/shared/interfaces/invoice.interface';
import { ChartItem } from '@app/shared/interfaces/chart.interface';
import { Color } from '@app/shared/models/color';
import { Style } from '@app/shared/components/atoms/icon/icon.component';

setOptions({
  lang: {
    decimalPoint: ',',
  },
});

const decimalPipe: DecimalPipe = new DecimalPipe('fr');
const NO_DATA = {
  series: ['none', 1],
  color: ['#E9EBEF'],
};

@Component({
  selector: 'app-area-column-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit, OnChanges {
  @Input() size: number = 250;

  @Input() subtitleCSSStyle: CSSObject = {
    fontFamily: '"Inter", sans-serif',
    fontSize: '.75rem',
    fontWeight: '400',
    color: Color.greyBlack,
  };

  @Input() valueUnit: string = BillinUnitType.EUR;

  @Input() titleUnit: string = BillinUnitType.EUR;

  @Input() shortSubtitle: string = 'TTC';

  chart?: Chart;

  @Input() data: InvoiceAmount[] = [];

  @Input() withUnderline = true;

  @Input() pieColors: ColorString[] = [
    '#225940', '#73CAA2', '#388B66',
    '#63FFB8', '#73CAA2', '#93F3C8',
    '#B0FFDB', '#54D89C', '#40A678',
  ];

  @Input() nbLegendItemPerColumn = 3;

  @Input() legendTitle?: string;

  @Input() exporting = false;

  series?: SeriesOptionsType[];

  chartItems: ChartItem[] = [];

  messages = MESSAGES;

  BillinUnitType = BillinUnitType;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.chartStyles || changes.pieColors) {
      if (changes.data) {
        this.chartItems = this.data.map(({ name, value, tooltip }, index) => {
          const color = this.pieColors[index];
          return {
            color,
            name,
            value: +value.toFixed(2),
            enabled: true,
            tooltip,
          };
        });
      }

      this.initChart();
    }
  }

  initChart() {
    const { displayedData, hasData } = this;

    const totalValue: number = +displayedData
      .map((data) => +(data[1] ?? 0))
      .reduce((sum, current) => sum + current, 0)
      .toFixed(2);
    const { length } = totalValue.toString();
    const titleFontSize = this.totalSize(length);

    this.series = [{
      data: hasData ? displayedData : NO_DATA.series,
      innerSize: '80%',
      showInLegend: true,
      dataLabels: {
        enabled: false,
      },
    }] as unknown as SeriesOptionsType[];

    if (this.chart) this.chart?.destroy();

    this.chart = new Chart({
      exporting: {
        enabled: this.exporting,
      },
      accessibility: {
        enabled: false,
      },
      chart: {
        type: 'pie',
        height: this.size,
        width: this.size,
      },
      title: {
        text: `<span class="total-value">${decimalPipe.transform(totalValue, '1.2-2')} ${this.titleUnit}</span>`,
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontFamily: '"Inter", sans-serif',
          fontSize: `${titleFontSize}rem`,
          fontWeight: '600',
        },
        y: (this.shortSubtitle ? 15 : 25),
      },
      subtitle: {
        text: `<bold><span>${this.shortSubtitle}</span></bold>`,
        align: 'center',
        verticalAlign: 'middle',
        style: this.subtitleCSSStyle,
        y: 40,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: totalValue > 0,
        headerFormat: undefined,
        pointFormat: `
              <span style="justify-content: center; padding: 0 0.5rem">{point.name} : <br><b>{point.y:,.2f} ${this.titleUnit}</b></span>
          `,
        backgroundColor: '#F1F2F5',
        borderRadius: 4,
        borderWidth: 0,
        padding: 16,
        shadow: {
          color: 'grey',
          opacity: 0.2,
          offsetX: 0,
          offsetY: 0,
          width: 5,
        },
        style: {
          fontFamily: 'Inter',
          fontSize: '0.88rem',
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          colors: hasData ? this.displayedPieColors : NO_DATA.color,
          borderWidth: 5,
        },
      },
      series: this.series,
    });
  }

  ngOnInit(): void {
    this.initChart();
  }

  handleClick(nameSelected: (string | number)) {
    this.chartItems = this.chartItems
      .map((value) => {
        if (value.name !== nameSelected) return value;

        return { ...value, enabled: !value.enabled };
      });

    this.initChart();
  }

  get displayedData() {
    return this.chartItems
      .filter(({ enabled }) => enabled)
      .filter(({ value }) => +value > 0)
      .map(({ name, value }) => [name, value])
      .sort((a: any, b: any) => b[1] - a[1]);
  }

  get displayedPieColors() {
    return this.chartItems
      .filter(({ enabled }) => enabled)
      .sort((a: any, b: any) => b.value - a.value)
      .map(({ color }) => color);
  }

  convertUnit(value: any): number {
    if (this.valueUnit === BillinUnitType.PERCENT) {
      const total = this.data
        .map(({ value: dataValue }) => +dataValue ?? 0)
        .reduce((sum, val) => sum + val, 0);

      return +(value / total).toFixed(4);
    }
    return value;
  }

  onMouseEnter(name: string | number) {
    this.chart?.ref$.subscribe({
      next: (chart) => {
        chart.series[0].points.forEach((p) => {
          if (p.name === name) {
            p.setState('hover');
          } else {
            p.setState('inactive');
          }
        });
      },
    });
  }

  onMouseOut() {
    this.chart?.ref$.subscribe({
      next: (chart) => {
        chart.series[0].points.forEach((p) => p.setState('normal'));
      },
    });
  }

  get hasData() {
    return this.chartItems.length > 0;
  }

  public getLegendContainerStyles() {
    const { nbLegendItemPerColumn } = this;

    return {
      'grid-template-rows': `repeat(${nbLegendItemPerColumn}, 1fr)`,
    };
  }

  protected readonly MESSAGES = MESSAGES;

  totalSize(value: number) {
    if (value <= 6) return 1.65;
    if (value <= 9) return 1.45;
    if (value <= 12) return 1.25;
    return 1.00;
  }

  protected readonly Style = Style;
}
