import { UnitTypes } from '@app/shared/interfaces/consumptions.interface';
import { SeriesIds, SeriesOptions } from '@app/consumptions/chart-services/charts.types';

export function fractionDigitsByUnit(unit: UnitTypes | string, segment?: string) {
  switch (unit) {
    case UnitTypes.MWh:
      return 3;
    case UnitTypes.kWh:
      return 0;
    case UnitTypes.kVA:
      if (!segment) throw new Error('Segment is not define');
      if (['C1', 'C4'].includes(segment)) return 0;
      if (segment === 'C5') return 2;
      throw new Error(`Unexpected segment ${segment}`);
    case UnitTypes.kW:
      if (!segment) throw new Error('Segment is not define');
      if (['C2', 'C3'].includes(segment)) return 0;
      throw new Error(`Unexpected segment ${segment}`);
    default:
      throw new Error(`Unexpected unit ${unit}`);
  }
}

export function mapSeriesNameToSeriesOptions(seriesName: string): SeriesOptions {
  const seriesOptions: SeriesOptions = {
    stacking: 'normal',
    index: 10,
  };
  switch (seriesName) {
    case 'HPTE':
    case 'P':
      return {
        ...seriesOptions,
        id: SeriesIds.POINTE,
        name: 'Pointe',
        color: '#ffbdad',
        index: 5,
      };
    case 'HPSH':
    case 'HPH':
      return {
        ...seriesOptions,
        id: SeriesIds.HPH,
        name: 'Heures Pleines Hiver (HPH)',
        color: '#9ff1cc',
        index: 4,
      };
    case 'HCSH':
    case 'HCH':
      return {
        ...seriesOptions,
        id: SeriesIds.HCH,
        name: 'Heures Creuses Hiver (HCH)',
        color: '#147960',
        index: 3,
      };
    case 'HPSB':
    case 'HPE':
      return {
        ...seriesOptions,
        id: SeriesIds.HPE,
        name: 'Heures Pleines Été (HPE)',
        color: '#00b68a',
        index: 2,
      };
    case 'HCSB':
    case 'HCE':
      return {
        ...seriesOptions,
        id: SeriesIds.HCE,
        name: 'Heures Creuses Été (HCE)',
        color: '#003834',
        index: 1,
      };
    case 'HP':
    case 'HEURES PLEINES':
    case 'HEURES PEINES': // Temporaire car erreur d'opéra
      return {
        ...seriesOptions,
        id: SeriesIds.HP,
        name: 'Heures Pleines (HP)',
        color: '#9ff1cc',
        index: 3,
      };
    case 'HC':
    case 'HEURES CREUSES':
      return {
        ...seriesOptions,
        id: SeriesIds.HC,
        name: 'Heures Creuses (HC)',
        color: '#147960',
        index: 2,
      };
    case 'BASE':
      return {
        ...seriesOptions,
        id: SeriesIds.BASE,
        name: 'Base',
        color: '#00b68a',
        index: 1,
      };
    default:
      return {
        ...seriesOptions,
        name: seriesName,
      };
  }
}
