import {
  ApplicationRef,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Input,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { TooltipPositionEnum } from './tooltip.enum';

enum TooltipTriggerType {
  Hover = 'Hover',
  Click = 'Click',
}

@Directive({
  standalone: true,
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input('appTooltip') set text(tooltipInfo: string) {
    this.message = tooltipInfo;
  }

  @Input('appTooltipTriggerType') triggerType: keyof typeof TooltipTriggerType = 'Hover';

  @Input('appTooltipMaxWidth') maxWidth: number = 14.75;

  @Input('appTooltipDisabled') disabled: boolean = false;

  @Input('appTooltipPosition') position?: 'top' | 'right' | 'bottom' | 'left';

  delay: number = 100;

  message!: string;

  private componentRef?: ComponentRef<TooltipComponent>;

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private viewContainerRef: ViewContainerRef,
  ) { }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (!this.componentRef && !this.disabled && this.triggerType === 'Hover') {
      this.toDisplay();
    }
  }

  @HostListener('click')
  onClick(): void {
    if (!this.componentRef && !this.disabled && this.triggerType === 'Click') {
      this.toDisplay();
    }
  }

  private toDisplay() {
    this.componentRef = this.viewContainerRef.createComponent(TooltipComponent);
    const { hostView } = this.componentRef;
    const domElem = (hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    this.setTooltipComponentProperties();
  }

  private setTooltipComponentProperties() {
    if (this.componentRef) {
      this.componentRef.instance.message = this.message;
      this.componentRef.instance.maxWidth = this.maxWidth;
      this.componentRef.instance.position = this.position
        ? TooltipPositionEnum[this.position]
        : TooltipPositionEnum.top;
      this.componentRef.instance.parentNativeElement = this.elementRef.nativeElement;
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    setTimeout(() => this.destroy(), this.delay);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }
}
