import { Component } from '@angular/core';
import { AuthService } from '@app/shared/auth/auth.service';
import { FluidService } from '@app/shared/services/fluid.service';
import { environment } from '@environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { Size, Style } from '@app/shared/components/atoms/icon/icon.component';

@Component({
  selector: 'app-contact-button',
  templateUrl: './contact-button.component.html',
  styleUrls: ['./contact-button.component.scss'],
})
export class ContactButtonComponent {
  zendeskUrl: URL = new URL(environment.ZENDESK_SUPPORT_URL);

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private fluidService: FluidService,
    private authService: AuthService,
  ) {
    this.fluidService.currentFluid$().pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ fluid }) => {
          this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
            this.zendeskUrl = new URL(environment.ZENDESK_SUPPORT_URL);
            if (environment.env) this.zendeskUrl.searchParams.append('env', environment.env);
            if (fluid) this.zendeskUrl.searchParams.append('fluid', fluid.key);
            if (user) this.zendeskUrl.searchParams.append('email', user.email);
          });
        },
      });
  }

  protected readonly Style = Style;

  protected readonly Size = Size;
}
