import { Component, Input } from '@angular/core';
import { Typography } from '@app/shared/models/typography';
import { Size } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';

@Component({
  selector: 'app-icon-text-title-card',
  templateUrl: './icon-text-title-card.component.html',
  styleUrls: ['./icon-text-title-card.component.scss'],
})
export class IconTextTitleCardComponent {
  @Input()
    icon!: string;

  @Input()
    title!: string;

  protected readonly Typography = Typography;

  protected readonly Size = Size;

  protected readonly Color = Color;
}
