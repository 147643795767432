import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event as NavigationEvent, NavigationStart, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import {
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AlertTypeEnum } from '@app/shared/components/molecules/alert-card/alert-card.model';
import { Alert } from './alert.model';
import { AlertService } from './alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('snackTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100px)' }),
        animate('200ms cubic-bezier(.47,1.64,.41,.8)', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('150ms', style({ opacity: 0, transform: 'translateY(-100px)' })),
      ]),
    ]),
  ],
})
export class AlertComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  isShown = true;

  title?: string;

  type?: AlertTypeEnum;

  message?: string;

  private keepAfterNavigationChange = false;

  constructor(
    private alertService: AlertService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.alertService.getMessage().subscribe((alert: Alert | null) => {
      if (!alert?.message) {
        this.isShown = false;
        return;
      }

      const {
        type, duration, message, keepAfterNavigationChange,
      } = alert;
      this.keepAfterNavigationChange = keepAfterNavigationChange;
      this.type = type;
      this.message = message;
      this.isShown = true;
      this.autoHide(duration);
    });
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          this.keepAfterNavigationChange = false;
        } else {
          this.isShown = false;
          this.alertService.clear();
        }
      }
    });
  }

  private autoHide = (timeout?: number) => {
    if (!timeout) return;
    setTimeout(() => {
      this.isShown = false;
    }, timeout);
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
