<div class="container">
  <div class="main-form">
    <div class="leaf"></div>
    <svg
      width="42.5mm"
      height="50mm"
      viewBox="-17.5 -17.5 100 100"
      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs
        id="defs2">
        <linearGradient>
          <stop
            style="stop-color:#000000;stop-opacity:1;"
            offset="0"
            id="stop791" />
        </linearGradient>
      </defs>
      <g
        transform="translate(-60.82258,-83.887105)">
        <circle
          style="fill:#ffffff;fill-opacity:0.0144231;stroke:#ffffff;stroke-width:15;stroke-dasharray:none;stroke-opacity:1"
          id="path111"
          cx="93.322578"
          cy="116.3871"
          r="25" />
        <rect
          style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:19.5112;stroke-dasharray:none;stroke-opacity:1"
          id="rect1202"
          height="33.000"
          x="110.820"
          y="116.000"
          width="14.990" />
      </g>
    </svg>


  </div>
</div>
