import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject, takeUntil } from 'rxjs';
import { BASE_API_URL } from '@app/shared/utils/api';
import { API_ROUTES } from '../utils/api-routes';
import { AuthService } from '../auth/auth.service';
import { SessionStorageService } from '../auth/session-storage.service';
import { User } from '../models/user-info';
import {
  MeasuresResponse, SearchMeasuresParams,
  StartDailyMeasuresParams,
  StartDailyMeasuresResponse,
  StopDailyMeasuresParams, StopMeasuresResponse,
} from '../interfaces/measures.interface';

@Injectable({
  providedIn: 'root',
})
export class IrisService {
  public userConnected?: User;

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private sessionStorage: SessionStorageService,
    private authService: AuthService,
  ) {
    this.authService.connectedUser$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        if (user) this.userConnected = user;
      });
  }

  searchServicesMeasures({ exchangeRef, segment }: SearchMeasuresParams) {
    const email = this.userConnected?.email ?? '';
    const url = `${BASE_API_URL.CA_API_BASE_URL_IRIS}${API_ROUTES.SEARCH_F300B_SERVICE}`
      .replace('{pointId}', exchangeRef);
    const params = new HttpParams()
      .append('segment', segment)
      .append('email', email);

    return this.http
      .get<MeasuresResponse>(url, {
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.sessionStorage.get(AuthService.ID_TOKEN) ?? [],
      },
    });
  }

  startDailyServicesMeasures({ exchangeRef, denominationSociale, segment }: StartDailyMeasuresParams) {
    const profileId = this.authService.getProfileID();
    const email = this.userConnected?.email ?? '';
    const url = `${BASE_API_URL.CA_API_BASE_URL_IRIS}${API_ROUTES.START_DAILY_SUBSCRIPTION_SERVICE}`
      .replace('{pointId}', exchangeRef!);
    const params = new HttpParams()
      .append('email', email);

    return this.http
      .post<StartDailyMeasuresResponse>(url, { denominationSociale, segment, profileId }, {
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.sessionStorage.get(AuthService.ID_TOKEN) ?? [],
      },
    });
  }

  stopDailyServicesMeasures({ exchangeRef, serviceSouscritId }: StopDailyMeasuresParams) {
    const profileId = this.authService.getProfileID() ?? '';
    const email = this.userConnected?.email ?? '';
    const url = `${BASE_API_URL.CA_API_BASE_URL_IRIS}${API_ROUTES.STOP_DAILY_SUBSCRIPTION_SERVICE}`
      .replace('{pointId}', exchangeRef!)
      .replace('{serviceId}', serviceSouscritId!.toString());
    const params = new HttpParams()
      .append('profileId', profileId)
      .append('email', email);

    return this.http
      .delete<StopMeasuresResponse>(url, {
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.sessionStorage.get(AuthService.ID_TOKEN) ?? [],
      },
    });
  }
}
