import {
  Directive, TemplateRef,
} from '@angular/core';

interface Context<T> {
  $implicit?: T; // current item exposed as implicit value
  index?: number;
}

@Directive({
  selector: '[appRowCells]',
})
export class RowCellsDirective {
  constructor(
    public templateRef: TemplateRef<any>,
  ) { }

  static ngTemplateContextGuard<T>(
    dir: RowCellsDirective,
    ctx: any,
  ): ctx is Context<T> {
    return true;
  }
}
