import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  map,
  mergeMap,
  Observable,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DateTime } from 'luxon';
import { API_ROUTES } from '@app/shared/utils/api-routes';
import {
  GetSitesOffersParams,
  SiteDetailContractFeatures,
  SiteDetailContractFeaturesResponse,
  SiteOffer,
  SiteOfferDetails,
  SiteOfferDetailsResponse,
} from '@app/shared/interfaces/site.interface';
import { AuthService } from '@app/shared/auth/auth.service';
import { User } from '@app/shared/models/user-info';
import { ExportsSitesOffers } from '@app/shared/interfaces/exports';
import { Document } from '@app/shared/interfaces/document.interface';
import { Pageable, PageableParams } from '@app/shared/interfaces/pagination.interface';
import { BASE_API_URL } from '@app/shared/utils/api';
import { Fluid } from '@app/shared/models/fluid.model';
import { downloadFromUrl } from '@app/shared/utils/download';
import { S3Service } from '@app/shared/services/s3.service';
import { AlertService } from '@app/shared/components/organisms/alert/alert.service';
import { mapCrmSiteOfferDetails, mapCrmSitesOffers, mapPowerList } from './mapper';

@Injectable({
  providedIn: 'root',
})
export class SiteOffersService implements OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  public static DATE_FORMAT_CRM = 'dd/MM/yyyy';

  userConnected?: User;

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private s3Service: S3Service,
    private alertService: AlertService,
  ) {
    this.authService.connectedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      if (user) this.userConnected = user;
    });
  }

  public getSitesOffers(
    {
      fluid, offers, exchangeRef, profile,
    }: GetSitesOffersParams,
    pageableParams?: PageableParams,
  ): Observable<Pageable<SiteOffer>> {
    this.isLoading$.next(true);
    const { offset, limit } = pageableParams || {};
    let params = new HttpParams();
    if (profile) params = params.append('profile', profile || '');
    if (fluid) params = params.append('nature', Fluid.crmFluidName[fluid.key]);

    params = params.append('offers', offers || '')
      .append('exchangeRef', exchangeRef || '')
      .append('limit', limit || '')
      .append('offset', offset || 0);

    return this.httpClient
      .get<{ url: string }>(
      `${BASE_API_URL.CA_API_BASE_URL_CRM}${API_ROUTES.SITES_OFFERS}`,
      {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).pipe(
      mergeMap(({ url }) => this.s3Service.getSiteOffers(url)),
      map((res) => mapCrmSitesOffers(res)),
      tap(() => this.isLoading$.next(false)),
    );
  }

  public getSiteDetail(id: string): Observable<SiteOfferDetails> {
    const url = `${BASE_API_URL.CA_API_BASE_URL_CRM}${API_ROUTES.SITES_OFFERS}/${id}`;

    let params = new HttpParams()
      .append('email', this.userConnected?.email!);

    if (this.userConnected?.selectedProfile) {
      params = params.append('profile', this.userConnected.selectedProfile.id);
    }
    return this.httpClient
      .get<SiteOfferDetailsResponse>(url, {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    }).pipe(
      map((res: SiteOfferDetailsResponse) => mapCrmSiteOfferDetails(res)),
    );
  }

  public getSiteDetailContractFeatures(
    contractLineId: string,
  ): Observable<SiteDetailContractFeatures> {
    const url = `${BASE_API_URL.CA_API_BASE_URL_BILLINGS}${API_ROUTES.CONTRACTS}/${contractLineId}`;
    return this.httpClient
      .get<SiteDetailContractFeaturesResponse>(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).pipe(
      map((res: SiteDetailContractFeaturesResponse) => mapPowerList(res)),
    );
  }

  public getMinSupplyDate(
    searchContractId: string,
    sitesOffers: (ExportsSitesOffers | SiteOffer)[],
  ): DateTime | undefined {
    const filteredSitesOffers = sitesOffers
      .filter(({ contractId }) => contractId === searchContractId);

    if (filteredSitesOffers.length === 0) return undefined;

    return DateTime.fromFormat(filteredSitesOffers
      .reduce((prev, cur) => (
        DateTime.fromFormat(prev?.supplyStartDate, SiteOffersService.DATE_FORMAT_CRM)
        > DateTime.fromFormat(cur.supplyStartDate, SiteOffersService.DATE_FORMAT_CRM) ? cur : prev))
      .supplyStartDate, SiteOffersService.DATE_FORMAT_CRM);
  }

  public getMaxSupplyDate(
    searchContractId: string,
    sitesOffers: (ExportsSitesOffers | SiteOffer)[],
  ): DateTime | undefined {
    const filteredSitesOffers = sitesOffers
      .filter(({ contractId }) => contractId === searchContractId);

    if (filteredSitesOffers.length === 0) return undefined;

    return DateTime.fromFormat(filteredSitesOffers
      .reduce((prev, cur) => (
        DateTime.fromFormat(prev?.supplyEndDate, SiteOffersService.DATE_FORMAT_CRM)
        < DateTime.fromFormat(cur.supplyEndDate, SiteOffersService.DATE_FORMAT_CRM) ? cur : prev))
      .supplyEndDate, SiteOffersService.DATE_FORMAT_CRM);
  }

  public getDocuments(locationId: string): Observable<Document[]> {
    let params = new HttpParams()
      .append('locationId', locationId)
      .append('email', this.userConnected?.email!);

    if (this.userConnected?.selectedProfile) {
      params = params.append('profile', this.userConnected.selectedProfile.id);
    }

    return this.httpClient
      .get<Document[]>(
      `${BASE_API_URL.CA_API_BASE_URL_CRM}${API_ROUTES.SITES}${API_ROUTES.DOCUMENTS}`,
      {
        params,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public getDocument(docId: string, siteId: string, name: string) {
    let params = new HttpParams()
      .append('objectType', 'sites')
      .append('objectId', siteId)
      .append('filename', name)
      .append('email', this.userConnected?.email!);

    if (this.userConnected?.selectedProfile) {
      params = params.append('profile', this.userConnected.selectedProfile.id);
    }

    return this.httpClient
      .get<{ url: string }>(`${BASE_API_URL.CA_API_BASE_URL_CRM}${API_ROUTES.DOCUMENTS}/${docId}`, {
      params,
    }).pipe(mergeMap(async ({ url }) => {
      await downloadFromUrl(url, name);
    }));
  }

  public putSiteOfferName(exchangeRef: string, oldName: string, name: string) {
    const params = new HttpParams()
      .append('email', this.userConnected?.email!);

    if (this.userConnected?.selectedProfile) {
      params.append('profile', this.userConnected?.selectedProfile.id);
    }

    return this.httpClient.put(
      `${BASE_API_URL.CA_API_BASE_URL_CRM}${API_ROUTES.SITES}/${exchangeRef}`,
      { name, oldName },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        params,
      },
    );
  }

  static removeEmptyContractId(siteOffers: SiteOffer[]) {
    return siteOffers
      .filter((siteOffer) => siteOffer.contractId !== null && siteOffer.contractId !== '');
  }

  /**
   * Filter the list to remove duplicates based on the contractId field
   * and prioritize SiteOffers with 'Actif' status.
   */
  static removeDuplicateContractId(siteOffers: SiteOffer[]): SiteOffer[] {
    // Create an interface for the reduce function
    interface UniqueOffers {
      [contractId: string]: SiteOffer;
    }

    return Object.values(
      siteOffers.reduce((uniqueOffers: UniqueOffers, currentOffer: SiteOffer) => {
        const updatedUniqueOffers = { ...uniqueOffers };

        // Check if a SiteOffer with the same contractId already exists in uniqueOffers
        const existingOffer = updatedUniqueOffers[currentOffer.contractId];

        // If no SiteOffer with the same contractId,
        // or if the existing SiteOffer is not in 'Actif' status and the new one is,
        // replace the existing SiteOffer
        if (!existingOffer || (currentOffer.status === 'Actif' && existingOffer.status !== 'Actif')) {
          updatedUniqueOffers[currentOffer.contractId] = currentOffer;
        }

        return updatedUniqueOffers;
      }, {}),
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
