<button #buttonElement [type]="type" [id]="buttonId" [class.loading]="isLoading" [class.selected]="isSelected" [disabled]="isDisabled" (click)="onClickEvent($event)">
  <ng-container *ngTemplateOutlet="appliedTmpl; context: {phClass: iconOptions?.phosphorClass, iconSize, iconStyle: iconOptions?.iconStyle, iconColor: getIconColor}"></ng-container>
</button>

<ng-template #LeftIconTmpl let-phClass='phClass' let-iconSize='iconSize' let-iconStyle='iconStyle' let-iconColor='iconColor'>
  <app-icon *ngIf="!isLoading"
            [phosphorClass]="phClass"
            [size]="iconSize"
            [iconStyle]="iconStyle"
            [color]="iconColor"
  ></app-icon>
  <ng-container *ngTemplateOutlet="DefaultTmpl; context: {iconSize}"></ng-container>
</ng-template>

<ng-template #RightIconTmpl let-phClass='phClass' let-iconSize='iconSize' let-iconStyle='iconStyle' let-iconColor='iconColor'>
  <ng-container *ngTemplateOutlet="DefaultTmpl; context: {iconSize}"></ng-container>
  <app-icon *ngIf="!isLoading"
            [phosphorClass]="phClass"
            [size]="iconSize"
            [iconStyle]="iconStyle"
            [color]="iconColor"
  ></app-icon>
</ng-template>

<ng-template #OnlyIconTmpl let-phClass='phClass' let-iconSize='iconSize' let-iconStyle='iconStyle' let-iconColor='iconColor'>
  <ng-container *ngTemplateOutlet="isLoading ? IsLoadingTmpl : emptyTmpl; context: {spinnerDiameter: iconSize}"></ng-container>
  <app-icon *ngIf="!isLoading"
            [phosphorClass]="phClass"
            [size]="iconSize"
            [iconStyle]="iconStyle"
            [color]="iconColor"
  ></app-icon>
</ng-template>

<ng-template #DefaultTmpl let-spinnerDiameter='iconSize'>
  <ng-container *ngTemplateOutlet="isLoading ? IsLoadingTmpl : emptyTmpl; context: {spinnerDiameter}"></ng-container>
  <ng-content></ng-content>
</ng-template>

<ng-template #IsLoadingTmpl let-diameter='spinnerDiameter'>
  <app-spinner [diameter]="diameter" [mainColor]="getSpinnerColor.mainColor" [secondColor]="getSpinnerColor.secondColor"></app-spinner>
</ng-template>

<ng-template #emptyTmpl></ng-template>

