<div class="liste-filtres" *ngIf="filterIsActived">

    <div class="filtre supprTout">
      <app-button
        buttonId="removeFilters"
        [style]="ButtonStyle.Textual"
        [size]="ButtonSize.Small"
        [iconOptions]="{phosphorClass: 'ph-trash', position: ButtonIcon.OnlyIcon}"
        [isDisabled]="disabledButton"
        (onclick)="removeAllFilters()"
      ></app-button>
    </div>

    <app-chip *ngFor="let filter of filters | keyvalue"
              id="{{filter.key}}" class="label"
              [label]="filter.value"
              (removeAction)="removeFilter(filter.key)"
    ></app-chip>
  </div>
