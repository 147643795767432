<app-toast *ngIf="surveyUrl" (closeEvent)="neverShowAgain()">
  <div class="toast-content">
    <img id='logo-mini' alt="image" src="{{CDN_SOREGIES_URL}}logos/ALTERNA/Normal/PNG/mini_logo alterna_vert.png" />
    <span class="toast-title">Votre avis compte pour nous</span>
    <p class="toast-content">Aidez nous à améliorer votre espace client en répondant à notre <b>enquête</b>. Cela ne vous prendra que <b>3 minutes</b> 😊</p>
    <app-button
      (onclick)="goToSurvey()"
    >Répondre à l'enquête</app-button>
  </div>
</app-toast>
