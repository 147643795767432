export enum Color {
  primary50 = 'var(--color-primary50)',
  primary100 = 'var(--color-primary100)',
  primary200 = 'var(--color-primary200)',
  primary300 = 'var(--color-primary300)',
  primary400 = 'var(--color-primary400)',
  primary500 = 'var(--color-primary500)',
  primary600 = 'var(--color-primary600)',
  primary700 = 'var(--color-primary700)',
  primary800 = 'var(--color-primary800)',
  primary900 = 'var(--color-primary900)',
  secondary50 = 'var(--color-secondary50)',
  secondary100 = 'var(--color-secondary100)',
  secondary200 = 'var(--color-secondary200)',
  secondary300 = 'var(--color-secondary300)',
  secondary400 = 'var(--color-secondary400)',
  secondary500 = 'var(--color-secondary500)',
  secondary600 = 'var(--color-secondary600)',
  secondary700 = 'var(--color-secondary700)',
  secondaryBlue = 'var(--color-secondaryBlue)',
  secondaryLightBlue = 'var(--color-secondaryLightBlue)',
  secondaryDarkBlue = 'var(--color-secondaryDarkBlue)',
  greyWhite = 'var(--color-greyWhite)',
  grey50 = 'var(--color-grey50)',
  grey100 = 'var(--color-grey100)',
  grey200 = 'var(--color-grey200)',
  grey300 = 'var(--color-grey300)',
  grey400 = 'var(--color-grey400)',
  grey500 = 'var(--color-grey500)',
  grey600 = 'var(--color-grey600)',
  grey700 = 'var(--color-grey700)',
  grey800 = 'var(--color-grey800)',
  grey900 = 'var(--color-grey900)',
  greyBlack = 'var(--color-greyBlack)',
  success100 = 'var(--color-success100)',
  success300 = 'var(--color-success300)',
  success500 = 'var(--color-success500)',
  success700 = 'var(--color-success700)',
  success900 = 'var(--color-success900)',
  warning100 = 'var(--color-warning100)',
  warning300 = 'var(--color-warning300)',
  warning500 = 'var(--color-warning500)',
  warning700 = 'var(--color-warning700)',
  warning900 = 'var(--color-warning900)',
  danger100 = 'var(--color-danger100)',
  danger300 = 'var(--color-danger300)',
  danger500 = 'var(--color-danger500)',
  danger700 = 'var(--color-danger700)',
  danger900 = 'var(--color-danger900)',
  info100 = 'var(--color-info100)',
  info300 = 'var(--color-info300)',
  info500 = 'var(--color-info500)',
  info700 = 'var(--color-info700)',
  info900 = 'var(--color-info900)',
  positive = 'var(--color-positive)',
  negative = 'var(--color-negative)',
}
