import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upperCaseFirstLetters',
})
export class UpperCaseFirstLettersPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) return '';
    return value.toLowerCase().split(' ')
      .map((splittedValue) => splittedValue[0].toUpperCase() + splittedValue.substring(1))
      .join(' ');
  }
}
