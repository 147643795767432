import {
  Component, EventEmitter, Input, Output, ViewChild,
} from '@angular/core';
import { BaseDialogComponent, DialogSize } from '@app/shared/components/atoms/dialog/base-dialog.component';
import { MESSAGES } from '@app/shared/utils/messages';
import { IrisService } from '@app/shared/services/iris.service';
import { AlertService } from '@app/shared/components/organisms/alert/alert.service';
import { Style } from '@app/shared/components/atoms/icon/icon.component';
import { Color } from '@app/shared/models/color';
import { ButtonStyle } from '@app/shared/components/molecules/buttons/button.type';

@Component({
  selector: 'app-daily-dialog',
  templateUrl: './daily-dialog.component.html',
  styleUrls: ['./daily-dialog.component.scss'],
})
export class DailyDialogComponent {
  protected readonly MESSAGES = MESSAGES;

  protected readonly DialogSize = DialogSize;

  @ViewChild('dialog') dialog!: BaseDialogComponent;

  @Input() segment?: string;

  @Input() exchangeRef?: string;

  @Input() denominationSociale?: string;

  @Output() cancelTrigger = new EventEmitter<void>();

  @Output() validateTrigger = new EventEmitter<any>();

  protected isLoadingSubmit = false;

  constructor(
    private alertService: AlertService,
    private irisService: IrisService,
  ) {}

  submitForm() {
    this.isLoadingSubmit = true;
    const { exchangeRef, denominationSociale, segment } = this;

    this.irisService
      .startDailyServicesMeasures({ exchangeRef, denominationSociale, segment }).subscribe({
        next: ({ affair }) => {
          const { serviceSouscritId } = affair;
          this.validateTrigger.emit({ serviceSouscritId });
          this.dialog.close();

          this.isLoadingSubmit = false;
        },
        error: () => {
          this.alertService.error(MESSAGES.TXT17);
          this.cancelTrigger.emit();
          this.dialog.close();
        },
      });
  }

  cancel() {
    this.cancelTrigger.emit();
    this.dialog.close();
  }

  protected readonly Style = Style;

  protected readonly Color = Color;

  protected readonly ButtonStyle = ButtonStyle;
}
