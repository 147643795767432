<div class="main-info-container">
  <div class="main-info-content" (click)="redirectTo()">
    <span class="label">{{ title }}</span>
    <ng-container *ngIf="!loading">
      <div class="content">
        @if (!edited) {
          <span *ngIf="value" class="value" [class.redirect]="redirectUrl">
            @if (redirectUrl) {
              <app-label
                [weight]="FontWeight.semibold"
                [typography]="Typography.body2"
                [color]="Color.primary600"
              >{{ value }}</app-label>
              <app-icon
                phosphorClass="icon ph-arrow-square-out" [iconStyle]="Style.bold" [size]="Size.small"
                [color]="Color.primary600"
              ></app-icon>
            } @else {
              {{ value }}
            }
          </span>
          <span *ngIf="!value" class="value">Non communiqué</span>
        } @else {
          <app-textfield [focusOnView]="true"
                     [value]="value!"
                     (valueChange)="editOnChange($event)"
                     [maxLength]="maxLenght"
                     [isOnError]="isEditingOnError"
                     ></app-textfield>
        }
      </div>
    </ng-container>
    <ng-container *ngIf="loading">
      <ngx-skeleton-loader class="skeleton-loader" count="1" appearance="line"></ngx-skeleton-loader>
    </ng-container>
  </div>
</div>
