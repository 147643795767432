import { Component, Input } from '@angular/core';
import { ConsumptionSitesOffer } from '@app/shared/interfaces/consumptions.interface';
import { RoutingPricing } from '@app/shared/interfaces/site.interface';
import { Style } from '@app/shared/components/atoms/icon/icon.component';

@Component({
  selector: 'app-bloc-detail-site',
  templateUrl: './bloc-detail-site.component.html',
  styleUrls: ['./bloc-detail-site.component.scss'],
})
export class BlocDetailSiteComponent {
  @Input() site?: ConsumptionSitesOffer;

  @Input() routingPricing: RoutingPricing | null = null;

  protected readonly Style = Style;
}
