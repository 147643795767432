/* eslint no-underscore-dangle: 0 */
import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';

export interface RadioItem {
  value: string;
  key: string;
  checked?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent implements OnInit {
  @Output() radioGroupChange = new EventEmitter<RadioItem[]>();

  @Input() name: string = 'radio-group';

  @Input() direction: 'row' | 'column' = 'row';

  @Input() gap: string = '0.4375rem';

  @Output() checkedChange = new EventEmitter<string>();

  _activeKey?: string;

  _radioGroup?: RadioItem[] = [];

  ngOnInit() {
    const { activeKey } = this;
    if (activeKey) this.activeRadio(activeKey);
  }

  @Input()
  set activeKey(value: string | undefined) {
    if (value) this.activeRadio(value);
    this._activeKey = value;
  }

  get activeKey() {
    return this._activeKey;
  }

  @Input()
  set radioGroup(value: RadioItem[]) {
    this._radioGroup = JSON.parse(JSON.stringify(value)) as RadioItem[];
  }

  get radioGroup() {
    return this._radioGroup ?? [];
  }

  onRadioChange($event: string) {
    this.radioGroupChange.emit(this.radioGroup);
    this.checkedChange.emit($event);
  }

  private activeRadio(key: string) {
    this.radioGroup = this.radioGroup.map((radio) => {
      if (radio.key !== key) return Object.assign(radio, { checked: false });
      return Object.assign(radio, { checked: true });
    });
  }
}
