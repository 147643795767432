import {
  AfterContentChecked,
  ContentChild,
  Directive, Host, HostBinding, HostListener, Input, OnInit, Optional,
} from '@angular/core';
import { ColumnHeaderComponent } from '@app/shared/components/atoms/table/column-header/column-header.component';
import { ColumnDirective } from '@app/shared/components/atoms/table/directives/column.directive';

const defaultBorderStyle = '1px solid #E9EBEF';

@Directive({
  selector: 'appColumnHeader, th[appColumnHeader]',
})
export class ColumnHeaderDirective implements OnInit, AfterContentChecked {
  @HostBinding('style.border-up') borderUp?: string;

  @HostBinding('style.border-right') borderRight?: string;

  @HostBinding('style.border-left') borderLeft?: string;

  @HostBinding('style.display') display?: string;

  @HostBinding('style.width') @Input() width?: string;

  @HostBinding('style.text-align') @Input() textAlign = 'start';

  @ContentChild(ColumnHeaderComponent) header?: ColumnHeaderComponent;

  @HostBinding('attr.sort') sorting?: 'asc' | 'dec';

  @Input() border?: 'left' | 'right' | 'bottom' | 'up';

  constructor(
    @Optional() @Host() private columnDirective: ColumnDirective,
  ) {}

  ngOnInit(): void {
    const { border } = this;

    switch (border) {
      case 'up':
        this.borderUp = defaultBorderStyle;
        break;
      case 'right':
        this.borderRight = defaultBorderStyle;
        break;
      case 'left':
        this.borderLeft = defaultBorderStyle;
        break;
      case 'bottom':
      default:
        break;
    }
  }

  @HostListener('click')
  onClick() {
    const { header } = this;

    switch (header?.type) {
      case 'sort':
        this.sorting = header.switchSort();
        break;
      case 'filter':
        header.showDropdown();
        break;
      default:
        break;
    }
  }

  removeSort() {
    const { header } = this;

    this.sorting = header?.removeSort();
  }

  ngAfterContentChecked(): void {
    this.display = this.columnDirective.showColumn() ? undefined : 'none';
  }
}
