<div class="table-layout">
  <div class="table-container" #tableContainer>
    <table [id]="id" appTable [tableLayoutAuto]="tableLayoutAuto">
      <thead>
      <ng-container #headersVcr *ngFor="let row of headerRows"></ng-container>
      </thead>
      <tbody [ngSwitch]="currentTemplate">
      <ng-container *ngSwitchCase="'loadingBlock'">
        <tr class="disabled" *ngFor="let row of Array.from(Array(limit).keys())">
          <td *ngFor="let cell of headers.toArray()">
            <ngx-skeleton-loader></ngx-skeleton-loader>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngSwitchCase="'dataBlock'">
        <ng-container #rowsVcr *ngFor="let row of rows"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'messageBlock'">
        <tr class="message no-data disabled">
          <td [colSpan]="headers.length">
            <span [innerHTML]="isFiltered ? noFilteredDataMessage : messageInfo"></span>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div [class.hidden-footer]="currentTemplate !== 'dataBlock' || !showFooter">
    <app-table-footer
                      [source]="displayedSource" [(limit)]="limit" [(currentPage)]="currentPage"
                      [pageable]="pageable"
                      [limits]="limits"
                      (limitChange)="onLimitChange($event)"
                      (currentPageChange)="onPageChange($event)">
    </app-table-footer>
  </div>
</div>
