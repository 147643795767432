import { Injectable, OnDestroy } from '@angular/core';
import {
  combineLatest, debounceTime,
  map, Observable, OperatorFunction, ReplaySubject, Subject,
} from 'rxjs';
import { Offer } from '@app/shared/interfaces/offer.interface';
import { SiteOffer } from '@app/shared/interfaces/site.interface';
import { Fluid } from '@app/shared/models/fluid.model';
import { FluidService } from '@app/shared/services/fluid.service';
import { SiteOffersService } from '@app/site-offers/site-offers.service';
import { Router } from '@angular/router';

export interface Monosite {
  elec: boolean,
  gas: boolean,
}

export interface DataStore {
  siteOffers?: SiteOffer[],
  offers?: Offer[],
  fluid?: Fluid,
  profile?: string,
  monosite?: Monosite,
}

@Injectable({
  providedIn: 'root',
})
export class InitAppService implements OnDestroy {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  data$ = new ReplaySubject<DataStore>(1);

  constructor(private fluidService: FluidService, private router: Router) {
  }

  next(data: DataStore) {
    this.router.navigate([], {
      queryParams: {
        profile: data.profile ?? null,
      },
    });
    this.data$.next(data);
  }

  current(): Observable<DataStore> {
    return combineLatest([
      this.fluidService.currentFluid$(),
      this.data$,
    ]).pipe(
      debounceTime(250),
      map(([{ fluid }, data]) => ({ fluid, ...data })),
    );
  }

  clear(): void {
    this.data$.next({ offers: [], siteOffers: [] });
  }

  ngOnDestroy() {
    this.data$.complete();
  }

  static filterByFluid(): OperatorFunction<DataStore, DataStore> {
    return map(({
      offers, siteOffers, fluid, ...tail
    }) => {
      const newOffers = offers?.filter(({ nature }) => fluid?.key === nature);
      const newSiteOffers = siteOffers?.filter(({ nature }) => fluid?.key === nature);

      return {
        ...tail,
        fluid,
        offers: newOffers,
        siteOffers: newSiteOffers,
      };
    });
  }

  static distinctContractId() {
    return map(({ siteOffers, ...tail }: DataStore) => {
      const newSiteOffers = InitAppService.filterSiteOffers(siteOffers!);

      return {
        ...tail,
        siteOffers: newSiteOffers,
      };
    });
  }

  static filterSiteOffers(sitesOffers: SiteOffer[]): SiteOffer[] {
    const siteOfferWithoutEmptyContractId = SiteOffersService.removeEmptyContractId(sitesOffers);
    return SiteOffersService.removeDuplicateContractId(siteOfferWithoutEmptyContractId);
  }
}
