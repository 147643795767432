<div
  [id]="expansionPanelId"
  class="container"
  [class.dropdown-opened]="toOpen"
  [class.disabled]="state === 'disabled'"
  [class.read-only]="state === 'readOnly'"
  #triggerContainerEl>
  <div class="cover-container" (click)="showDropdown()" cdkOverlayOrigin #trigger="cdkOverlayOrigin" [ngStyle]="{'justify-content': COVERSPACINGTYPE[coverSpacing]}">
    <div class="cover-content">
      <app-icon *ngIf="iconClassName" [phosphorClass]="iconClassName" [iconStyle]="iconStyle" [size]="iconSize" [color]="state === 'disabled' ? Color.grey500 : Color.greyBlack "></app-icon>
      <div class="text-container" *ngIf="hoverText; else noHovertext">
        <span class="text">{{coverText}}</span>
        <span class="hover-text">{{hoverText}}</span>
      </div>
      <ng-template #noHovertext>
        <span *ngIf="coverText" class="text">{{coverText}}</span>
      </ng-template>
    </div>
    <app-icon *ngIf="!withoutCarretIcon" phosphorClass="ph-caret-down" [size]="Size.small"></app-icon>
  </div>

  <ng-template cdkPortal>
    <div [id]="expansionPanelId+'-dropdown'" class="dropdown-container" #dropdownContainer>
      <div class="content" (click)="hasCloseOnCLick && hide()">
        <ng-content select="[dropdown-content]"></ng-content>
      </div>
    </div>
  </ng-template>
</div>
