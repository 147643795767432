<div class="card-container">

  <img
    *ngIf="this.styleOption?.customIconSrc; else defaultIcon"
    #customAlertIcon
    [class.flexstart-type-icon]="!this.styleOption?.iconCenterAlign"
    [src]="this.styleOption?.customIconSrc!"
    [width]="24"
    [height]="24"
    alt="Icon information"/>

  <ng-template #defaultIcon>
    <app-icon
      [class.flexstart-type-icon]="!this.styleOption?.iconCenterAlign"
      [phosphorClass]="getType.iconClass"
      [color]="getType.color"
    ></app-icon>
  </ng-template>


  <div class="card-body">
    <div class="content">
      <span *ngIf="title" id="alertTitle">{{ title }}</span>
      <p *ngIf="description" id="alertDescription" [innerHTML]="description"></p>
    </div>
    <app-button
      *ngIf="closeIcon"
      class="close-btn"
      buttonId="closed-alert-btn"
      [size]="ButtonSize.Small"
      [style]="ButtonStyle.Textual"
      [iconOptions]="{phosphorClass: 'ph-x', position: ButtonIcon.OnlyIcon}"
      (onclick)="closeEvent.emit()"
    >
    </app-button>
  </div>
</div>
